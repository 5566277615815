import { fabric } from 'fabric';
import { CardContextState } from '../../../context/card-context';
import { CardEditableArea, CardFaceData } from '../../../global-types';
import { config } from '../../../regional-config';
import { getEditableAreaSettings, editableAreaDefaultSettings } from '../card-editor-utils';
import {
  getUserZonePhotoButton,
  getUserZoneTextButton,
  getUserZoneStickerButton,
  getUserZoneWamButton,
  getUserZoneInstructions,
} from './get-editable-area-buttons';
import { scaleEditableAreaButton } from './scale-editable-area-buttons';

/**
 * Adds editable user zone area to card face
 *
 * @param face - card face to add user zone to
 * @param addButtonsOnly - used to re-populate buttons in user zone after final object is deleted
 * @returns void
 */
export const addEditableAreas = (face: CardFaceData, cardState: CardContextState, addButtonsOnly?: boolean) => {
  const canvas = face?.canvas?.current;
  if (!canvas) {
    return;
  }
  const { editableAreas } = face;
  if (!editableAreas || editableAreas.length === 0) {
    return;
  }
  let hasAreaObjects = false;

  face.originalCanvasJson?.objects.forEach((obj: fabric.Object) => {
    hasAreaObjects = !!obj.clipPath;
  });

  if (canvas) {
    const objects = canvas.getObjects();
    editableAreas.forEach((area: CardEditableArea, index: number) => {
      const canvasArea = new fabric.Rect(getEditableAreaSettings(area, index, cardState));
      const editableAreaObject = objects?.find((el) => el?.data?.type === 'editable-area');
      let zone: fabric.Object;
      if (!editableAreaObject) {
        canvas.add(canvasArea);
        canvasArea.moveTo(0);
        zone = canvasArea;
      } else {
        // Ensure editableArea is at bottom of object stack
        editableAreaObject.moveTo(0);
        zone = editableAreaObject;
      }

      const addUserZoneButtonToCanvas = (button) => {
        const scaledButton = scaleEditableAreaButton(button, zone);
        canvas.add(scaledButton);
      };

      if (!hasAreaObjects || addButtonsOnly) {
        if (config.userZones?.stokeAndFillHide) {
          zone.set({
            stroke: editableAreaDefaultSettings.stroke,
            fill: editableAreaDefaultSettings.fill,
          });
        }
        // Photo Button enabled for US, UK, CE
        if (config.userZones?.hasPhotoButton) {
          getUserZonePhotoButton(zone).then(addUserZoneButtonToCanvas);
        }

        // Text Button enabled for US, UK, CE
        if (config.userZones?.hasTextButton) {
          getUserZoneTextButton(zone).then(addUserZoneButtonToCanvas);
        }

        // Sticker Button enabled only for CE
        if (config.userZones?.hasStickerButton) {
          getUserZoneStickerButton(zone).then(addUserZoneButtonToCanvas);
        }

        // WAM is enabled only for UK
        if (config.userZones?.hasWAMButton) {
          getUserZoneWamButton(zone).then(addUserZoneButtonToCanvas);
        }

        // Instructions is enabled only for UK
        if (config.userZones?.hasInstructions) {
          getUserZoneInstructions(zone).then((instructions) => {
            canvas.add(instructions);
          });
        }
      } else if (config.userZones?.stokeAndFillHide) {
        zone.set({
          stroke: 'transparent',
          fill: 'transparent',
        });
      }
    });
  }
};
