export const convertRotationToRange = (angle: number): number => {
  if (angle > 180) {
    return angle - 360;
  }
  return angle;
};

export const convertRangeToRotation = (rangeValue: number): number => {
  if (rangeValue < 0) {
    return 360 + rangeValue;
  }
  return rangeValue;
};
