import { SummaryData, SummaryOption, PriceBook, MappedPrice } from './summary-drawer-types';

/**
 * Get mapped rowData for Summary grid component. Currently we're only working with one row of mostly static info,
 * but this could change and more rows could be defined
 * @param subtotal a string with the subtotal calculated by (selected quantity * unit price)
 * @returns an array properly formatted for SummaryGrid component, at the moment just includes one row with card quantity data
 */
export const getMappedRowData = (subtotal: string): SummaryData[] => {
  return [{ title: 'Flat card', subtitle: 'includes envelopes', detail: `$${subtotal}` }];
};

/**
 *
 * @param total the project total which at the time is the subtotal from (qty*price), could change in the future if more options are added to cards
 * @returns a SummaryData object data to pass as the endRow param to Summary Grid component
 */
export const getMappedEndData = (total: string): SummaryData => {
  return { title: 'Project total', detail: `$${total}` };
};

/**
 *
 * @param priceData A list of card quantities and its corresponding prices per unit
 * @returns an array of objects properly formatted to pass to the dropdown component inside SummaryGrid
 */
export const getMappedOptions = (priceData: PriceBook): SummaryOption[] => {
  return (
    priceData.priceTiers?.map((obj) => ({ label: `${obj.quantity} @ $${obj.price}`, value: `${obj.quantity}` })) ?? []
  );
};

/**
 *
 * @param priceData A list of card quantities and its corresponding prices per unit
 * @returns a Price map with a string representing the quantity as key, to be used when calculating subtotals.
 * Since the dropdown component used in the summary-grid uses string type for its value, we're using the map to
 * avoid possible discrepancies when doing calculations.
 */
export const getPricesMap = (priceData: PriceBook): MappedPrice => {
  const pricesMap: MappedPrice = {};
  priceData.priceTiers?.forEach((price) => {
    pricesMap[`${price.quantity}`] = price;
  });

  return pricesMap;
};
