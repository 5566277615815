import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastCE, ToastCEProps, ToastCEVariants } from '@hallmark/web.ce.components.toast-ce';
import { Toast as ToastGlobal, ToastProps as ToastGlobalProps, ToastVariants } from '@hallmark/web.core.feedback.toast';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { config } from '../../../regional-config';

export const Toast = ({
  children,
  ...props
}: ToastCEProps & ToastGlobalProps & { variant?: ToastVariants | ToastCEVariants }) => {
  const { t } = useTranslation();

  if (config.toast.variant === 'CE') {
    // we do not show error type of toast for CE
    if (props.variant === ToastVariants.Error) {
      return (
        <ToastCE {...props} variant={ToastCEVariants.Warning}>
          <span>
            <Typography variant={TypographyVariants.BodyBold}>{t('toast.errorFirstText')}</Typography>
            {children}
          </span>
        </ToastCE>
      );
    }
    return <ToastCE {...props}>{children}</ToastCE>;
  }
  return <ToastGlobal {...props}>{children}</ToastGlobal>;
};
