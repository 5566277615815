import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { CollapsePanel } from '@hallmark/web.core.surfaces.collapse-panel';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { CollapseSectionProps } from '../address-form-types';
import styles from '../address-form.module.scss';

export const CollapseSection = ({ children }: CollapseSectionProps): React.ReactElement => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const CollapseTrigger = (): React.ReactElement => (
    <Button
      mode={ButtonModes.TextLink}
      addClass={classNames(styles['collapse-trigger'], styles['text-link'])}
      click={() => {
        setIsCollapsed(!isCollapsed);
      }}
      endIcon={{ name: IconNames.ArrowsCaretUpBold, addClass: isCollapsed ? styles['collapsed'] : null }}
      testId={'collapse-button'}
    >
      Address line 2 and company name
    </Button>
  );
  return (
    <CollapsePanel isOpen={!isCollapsed} trigger={<CollapseTrigger />} addClass={styles['collapse-panel']}>
      {!isCollapsed && children}
    </CollapsePanel>
  );
};
