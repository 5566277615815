import EXIF from 'exif-js';

/**
 * Leverages EXIF package to read EXIF data from a file.
 * @param file
 * @returns Binary EXIF data from the file (defined as any in EXIF types.) , or null for an error.
 */
export const readFileExifData = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        resolve(EXIF.readFromBinaryFile(reader.result));
      } else {
        reject(null);
      }
    };
    reader.onerror = () => {
      reject(null);
    };
    reader.readAsArrayBuffer(file);
  });
};
