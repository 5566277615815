import { FabricTextBox } from '../../../../global-types';
import { useActiveCanvas, useIsSignAndSend } from '../../../../hooks';
import { CanvasDataTypes } from '../../../../utils';

export const useFontDrawerFlags = () => {
  const canvas = useActiveCanvas();
  const shouldNOTShowPositionTabEditor = useIsSignAndSend();
  const shouldShowPositionTabEditor = !shouldNOTShowPositionTabEditor;
  const activeObject = canvas?.current?.getActiveObject() as FabricTextBox;
  const shouldShowColorEditor = activeObject.CanEditFontColor || activeObject.data.type === CanvasDataTypes.UserText;

  return { shouldShowColorEditor, shouldShowPositionTabEditor };
};
