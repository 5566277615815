import { RefObject } from 'react';
import { fabric } from 'fabric';
import { CanvasDataTypes, CustomFabricType } from '../canvas';

const MAX_IMAGE_WIDTH = 400;
const MAX_IMAGE_HEIGHT = 400;
const SCALE_FACTOR = 2;

export const createFabricImageInstance = (
  imageRef: RefObject<HTMLImageElement>,
  canvasDataType: CanvasDataTypes.UserImage | CanvasDataTypes.StickerImage,
  imageName: string | undefined,
  borderColor: string,
  currentCanvas: fabric.Canvas,
  customType?: CustomFabricType,
  activeCardIndex?: number,
  cardFormat?: string,
): fabric.Image => {
  const zoomLevel = currentCanvas.getZoom();

  const imageElement = imageRef.current;
  if (!imageElement) throw new Error('Image element is missing.');

  const fabricImage = new fabric.Image(imageElement, {
    name: imageName,
    borderColor,
    crossOrigin: 'anonymous',
    hasControls: true,
    data: {
      type: canvasDataType,
      customType,
    },
    padding: 10,
  });

  const imageScaleFactorWidth = MAX_IMAGE_WIDTH / (fabricImage.width ?? 1);
  const imageScaleFactorHeight = MAX_IMAGE_HEIGHT / (fabricImage.height ?? 1);
  const scaleFactor = Math.min(imageScaleFactorWidth, imageScaleFactorHeight);

  fabricImage.scale(scaleFactor);

  const centerX = currentCanvas.getWidth() / SCALE_FACTOR / zoomLevel;
  const centerY = currentCanvas.getHeight() / SCALE_FACTOR / zoomLevel;

  let leftPosition = centerX - fabricImage.getScaledWidth() / SCALE_FACTOR;
  let topPosition = centerY - fabricImage.getScaledHeight() / SCALE_FACTOR;

  if (cardFormat === 'portrait') {
    const leftCalculation = centerX + centerX / SCALE_FACTOR - fabricImage.getScaledWidth() / SCALE_FACTOR;

    if (activeCardIndex === 1) {
      leftPosition = leftCalculation;
    }
  } else if (cardFormat === 'landscape') {
    topPosition = centerY + centerY / SCALE_FACTOR - fabricImage.getScaledHeight() / SCALE_FACTOR;
  }

  fabricImage.set({
    left: leftPosition,
    top: topPosition,
  });

  currentCanvas.add(fabricImage);
  currentCanvas.setActiveObject(fabricImage);
  currentCanvas.renderAll();

  return fabricImage;
};
