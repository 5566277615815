import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IEvent } from 'fabric/fabric-impl';
import { useInitializationDataContext } from '../../../context/data-context';
import { useActiveCanvas } from '../../../hooks';
import { isPhotoTextZone, getObjectPositionY } from '../../../utils';

/**
 * Custom hook to make the canvas objects accessible by keyboard and for screen readers.
 * This hooks adds a keydown event listener to handle the canvas object selection and updates the screen reader text
 *
 * @returns The canvas-wrapper ref to be used in the container wrapping the canvas, and the activeLabel of the selected object in canvas
 * to be used in the aria-label property of the canvas wrapping container
 */
export const useCanvasAccessibility = () => {
  const [label, setLabel] = useState('');
  const activeCanvas = useActiveCanvas();
  const { t } = useTranslation();
  const canvasWrapperRef = useRef<HTMLDivElement>(null);

  const { initializedDataState } = useInitializationDataContext();
  const projectType = initializedDataState.data?.project_type_code;

  type Coords = {
    top: number;
    left: number;
  };
  const moveObjectTo = (object: fabric.Object, { top, left }: Coords) => {
    object.set({ left, top });
    object.canvas?.requestRenderAll();
  };

  const handleEnterKeyDown = useCallback((event: KeyboardEvent, canvas: fabric.Canvas) => {
    canvasWrapperRef.current?.blur();
    const activeObject = canvas.getActiveObject();
    if (activeObject?.type === 'textbox') {
      (activeObject as fabric.Textbox).fire('custom:updateBorders');
      (activeObject as fabric.Textbox).enterEditing();
      event.preventDefault();
    }
  }, []);

  const handleTabKeyDown = useCallback((event: KeyboardEvent, canvas: fabric.Canvas) => {
    const objects = canvas.getObjects().filter((object) => object.selectable);
    if (objects.length === 0) {
      return;
    }
    const activeObject = canvas.getActiveObject();
    const activeObjectIndex = activeObject ? objects.indexOf(activeObject) : -1;
    const isMovingBackwards = event.shiftKey;
    const nextObjectIndex = isMovingBackwards
      ? activeObjectIndex === -1
        ? objects.length - 1
        : activeObjectIndex - 1
      : activeObjectIndex + 1;
    if (nextObjectIndex < 0 || nextObjectIndex >= objects.length) {
      canvas.discardActiveObject();
      canvas.renderAll();
      return;
    }

    event.preventDefault();
    const nextActiveObject = objects[+nextObjectIndex];
    canvas.setActiveObject(nextActiveObject);
    canvas.renderAll();
  }, []);

  const handleArrowKeyDown = useCallback((event: KeyboardEvent, canvas: fabric.Canvas) => {
    // S&S projects can't support key controls for as it allows user to break template layout.
    if (!event.key.startsWith('Arrow') || projectType == 'S') {
      return;
    }
    const activeObject = canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    const pixelsToMove = 4;
    const moveFactor = event.repeat ? 6.5 * pixelsToMove : pixelsToMove;
    const { top = 0, left = 0 } = activeObject;
    switch (event.key) {
      case 'ArrowUp': {
        moveObjectTo(activeObject, { top: top - moveFactor, left });
        break;
      }
      case 'ArrowDown': {
        moveObjectTo(activeObject, { top: top + moveFactor, left });
        break;
      }
      case 'ArrowRight': {
        moveObjectTo(activeObject, { top, left: left + moveFactor });
        break;
      }
      case 'ArrowLeft': {
        moveObjectTo(activeObject, { top, left: left - moveFactor });
        break;
      }

      default:
        break;
    }
  }, []);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const canvas = activeCanvas?.current;
      if (!canvas) {
        return;
      }
      switch (event.key) {
        case 'Tab': {
          handleTabKeyDown(event, canvas);
          break;
        }
        case 'Enter': {
          handleEnterKeyDown(event, canvas);
          break;
        }
        case 'Escape': {
          canvas.discardActiveObject();
          canvas.renderAll();
          break;
        }
        default:
          handleArrowKeyDown(event, canvas);
          break;
      }
    },
    [activeCanvas?.current, handleEnterKeyDown, handleTabKeyDown, handleArrowKeyDown],
  );

  const onDiscardActiveObject = useCallback(() => {
    setLabel('');
  }, []);

  const onObjectSelected = useCallback((event: IEvent<Event>) => {
    const selectedObject = event.selected?.[0];
    if (!selectedObject) {
      return;
    }
    if (isPhotoTextZone(selectedObject)) {
      const position = getObjectPositionY(selectedObject);
      const photoTextZoneLabel = t('cardFace.photoTextZoneLabel', { position }) ?? '';
      setLabel(photoTextZoneLabel);
    }
  }, []);

  useEffect(() => {
    // add listeners to canvas for accessibility
    activeCanvas?.current?.on('selection:cleared', onDiscardActiveObject);
    activeCanvas?.current?.on('selection:created', onObjectSelected);
    activeCanvas?.current?.on('selection:updated', onObjectSelected);
    return () => {
      activeCanvas?.current?.off('selection:cleared', onDiscardActiveObject);
      activeCanvas?.current?.off('selection:created', onObjectSelected);
      activeCanvas?.current?.off('selection:updated', onObjectSelected);
    };
  }, [activeCanvas, onDiscardActiveObject, onObjectSelected]);

  useEffect(() => {
    canvasWrapperRef.current?.addEventListener('keydown', onKeyDown);
    return () => {
      canvasWrapperRef.current?.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown, canvasWrapperRef]);

  return { canvasWrapperRef, activeLabel: label };
};
