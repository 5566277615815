import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import styles from '../font-drawer.module.scss';

export const FontDrawerHeader = () => {
  const { t } = useTranslation();
  return (
    <Typography variant={TypographyVariants.Headline4} addClass={styles.headline}>
      {t('fontDrawer.headline')}
    </Typography>
  );
};
