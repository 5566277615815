import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceBook } from '../components/card-controls/summary-drawer/summary-drawer-types';
import { useInitializationDataContext } from '../context/data-context';
import { DEFAULT_PROJECT_NAMES } from '../global-types';

/**
 * Hook that returns the card's product name and price-book from BE data we get in initialize call.
 *
 * @returns priceBook object and productName string
 */
export const useProjectSummary = () => {
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { t } = useTranslation();

  /**
   * The priceBook information containing singular price and a list of pricing tiers as price-quantity pairs.
   * Used to populate the quantity dropdown in project summary.
   * @type PriceBook
   */
  const priceBook: PriceBook = useMemo(
    () => ({
      price: initializedData?.product?.price_book?.price,
      priceTiers: initializedData?.product?.price_book?.price_tiers,
    }),
    [initializedData?.product?.price_book?.price, initializedData?.product?.price_book?.price_tiers],
  );

  //  Product's name. Used to set the initial draft name in project summary for a new (unsaved) project.
  const productName = initializedData?.product.product_name;
  // Default name data from BE is "POD Project | S&S Project | Digital Greetings".
  const projectName = initializedData?.name;
  const projectId = initializedData?.project_id;

  /**
   * The project's type code (P | S | D).
   */
  const projectTypeCode = initializedData?.project_type_code;

  /**
   * Card faces.
   * Used for validating the images on each face in save project dialog
   */
  const cardFaces = initializedData?.variables?.template_data?.Faces;

  /**
   * Boolean to validate if the user hasn't saved the project with a new name yet
   */
  const hasDefaultProjectName = Object.values<string>(DEFAULT_PROJECT_NAMES).includes(`${projectName}`);

  const defaultProjectName = useMemo(() => {
    if (projectName && !hasDefaultProjectName) {
      return projectName;
    }
    if (productName) {
      const prefix = /^personalized/i;
      return `${prefix.test(productName) ? '' : t('saveProjectDialog.personalized')} ${productName}`;
    }
    return `${t('saveProjectDialog.personalized')} card`;
  }, [projectName, productName, hasDefaultProjectName]);

  return {
    priceBook,
    productName,
    projectName,
    projectId,
    projectTypeCode,
    cardFaces,
    hasDefaultProjectName,
    defaultProjectName,
  };
};
