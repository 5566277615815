import { Font } from '../../../../global-types';
import { getFontName } from '../../../../utils';

/**
 * Retrieves the label value based on the given font ID.
 * @param {string} fontId - The font ID to search for.
 * @param {Array} fontCollection - The font collection to search in.
 * @returns {string} - The label value corresponding to the font ID, or null if not found.
 */
export const getLabelByFontId = (fontId: string, fontCollection: Font[] | undefined) => {
  const font = fontCollection?.find((item) => getFontName(item.id) === fontId);
  return font ? font.name : '';
};
