import { GetAddToCartErrorOptions } from '../analytics-context-types';
import { getPODModalTag, getPODProductTag } from './utils';

export const getAddToCartErrorTag = (options: GetAddToCartErrorOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const addToCartErrorEvent = [
    {
      eventInfo: {
        eventName: 'Add to Cart',
        eventAction: 'error',
      },
    },
  ];

  const { modal, user } = getPODModalTag('Print on Demand - Add to Cart', addToCartErrorEvent, options.projectID);

  const newModal = {
    ...modal,
    messages: { statusMessages: options.errorMessage },
  };

  return {
    modal: newModal,
    product,
    user,
  };
};
