import { RefObject } from 'react';
import { Canvas } from 'fabric/fabric-impl';

export const bringFrontObject = (canvas: RefObject<Canvas> | undefined) => {
  const activeObject = canvas?.current?.getActiveObject();
  const textBoxObjects = canvas?.current?.getObjects();

  if (activeObject && textBoxObjects && textBoxObjects.length > 0) {
    const topestPositionIndex = textBoxObjects.length - 1;
    // Bring the active text object to the front
    activeObject.bringToFront();
    activeObject.setOptions({ order: topestPositionIndex });
  }
};
