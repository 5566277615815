/**
 * Takes a data encoded eimage and returns that same image in a Blob format.
 * @param dataURI Data encoded image
 * @returns Blob file or null if dataURI is not a data encoded image.
 */
export const dataUriToBlob = (dataURI: string): Blob | null => {
  if (dataURI.substring(0, 5) !== 'data:') {
    return null;
  }

  const bytes =
    dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : decodeURI(dataURI.split(',')[1]);
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i++) ia[`${i}`] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};
