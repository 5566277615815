import { cropAndRotateFormData } from '../../../global-types';
import { cropAndRotateImage, uploadToAssetStorage } from '../../../services/image';
import { dataUriToBlob } from '../../../utils/utility/data-uri-to-blob';
import { createSingleAssetUrl } from '../../../utils/utility/image-create-single-asset-url';

type UploadCroppedImageProps = {
  croppedImageUrl: string;
  cropAndRotateFormData: cropAndRotateFormData;
  imageName: string;
  projectId: string;
  onUploadSuccess: (imageUrl: string) => void;
  onUploadError: (error: Error) => void;
};
/**
 * Uploads the cropped image to S3 and returns the asset storage URL to be bound to fabric-image
 * @param fabricImageUrl Data URI of the cropped image
 * @param callback Callback function to handle the asset storage URL
 * @returns void
 */
export const uploadCroppedImage = async ({
  croppedImageUrl,
  cropAndRotateFormData,
  imageName,
  projectId,
  onUploadSuccess,
  onUploadError,
}: UploadCroppedImageProps) => {
  // Convert cropped image data URI to Blob
  const imageBlob = dataUriToBlob(croppedImageUrl);

  if (!imageBlob) {
    onUploadError(new Error('Failed to convert cropped image data URI to Blob'));
    return;
  }

  // Create a new file and attatch blob to it.  This is needed to upload the image to s3
  const imageFile = await new File([imageBlob], 'image.png', { type: 'image/png' });

  // Fetch the asset URL to s3 bucket
  const s3Url = await createSingleAssetUrl(imageFile);

  if (s3Url.length <= 0) {
    onUploadError(new Error('Failed to create asset URL at upload-cropped-image'));
    return;
  }

  // Upload the image to the asset storage URL obtained in previous step
  await uploadToAssetStorage(s3Url, imageFile)
    .then(async () => {
      // Remove the unnecessary query parameters from the URL before uploading to crop/rotate endpoint
      cropAndRotateFormData.image_url = s3Url.split('?X-')[0];
      await cropAndRotateImage(projectId, imageName, cropAndRotateFormData)
        .then((image) => {
          if (image?.data?.image_url) {
            onUploadSuccess(image.data.image_url);
            return;
          }

          onUploadError(new Error('image_url not found in cropAndRotate response'));
        })
        .catch((error) => {
          onUploadError(error);
          return;
        });
    })
    .catch((error) => {
      onUploadError(error);
    });
};
