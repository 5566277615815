import { ErrorResponse } from '../global-types';
import { preventUnload } from './dom';

/**
 * Handles API errors by checking for status codes,
 * responding, and throwing analytics code
 *
 * @param errors Array of errors to be stored and saved to analytics
 */
export const handleApiErrors = (errors: ErrorResponse, onAuthError: () => void, callback?: () => void) => {
  // Check check for system error

  if (errors.status === 401) {
    onAuthError();
  }

  // Remove unload event
  window.removeEventListener('beforeunload', preventUnload);
  if (callback) {
    callback();
  }
};
