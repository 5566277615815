import { ButtonModes } from '@hallmark/web.core.buttons.button';
import { ButtonGroupVariant } from '@hallmark/web.core.buttons.button-group';
import { IconButtonBrandColors } from '@hallmark/web.core.buttons.icon-button';
import { RangeVariants } from '@hallmark/web.core.forms.range';
import { TypographyVariants } from '@hallmark/web.core.typography.typography';
import { alignmentDrawerBtnList } from '@hallmark/web.page-components.print-on-demand.text-editor.alignment-drawer';
import { RangeValuePosition } from '@hallmark/web.page-components.print-on-demand.text-editor.size-drawer';
import { MoreButtonPosition } from '@hallmark/web.page-components.print-on-demand.text-editor.text-color-drawer/dist/text-color-drawer-types';
import { TextOptionDrawerVariant } from '@hallmark/web.page-components.print-on-demand.text-editor.text-option-drawer';
import { BrandColors } from '@hallmark/web.styles.colors';
import DeleteIcon from '../../assets/Delete.svg';
import RotateIcon from '../../assets/Rotate.svg';
import ScaleIcon from '../../assets/Scale.svg';
import UKAddPhotoIcon from '../../assets/camera-icon-button-48-uk.svg';
import CircleIcon from '../../assets/circle-icon.svg';
import UKEditIcon from '../../assets/edit-icon-uk.svg';
import RectangleIcon from '../../assets/rectangle-icon.svg';
import UKUserZoneAddPhotoIcon from '../../assets/user-zone-add-photo-icon-uk.svg';
import UKUserZoneAddTextIcon from '../../assets/user-zone-add-text-icon-uk.svg';
import UKUserZoneAddWamIcon from '../../assets/user-zone-add-wam-icon-uk.svg';
import { HeaderVariants } from '../../components/print-on-demand-header/print-on-demand-header-types';
import { RegionalCodesList } from '../../global-types';
import { TextEditorDrawerType } from '../../global-types/regions-list';
import colorsList from '../../styles/util.scss';
import { RegionalSchema } from '../regional-config-types';

export const configUK: RegionalSchema = {
  gtm: {
    id: 'GTM-NJJHJR6',
  },
  editor: {
    hasCustomBordersAndShadows: false,
  },
  screen: {
    defaultFontId: 107,
    defaultFontFamily: 'fontid-107',
  },
  preview: {
    hasEmailButton: true,
  },
  cardEditor: {
    faceTypeText: true,
    textEditorDrawerType: TextEditorDrawerType.Bottom,
    photoZoneBackgroundColor: '#838684',
    editableAreaDefaultStrokeColor: 'rgba(41, 48, 53, 1)',
    editableAreaDefaultFillColor: 'rgba(255, 255, 255, 0.20)',
    foldLineColor: 'gray',
  },
  cardSelectBar: {
    containerClass: 'footer',
  },
  saveProjects: {
    saveButton: {
      type: 'LoadingButton',
    },
    hasProjectNameFeature: true,
  },
  navigation: {
    useAlternativeExitLogic: false,
  },
  textOptionDrawer: {
    shouldRenderFooter: true,
    showVeil: true,
  },
  alignmentDrawer: {
    btnList: alignmentDrawerBtnList,
    actionButtonMode: ButtonModes.Primary,
    cancelButtonMode: ButtonModes.Secondary,
    hasActionButton: true,
    hasCancelButton: true,
    buttonGroupMode: ButtonGroupVariant.Primary,
  },
  textColorDrawer: {
    moreButtonPosition: MoreButtonPosition.RIGHT,
    ButtonGroupVariant: ButtonGroupVariant.Primary,
    hasPurpleShadowEffect: false,
    actionButtonMode: ButtonModes.Primary,
    cancelButtonMode: ButtonModes.Secondary,
    hasActionButton: true,
    hasCancelButton: true,
  },
  fontDrawer: {
    hasActionButton: true,
    hasCancelButton: true,
    actionButtonMode: ButtonModes.Primary,
    cancelButtonMode: ButtonModes.Secondary,
  },
  sizeDrawer: {
    hasCancelButton: false,
    hasActionButton: true,
    actionButtonMode: ButtonModes.Primary,
    cancelButtonMode: ButtonModes.Secondary,
    rangeVariant: RangeVariants.Primary,
    rangeValuePosition: RangeValuePosition.Bottom,
  },
  orderDrawer: {
    hasCancelButton: true,
    hasActionButton: true,
    actionButtonMode: ButtonModes.Primary,
    cancelButtonMode: ButtonModes.Secondary,
    labelTypographyVariant: TypographyVariants.FieldLabel,
  },
  rotateDrawer: {
    hasCancelButton: true,
    hasActionButton: true,
    actionButtonMode: ButtonModes.Primary,
    cancelButtonMode: ButtonModes.Secondary,
    labelTypographyVariant: TypographyVariants.FieldLabel,
    rangeVariant: RangeVariants.Primary,
    hasRangeLabel: true,
    rangeLabelColor: BrandColors.MediumGray,
    rangeLabelVariant: TypographyVariants.InputText,
    rangeLabelSymbol: 'percent',
  },
  colorPalleteDrawer: {
    titleTypographyVariant: TypographyVariants.Headline2,
  },
  userZones: {
    stokeAndFillHide: false,
    hasTextButton: true,
    addTextIcon: UKUserZoneAddTextIcon,
    addTextIconOffset: 0,
    hasPhotoButton: true,
    addPhotoIcon: UKUserZoneAddPhotoIcon,
    addPhotoIconOffset: -100,
    hasWAMButton: true,
    addWAMIcon: UKUserZoneAddWamIcon,
    addWAMIconOffset: 100,
    hasStickerButton: false,
    addStickerIcon: null,
    addStickerIconOffset: 0,
    hasInstructions: true,
  },
  photoDrawer: {
    hasTitle: true,
    hasSubtitle: false,
    titleTypographyVariant: TypographyVariants.Headline5,
    subtitleTypographyVariant: TypographyVariants.Body,
    buttonModes: ButtonModes.Tertiary,
    buttonTypographyVariant: TypographyVariants.BodyBold,
    uploadInstructionsPosition: 'bottom',
  },
  imageEditDrawer: {
    hasFooterContent: true,
    headerContentType: TextOptionDrawerVariant.Variant1,
    hasLayoutVariant: false,
    applyButtonMode: ButtonModes.Primary,
    cancelButtonMode: ButtonModes.Secondary,
  },
  header: {
    svgColor: IconButtonBrandColors.Black,
    saveDialogTheme: RegionalCodesList.uk,
    variant: HeaderVariants.US,
  },
  loader: {
    useCrownLoader: false,
  },
  carousel: {
    hasNavigationButtons: true,
  },
  instructionStep: {
    titleVariant: TypographyVariants.Subtitle,
    titleColor: BrandColors.MediumGray,
  },
  scaleDrawer: {
    hasCancelButton: true,
    hasActionButton: true,
    actionButtonMode: ButtonModes.Primary,
    cancelButtonMode: ButtonModes.Secondary,
    labelTypographyVariant: TypographyVariants.BodyBold,
    labelValueTypographyVariant: TypographyVariants.Body,
    rangeVariant: RangeVariants.Primary,
  },
  customControls: {
    crop: {
      hasRotateIcon: false,
      hasMiddleControls: true,
      hasCornerControls: true,
    },
    image: {
      hideMiddleControls: true,
    },
    icons: {
      deleteIcon: DeleteIcon,
      rotateIcon: RotateIcon,
      scaleIcon: ScaleIcon,
      rectangleIcon: RectangleIcon,
      circleIcon: CircleIcon,
      editIcon: UKEditIcon,
      addPhotoIcon: UKAddPhotoIcon,
    },
    hasBorders: true,
    borderColor: '#333333',
    controlsVisibility: {
      tl: true,
      tr: true,
      br: true,
      bl: true,
      mt: false,
      mb: false,
      ml: true,
      mr: true,
      mtr: true,
      rotateControl: false,
    },
    controlsPositionsTextVisibility: {
      mr: true,
      ml: true,
      bl: true,
      mtr: true,
      tr: true,
      tl: true,
      br: true,
    },
    renderImageIconSize: 1,
    mappingIcon: {
      region: RegionalCodesList.us,
    },
    possitions: {
      delete: {
        x: 0,
        y: -0.5,
        offsetY: -40,
      },
      rotate: {
        x: 0,
        y: -0.5,
        offsetY: -40,
        withConnection: true,
      },
      resize: {
        x: 0.5,
        y: 0.5,
      },
    },
    controlBehaviors: {
      tr: 'drawImage',
      bl: 'drawImage',
      tl: 'drawImage',
      br: 'drawImage',
      ml: 'drawImageAdjust',
      mr: 'drawImageAdjust',
      deleteControl: 'sizeTextIcon',
    },
  },
  canvas: {
    textColor: colorsList[BrandColors.CharcoalGrey],
  },
  imagesUpload: {
    maxFileSize: 10 * 1024 * 1024, // 10MB
    maxFilesCount: 20,
  },
  dialog: {
    useAlternativeDialog: false,
    cancelButtonMode: ButtonModes.TextLink,
    applyButtonMode: ButtonModes.Primary,
    applyTypographyVariant: TypographyVariants.Body,
    cancelTypographyVariant: TypographyVariants.Body,
  },
  toast: {
    variant: 'Global',
  },
  scssStyles: {
    appBackground: colorsList[BrandColors.EditorBackground],
    headerBackgroundMobile: colorsList[BrandColors.White],
  },
  exitUrl: {
    cardsPath: '/cards',
    cartPath: '/shopping-cart',
  },
};
