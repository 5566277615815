import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Checkbox } from '@hallmark/web.core.forms.checkbox';
import { Dropdown } from '@hallmark/web.core.forms.dropdown';
import { RadioCard } from '@hallmark/web.core.forms.radio-card';
import { TextField } from '@hallmark/web.core.forms.text-field';
import { HtmlTagOverrides, Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useAppContext, setIsSendToMe } from '../../context/app-context';
import { useInitializationDataContext } from '../../context/data-context';
import { Address, AddressForm as AddressFormData, AddressTypes, PrefixForm } from '../../global-types/addresses';
import { usStates } from '../../global-types/us-states';
import { useIsOneToMany } from '../../hooks';
import { getRequired } from '../../utils/address';
import { isLoggedIn } from '../../utils/login';
import AddressBook from '../address-book/address-book';
import { Contact } from '../address-book/address-book-types';
import { AddressFormProps } from './address-form-types';
import styles from './address-form.module.scss';
import { getContactFormData, validStringRule, validZipCodeRule, getThemeZipLength } from './address-utils';
import { AddressBookButton, CollapseSection, EnvelopeRadioGroup } from './fragments';

export const AddressForm = ({
  addressType,
  addClass,
  shouldRenderEnvelope,
  formHandlers: {
    getValues,
    setValue,
    register,
    formState: { errors },
    trigger,
    watch,
  },
}: AddressFormProps): React.ReactElement => {
  const [isAddressBookOpen, setIsAddressBookOpen] = useState(false);
  const classes = classNames(styles['address-form-wrapper'], addClass);

  const { t } = useTranslation();
  const { initializedDataState } = useInitializationDataContext();
  const { appState, appDispatch } = useAppContext();
  const { isUS, isUK } = initializedDataState;
  const { search, pathname } = useLocation();
  const history = useHistory();
  const isOneToMany = useIsOneToMany();
  const formTitle =
    addressType === AddressTypes.RECIPIENT ? `${t('addressForm.recipientTitle')}` : `${t('addressForm.returnTitle')}`;

  if (appState.isSendToMe) {
    setValue('send_to', 'me');
    // If false, form defaults to 'them'
  }

  const shortFieldError = (min: number, max: number) => `${t('addressUtil.shortFieldError', { min, max })}`;

  const usStateOptions = Object.keys(usStates).map((key) => ({ value: key, label: usStates[`${key}`] }));

  const handleAddressBookSubmit = (newAddress: Contact) => {
    const addressFormValues = getValues();
    const contactFormData = getContactFormData(addressFormValues, newAddress);

    Object.keys(contactFormData).forEach((field) =>
      setValue(field as keyof Address, contactFormData[`${field}`], { shouldValidate: true }),
    );
  };

  const formatPostcode = () => {
    const { zip } = getValues();
    let newZip = zip.replace(/ /g, '');
    if (newZip.length > 4) {
      const firstHalf = newZip.substring(0, newZip.length - 3);
      const lastHalf = newZip.slice(newZip.length - 3);
      newZip = `${firstHalf} ${lastHalf}`;
      setValue('zip', newZip.toUpperCase());
    } else {
      setValue('zip', newZip.toUpperCase());
    }
  };

  // TODO: remove this and all onChange props on the inputs below when MGE is retired. this was added for MGE
  const handleChange = (inputName: keyof AddressFormData) => {
    isUK && formatPostcode();
    if (inputName === 'send_to') {
      // Store send_to in state in case user leaves/returns
      const isSendToMe = getValues('send_to') === 'me';
      setIsSendToMe(appDispatch, isSendToMe);
    }

    setTimeout(() => {
      trigger(inputName);
    }, 250);
  };

  const envelope = watch('envelope');

  useEffect(() => {
    if (isOneToMany) {
      const searchParams = new URLSearchParams(search);
      searchParams.set('envelope', envelope as string);
      history.replace({ pathname, search: searchParams.toString() });
    }
  }, [envelope]);

  const shouldHideAddressBook = shouldRenderEnvelope && envelope === 'blank';

  return (
    <div className={classes}>
      {isUS && (
        <AddressBook
          isOpen={isAddressBookOpen}
          handleAddressBookSubmit={handleAddressBookSubmit}
          onClose={() => setIsAddressBookOpen(false)}
        />
      )}
      <form className={styles['address-form']}>
        <div tabIndex={-1} className={styles['address-form-content']}>
          {shouldRenderEnvelope ? (
            <EnvelopeRadioGroup handleChange={handleChange} register={register} />
          ) : (
            formTitle && (
              <Typography
                variant={TypographyVariants.Subtitle}
                addClass={styles.title}
                htmlTagOverride={HtmlTagOverrides.H2}
              >
                {formTitle}
              </Typography>
            )
          )}
          {isUK && (
            <div role="radiogroup" aria-labelledby="radio_button_group" className={styles['radio-cards-container']}>
              <RadioCard
                domId="send-to-me-radio-card"
                helperText="Send to Me"
                register={register('send_to', {
                  required: true,
                  onChange: () => handleChange('send_to'),
                })}
                addClass={styles['radio-card']}
                value="me"
              >
                Sent to you with a spare envelope
              </RadioCard>
              <RadioCard
                domId="send-to-them-radio-card"
                helperText="Send to Them"
                register={register('send_to', {
                  required: true,
                  onChange: () => handleChange('send_to'),
                })}
                addClass={styles['radio-card']}
                value="them"
              >
                Sent directly to the recipient
              </RadioCard>
            </div>
          )}
          {isUS && !shouldHideAddressBook && <AddressBookButton click={() => setIsAddressBookOpen(true)} />}
          <div className={classNames(envelope === 'blank' && styles['hide-address'])}>
            <TextField
              register={register('first_name', {
                required: getRequired(PrefixForm.ADDRESS_FORM, 'firstName'),
                validate: validStringRule(t('addressUtil.invalidCharacterError')),
                maxLength: { value: 40, message: shortFieldError(2, 40) },
                minLength: { value: 2, message: shortFieldError(2, 40) },
              })}
              domId={`${addressType}-first-name`}
              label={`${t('addressForm.firstName')}`}
              isError={Boolean(errors.first_name)}
              helperText={errors.first_name ? errors.first_name.message : ''}
              addClass={styles['form-field']}
              testId={'address_first_name'}
              required
              onChange={() => handleChange('first_name')}
            />
            <TextField
              register={register('last_name', {
                required: getRequired(PrefixForm.ADDRESS_FORM, 'lastName'),
                validate: validStringRule(t('addressUtil.invalidCharacterError')),
                maxLength: { value: 40, message: shortFieldError(2, 40) },
                minLength: { value: 2, message: shortFieldError(2, 40) },
              })}
              domId={`${addressType}-last-name`}
              label={`${t('addressForm.lastName')}`}
              isError={Boolean(errors.last_name)}
              helperText={errors.last_name ? errors.last_name.message : ''}
              addClass={styles['form-field']}
              testId={'address_last_name'}
              required
              onChange={() => handleChange('last_name')}
            />
            <TextField
              register={register('address_line_1', {
                required: getRequired(PrefixForm.ADDRESS_FORM, 'addressLine1'),
                maxLength: { value: 55, message: shortFieldError(1, 55) },
                minLength: { value: 1, message: shortFieldError(1, 55) },
              })}
              domId={`${addressType}-address-line-1`}
              label={`${t('addressForm.addressLine1')}`}
              isError={Boolean(errors.address_line_1)}
              helperText={errors.address_line_1 ? errors.address_line_1.message : ''}
              addClass={styles['form-field']}
              testId={'address_line_1'}
              required
              onChange={() => handleChange('address_line_1')}
            />
            <CollapseSection>
              <TextField
                register={register('address_line_2', {
                  maxLength: { value: 50, message: shortFieldError(0, 50) },
                  minLength: { value: 0, message: shortFieldError(0, 50) },
                })}
                domId={`${addressType}-address-line-2`}
                label={`${t('addressForm.addressLine2')}`}
                addClass={styles['form-field']}
                onChange={() => handleChange('address_line_2')}
              />
              <TextField
                register={register('company_name', {
                  maxLength: { value: 50, message: shortFieldError(0, 50) },
                  minLength: { value: 0, message: shortFieldError(0, 50) },
                })}
                domId={`${addressType}-company-name`}
                label={`${t('addressForm.companyName')}`}
                addClass={styles['form-field']}
                onChange={() => handleChange('company_name')}
              />
            </CollapseSection>
            <TextField
              register={register('city', {
                required: `${t('addressForm.isRequired', { requirement: 'City' })}`,
                validate: validStringRule(t('addressUtil.invalidCharacterError')),
                maxLength: { value: 26, message: shortFieldError(2, 26) },
                minLength: { value: 2, message: shortFieldError(2, 26) },
              })}
              domId={`${addressType}-city`}
              label={`${t('addressForm.city')}`}
              isError={Boolean(errors.city)}
              helperText={errors.city ? errors.city.message : ''}
              addClass={styles['form-field']}
              testId={'address_city'}
              required
              onChange={() => handleChange('city')}
            />
            {isUS && (
              <Dropdown
                options={[{ value: '', label: '' }, ...usStateOptions]}
                label={t('addressForm.state')}
                register={register('state_code', {
                  required: `${t('addressForm.isRequired', { requirement: 'State' })}`,
                  maxLength: { value: 2, message: t('addressUtil.validStateCodeError') },
                  minLength: { value: 2, message: t('addressUtil.validStateCodeError') },
                })}
                helperText={errors.state_code ? errors.state_code.message : ''}
                isError={Boolean(errors.state_code)}
                addClass={styles['form-field']}
                testId={'address_state_code'}
                required
                onChange={() => handleChange('state_code')}
              />
            )}
            <TextField
              register={register('zip', {
                required: `${t('addressForm.isRequired', {
                  requirement: isUK ? 'postcode' : 'ZIP code',
                })}`,
                validate: validZipCodeRule(t('addressUtil.validZipCodeError')),
                maxLength: { value: getThemeZipLength().max, message: t('addressUtil.validZipCodeError') },
                minLength: { value: getThemeZipLength().min, message: t('addressUtil.validZipCodeError') },
              })}
              domId={`${addressType}-zip`}
              label={`${t('addressForm.zip')}`}
              isError={Boolean(errors.zip)}
              helperText={errors.zip ? errors.zip.message : ''}
              addClass={styles['form-field']}
              testId={'address_zip'}
              required
              onChange={() => handleChange('zip')}
            />
            {isLoggedIn() && isUS && (
              <Checkbox domId="quick-address" register={register('isQuickAddress')} addClass={styles['checkbox']}>
                {t('addressForm.saveQuickAddress')}
              </Checkbox>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
