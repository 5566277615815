export const renderSizeTextIcon = (
  actualSize: number,
  control: fabric.Control,
  ctx: CanvasRenderingContext2D,
  icon: HTMLImageElement,
  scalingFactor: number,
) => {
  control.sizeX = actualSize;
  control.sizeY = actualSize;
  ctx.drawImage(icon, -actualSize / scalingFactor, -actualSize / scalingFactor, actualSize, actualSize);
};
