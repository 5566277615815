import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { IconPosition, TextLink } from '@hallmark/web.core.buttons.text-link';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useInitializationDataContext } from '../../../context/data-context';
import { getLoginUrl, isLoggedIn } from '../../../utils';
import { removeBeforeUnloadEvent } from '../../../utils';
import { AddressBookButtonProps } from '../address-form-types';
import styles from '../address-form.module.scss';

export const AddressBookButton = ({ click }: AddressBookButtonProps): React.ReactElement => {
  const {
    initializedDataState: { data: initializedData, lineItemUUID },
  } = useInitializationDataContext();

  const { t } = useTranslation();
  const loginRedirectUrl = getLoginUrl(
    initializedData?.project_id as string,
    initializedData?.account_id as string,
    lineItemUUID,
  );
  const isLogged = isLoggedIn();

  return (
    <>
      {!isLogged ? (
        <Button click={removeBeforeUnloadEvent} addClass={styles['login-button']} mode={ButtonModes.Custom}>
          <TextLink
            title={`${t('AddressBookButton.signIn')}`}
            url={loginRedirectUrl}
            icon={IconNames.AddressBookBold}
            iconPosition={IconPosition.Right}
          />
        </Button>
      ) : (
        <Button
          click={click}
          mode={ButtonModes.TextLink}
          endIcon={{ name: IconNames.AddressBookBold }}
          addClass={styles['text-link']}
          testId="address-book-button"
        >
          {`${t('AddressBookButton.addressBook')}`}
        </Button>
      )}
    </>
  );
};
