import { useEffect } from 'react';
import { setExitUrl, useAppContext } from '../context/app-context';
import { config } from '../regional-config';
import { getMonolithUrl } from '../utils/utility';
import { useQueryParams } from './useQueryParams';

const monolithUrl = getMonolithUrl() ?? '';

export const useExitUrl = () => {
  const queryParams = useQueryParams();
  const { appDispatch } = useAppContext();

  useEffect(() => {
    const monolithUrlObj = new URL(monolithUrl);
    const monolithOrigin = monolithUrlObj.origin;
    const skuReturn = queryParams.get('returnsku');
    const cartReturn = queryParams.get('fromCart') || queryParams.get('fromcart');
    const returnTo = queryParams.get('returnTo') || queryParams.get('returnto');

    if (returnTo) {
      const exitUrl = `${monolithOrigin}/${returnTo}`;
      setExitUrl(appDispatch, exitUrl);
      return;
    }
    if (skuReturn) {
      const exitUrl = `${monolithOrigin}${config.exitUrl.cardsPath}/${skuReturn}`;
      setExitUrl(appDispatch, exitUrl);
      return;
    }
    if (cartReturn) {
      const exitUrl = `${monolithOrigin}${config.exitUrl.cartPath}/`;
      setExitUrl(appDispatch, exitUrl);
      return;
    }
    if (window.document.referrer.includes(monolithOrigin)) {
      setExitUrl(appDispatch, window.document.referrer);
      return;
    }
    setExitUrl(appDispatch, monolithUrl);
  }, [queryParams]);
};
