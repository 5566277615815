import { GetAddNewColorOptions } from '../analytics-context-types';
import { getPODModalTag, getPODProductTag } from './utils';

export const getAddNewColorTag = (options: GetAddNewColorOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const addNewColorModalEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
    {
      eventInfo: {
        eventName: options.elementFormat,
        eventAction: 'format',
      },
    },
    {
      eventInfo: {
        eventName: 'color',
        eventAction: 'add new color',
      },
    },
  ];

  const { modal, user } = getPODModalTag('Edit Mode', addNewColorModalEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
