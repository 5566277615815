import { GetProjectAsset } from '../../global-types';
import { getProjectDetails } from '../../services';

export enum AssetTypeCodes {
  FrontPanel = 'F',
  InsidePanel = 'I',
}

export const loadProjectDetails = (projectId): Promise<any> => {
  return new Promise((resolve, reject) => {
    getProjectDetails(projectId)
      .then((res) => {
        const projectAssets = res?.data?.assets;

        if (projectAssets !== undefined) {
          const animationPanels = projectAssets.reduce<GetProjectAsset>((animationPreviewAssets, asset) => {
            /**
             * Asset Type Codes:
             * F - Front Panel
             * I - Inside Panels
             */
            if (
              asset.asset_type_code === AssetTypeCodes.FrontPanel ||
              asset.asset_type_code === AssetTypeCodes.InsidePanel
            ) {
              //If we don't have one yet, or if we have multiple and the current one is newer
              if (
                animationPreviewAssets[asset.asset_type_code] === undefined ||
                Date.parse(asset.created_at) > Date.parse(animationPreviewAssets[asset.asset_type_code].created_at)
              ) {
                animationPreviewAssets[asset.asset_type_code] = asset;
              }
            }

            return animationPreviewAssets;
          }, {});
          resolve(animationPanels);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
