import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { ButtonGroup, ButtonGroupVariant } from '@hallmark/web.core.buttons.button-group';
import { Drawer, DrawerPositions } from '@hallmark/web.core.feedback.drawer';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { CEIconNames } from '@hallmark/web.styles.fonts.icomoon';
import { extendedTextColors } from '../../../constants';
import { config } from '../../../regional-config';
import { ColorPalleteDrawerProps } from './color-pallete-drawer-types';
import styles from './color-pallete-drawer.module.scss';

export const ColorPalleteDrawer = ({
  isOpen,
  onClose,
  onColorSelect,
  selectedColor,
}: ColorPalleteDrawerProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Drawer
      addClass={styles['color-palette-drawer']}
      drawerPosition={DrawerPositions.Left}
      isOpen={isOpen}
      onClose={onClose}
      closeIcon={CEIconNames.XClose}
      headerContent={
        <Typography variant={config?.colorPalleteDrawer?.titleTypographyVariant} addClass={styles.header}>
          {t('colorPaletteDrawer.addColor')}
        </Typography>
      }
    >
      <div className={styles['footer-wrapper']}>
        <ButtonGroup
          ariaLabel="Text Color"
          addClass={styles['button-group']}
          defaultClickedIndex={extendedTextColors.indexOf(selectedColor)}
          variant={ButtonGroupVariant.Secondary}
        >
          {extendedTextColors.map((color) => (
            <Button
              mode={ButtonModes.Icon}
              addClass={classNames(styles['color-button'], styles[`shade-color-${color}`])}
              key={color}
              ariaLabel={color}
              click={() => onColorSelect(color)}
            >
              <Typography variant={TypographyVariants.SRT}>{color}</Typography>
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </Drawer>
  );
};
