import React from 'react';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { CEBrandColors } from '@hallmark/web.styles.colors';
import { CEIconNames } from '@hallmark/web.styles.fonts.icomoon';
import { OptionsContainerProps, DrawerButtonProps } from '../image-edit-drawer-types';

const DrawerButton = ({ onClick, icon, label }: DrawerButtonProps): React.ReactElement => {
  return (
    <Button
      testId={`drawer-button-${label}`}
      click={onClick}
      mode={ButtonModes.QuickActionActivated}
      startIcon={{ name: icon, size: 24, color: CEBrandColors.Purple }}
    >
      <Typography variant={TypographyVariants.BodyBold} color={CEBrandColors.Anthracite}>
        {label}
      </Typography>
    </Button>
  );
};

export const CEOptionsContainer = ({
  onCrop,
  onSizeChange,
  onRotateChange,
  onDelete,
  onOrderChange,
  onReset,
  isPodProductCode,
  isPhotoZoneImage,
  isSignAndSend,
  isStickerEditMode,
}: OptionsContainerProps) => (
  <>
    {!isStickerEditMode && <DrawerButton icon={CEIconNames.Crop} onClick={onCrop} label="Bijsnijden" />}
    <DrawerButton icon={CEIconNames.Resize} onClick={onSizeChange} label="Schalen" />
    <DrawerButton icon={CEIconNames.Refresh} onClick={onRotateChange} label="Draaien" />
    {isPodProductCode && !isPhotoZoneImage && (
      <DrawerButton icon={CEIconNames.Layers} onClick={onOrderChange} label="Volgorde" />
    )}
    {isPodProductCode && <DrawerButton icon={CEIconNames.Trash} onClick={onDelete} label="Verwijderen" />}
    {isSignAndSend && <DrawerButton icon={CEIconNames.Trash} onClick={onReset} label="Verwijderen" />}
  </>
);
