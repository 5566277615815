import React, { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Range, RangeVariants } from '@hallmark/web.core.forms.range';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useActiveCanvas } from '../../../../hooks';
import { config } from '../../../../regional-config';
import { getTextAndImageObjects } from '../../../../utils/canvas';
import { HeaderContentProps } from '../order-drawer-types';
import styles from '../order-drawer.module.scss';

export const HeaderContent = ({ currentOrder, handleOrderChange }: HeaderContentProps) => {
  const canvas = useActiveCanvas();
  const { min, max, tickCount } = useMemo(() => {
    const filteredObjects = getTextAndImageObjects(canvas?.current as fabric.Canvas);
    const objectsCount = filteredObjects.length;
    return {
      min: 0,
      max: objectsCount - 1,
      tickCount: objectsCount > 3 ? objectsCount : 3,
    };
  }, [canvas]);

  const { t } = useTranslation();
  const { register, setValue } = useForm();

  useEffect(() => {
    setValue('order-range', currentOrder);
  }, [currentOrder, setValue]);

  return (
    <div className={styles['header-wrapper']}>
      <div className={styles['range-label']}>
        <Typography variant={config?.orderDrawer?.labelTypographyVariant as TypographyVariants}>
          {t('orderDrawer.background')}
        </Typography>
        <Typography variant={config?.orderDrawer?.labelTypographyVariant as TypographyVariants}>
          {t('orderDrawer.foreground')}
        </Typography>
      </div>
      <Range
        addClass={styles['order-range']}
        domId="order-range-input"
        label="Order range"
        register={register('order-range', { valueAsNumber: true })}
        onChange={handleOrderChange}
        tickCount={tickCount}
        step={1}
        min={min}
        max={max}
        testId="order-drawer-range-input"
        variant={config?.orderDrawer?.rangeVariant as RangeVariants}
      />
    </div>
  );
};
