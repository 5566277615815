import { DefaultProductOptions } from '../analytics-context-types';
import { getDGModalTag, getPODProductTag } from './utils';

/**
 * Gets the modal and product tag to track when the user
 * is taken to the "Hooray" screen after eCard successfully sends
 *
 * @param options attributes to be included in product tag and modal event tag
 * @returns
 */
export const getSuccesfullySentEmailTag = (options: DefaultProductOptions) => {
  const modalEvent = [
    {
      eventInfo: {
        eventName: 'Flow End',
        eventAction: 'Digital Greetings',
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const { modal, user } = getDGModalTag('Hooray', modalEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
