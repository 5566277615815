import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CardFaceTypes } from '../../global-types/card-face';
import { config } from '../../regional-config';
import { getGroupedItemByName, CanvasDataTypes } from '../../utils';
import { useAppContext } from '../app-context';
import { useCardContext } from '../card-context';
import { useInitializationDataContext } from '../data-context';
import { useAnalytics } from './analytics-context-hooks';
import { POD_TAGS } from './analytics-context-tags';
import { AnalyticsProviderProps, CreateContextProps, EditFormats, TagOptions } from './analytics-context-types';
import { initializeDataDog } from './utils/initialize-data-dog';
import { initializeGtmAnalytics } from './utils/initialize-gtm';

const defaultEditFormats: EditFormats = {
  textAlignment: '',
  addColor: '',
  color: '',
  fontName: '',
  fontSize: '',
  crop: '',
  order: '',
  rotate: '',
  size: '',
  replace: '',
  move: null,
  eventAction: null,
  isTextModified: null,
};

export const AnalyticsContext = createContext<CreateContextProps>(undefined);

export const AnalyticsContextProvider = ({ children, shouldInjectScripts }: AnalyticsProviderProps) => {
  const { buildTag, track, dataLayerAvailable } = useAnalytics();
  const { initializedDataState } = useInitializationDataContext();
  const { cardState } = useCardContext();
  const { appState } = useAppContext();
  const { isUS } = initializedDataState;
  // README: below is useful in testing analytics as all analytics tags use the trackEvent function
  // make sure not to commit this as 'true'
  const debug = false;

  const editFormats = useRef<EditFormats>(defaultEditFormats);
  const uploadedPhotos = useRef<number[]>([]);
  const firstElementSelected = useRef(false);
  const isReplacingImage = useRef(false);
  const pageNavigationButtonClicked = useRef(null);
  const isOnEmailSplashScreen = useRef(false);
  const { activeCardIndex, cardFacesList } = cardState;
  const activeCardFace = cardFacesList[`${activeCardIndex}`] ?? {};
  const canvas = activeCardFace?.canvas;
  const currentEnvironment = process.env.HOST_ENV || process.env.REACT_APP_CURRENT_ENV;

  const updateEditFormats = useCallback((editedFormat: Partial<EditFormats>) => {
    editFormats.current = { ...editFormats.current, ...editedFormat };
  }, []);

  const resetEditFormats = (keepFormats?) => {
    updateEditFormats({
      ...defaultEditFormats,
      ...keepFormats,
    });
  };

  const getEditFormats = () => editFormats.current;

  const getDefaultProductOptions = () => ({
    productName: initializedDataState.data?.product?.product_name ?? '',
    productID: initializedDataState.data?.product_id ?? '',
    productType: initializedDataState.data?.project_type_code ?? '',
    projectID: initializedDataState.data?.project_id ?? '',
    productQuantity: appState.productQuantity ?? '',
  });

  const getElementPosition = (object?: fabric.Object, face?: string): string => {
    let cardSide, cardHalfSize;
    const { cardFormat: format } = cardState;
    const isSignSend = initializedDataState.data?.project_type_code === 'S';
    const cardFaceType = face || activeCardFace.type;
    const activeObject = object ? object : canvas?.current?.getActiveObject();
    const canvasWidth = canvas?.current?.getWidth() ?? 0;
    const canvasHeight = canvas?.current?.getHeight() ?? 0;
    if (activeObject) {
      if (cardFaceType === 'front') {
        return 'outside-front';
      } else if (format === 'portrait' && !isSignSend) {
        cardHalfSize = canvasWidth / 2;
        cardSide = (activeObject.left as number) >= cardHalfSize ? 'right' : 'left';
      } else if (format === 'landscape' || format === 'vertical' || isSignSend) {
        cardHalfSize = canvasHeight / 2;
        cardSide = (activeObject.top as number) >= cardHalfSize ? 'bottom' : 'top';
      }
      return `${cardFaceType}${cardSide ? `-${cardSide}` : ''}`;
    }
    return cardFaceType;
  };

  /**
   * Get the active card general area when an event was triggered,
   * with no specification of positioning inside a card face.
   * Can be used when tracking an event without an active object on the canvas.
   * @returns outside-front | outside-back | inside | (card-face)
   */
  const getCurrentCardArea = (): string => {
    const cardFace = activeCardFace.type?.split('-')[0];
    if (cardFace === CardFaceTypes.FACEFRONT) {
      return CardFaceTypes.AREAFRONT;
    } else if (cardFace === CardFaceTypes.FACEBACK) {
      return CardFaceTypes.AREABACK;
    } else if (cardFace === CardFaceTypes.FACEINSIDE) {
      return CardFaceTypes.AREAINSIDE;
    }
    return cardFace;
  };

  const trackEvent = (tagType: string, trackType: string, options?: TagOptions, keysToDelete?: string[]) => {
    if (dataLayerAvailable) {
      if (!keysToDelete) keysToDelete = ['messages', 'modal.messages'];
      const tag = buildTag(tagType, options, keysToDelete);
      // eslint-disable-next-line no-console
      debug && console.log(`tagType: ${tagType}`, tag);
      track(trackType);
    }
  };

  const trackStartFlow = (eventAction: string) => {
    const { START_FLOW } = POD_TAGS;
    const product = getDefaultProductOptions();
    const options = {
      elementPosition: getElementPosition() || 'outside-front',
      eventAction: eventAction,
      ...product,
    };
    trackEvent(START_FLOW, 'page', options);
  };

  const trackExitFlow = () => {
    const { EXIT_FLOW } = POD_TAGS;
    trackEvent(EXIT_FLOW, 'page', getDefaultProductOptions());
  };

  const trackDeleteElement = () => {
    const { DELETE_ELEMENT } = POD_TAGS;
    const activeObject = canvas?.current?.getActiveObject();
    const originalTextbox =
      activeObject && activeObject?.type === 'group'
        ? getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, activeObject as fabric.Group)
        : null;
    if (activeObject || originalTextbox) {
      const options = {
        ...getDefaultProductOptions(),
        elementType: originalTextbox ? originalTextbox.type : activeObject?.type,
        elementPosition: getElementPosition(originalTextbox as fabric.Object),
      };
      trackEvent(DELETE_ELEMENT, 'link', options);
    }
  };

  // TODO: this has been disabled because we don't want anything to be tracked on simply
  // selection. Please review at a later time.
  const trackObjectSelection = (targetObject: fabric.Object, actionType: string) => {
    const { OBJECT_SELECTION } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(),
      elementType: targetObject?.data?.type ?? targetObject?.type,
      elementPosition: getElementPosition(targetObject),
      actionType: actionType,
    };
    trackEvent(OBJECT_SELECTION, 'link', options);
  };

  const trackClickAddressRecipient = () => {
    const { CLICK_ADDRESS_RECIPIENT } = POD_TAGS;
    trackEvent(CLICK_ADDRESS_RECIPIENT, 'page', getDefaultProductOptions());
  };

  const trackClickNextToAddressSender = () => {
    const { CLICK_NEXT_ADDRESS_SENDER } = POD_TAGS;
    trackEvent(CLICK_NEXT_ADDRESS_SENDER, 'page', getDefaultProductOptions());
  };

  const trackLoadPreview = () => {
    const { CLICK_PREVIEW } = POD_TAGS;
    trackEvent(CLICK_PREVIEW, 'page', getDefaultProductOptions());
  };

  const trackClickPreviewMode = () => {
    const { CLICK_PREVIEW_MODE } = POD_TAGS;
    const product = getDefaultProductOptions();
    const options = {
      elementPosition: getElementPosition(),
      button: pageNavigationButtonClicked.current,
      ...product,
    };
    trackEvent(CLICK_PREVIEW_MODE, 'page', options);
  };

  const trackRetryHandlerTriggered = (eventAction: string, errorMessages: string[]) => {
    const { RETRY_TRIGGERED } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(),
      eventAction: eventAction,
      errorMessages: errorMessages,
    };
    trackEvent(RETRY_TRIGGERED, 'link', options);
  };

  const trackReturnToEdit = () => {
    const { RETURN_TO_EDIT } = POD_TAGS;
    trackEvent(RETURN_TO_EDIT, 'page', getDefaultProductOptions());
  };

  const trackAddToCart = () => {
    const { ADD_TO_CART } = POD_TAGS;
    trackEvent(ADD_TO_CART, 'page', getDefaultProductOptions());
  };

  /**
   * Tracks when the user clicks send mail now for DG
   * @param eventAction
   */
  const trackSendEmailNow = (eventAction: string) => {
    const { SEND_EMAIL_NOW } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(),
      eventAction,
    };
    trackEvent(SEND_EMAIL_NOW, 'page', options);
  };

  const trackTextEdits = (activeObject: fabric.Object) => {
    const { TEXT_FORMATS } = POD_TAGS;
    const isTypeAMessage =
      !activeObject?.data?.type &&
      (activeObject?.name?.startsWith(CanvasDataTypes.TemporaryTAMInput) ||
        activeObject?.name?.startsWith(CanvasDataTypes.PhotoTextZone));
    const options = {
      ...getDefaultProductOptions(),
      elementPosition: getElementPosition(),
      elementType: isTypeAMessage ? 'type-a-message' : activeObject?.data?.type ?? activeObject?.type,
      eventAction: editFormats.current.eventAction,
      textAlignment: editFormats.current.textAlignment,
      addColor: editFormats.current.addColor,
      color: editFormats.current.color,
      fontName: editFormats.current.fontName,
      fontSize: editFormats.current.fontSize,
      isTextModified: editFormats.current.isTextModified,
      move: editFormats.current.move,
    };
    trackEvent(TEXT_FORMATS, 'link', options);
  };

  const trackPhotoEdits = (activeObject: fabric.Object) => {
    const { IMAGE_FORMATS } = POD_TAGS;
    const isWriteAMessage =
      !activeObject?.data?.type && activeObject?.data?.zoneName?.indexOf(CanvasDataTypes.PhotoTextZone) > -1;
    const options = {
      ...getDefaultProductOptions(),
      elementPosition: getElementPosition(),
      elementType: isWriteAMessage ? 'write-a-message' : activeObject?.data?.type ?? activeObject?.type,
      eventAction: editFormats.current.eventAction,
      rotate: editFormats.current.rotate,
      order: editFormats.current.order,
      crop: editFormats.current.crop,
      size: editFormats.current.size,
      move: editFormats.current.move,
      replace: editFormats.current.replace,
    };
    trackEvent(IMAGE_FORMATS, 'link', options);
  };

  const trackEditElement = () => {
    const activeObject = canvas?.current?.getActiveObject();
    const isActiveObjectModified =
      editFormats.current && Object.values(editFormats.current).some((val) => val !== '' && val !== null);

    // stops edits from being reset (at the end of this function updateFormats) if activeobject is write a message or if replacing image
    if (activeObject?.type === 'rect' || activeObject === undefined || isReplacingImage.current) {
      return false;
    }

    // check if element is a textbox and is modified
    activeObject?.type === 'textbox' && isActiveObjectModified && trackTextEdits(activeObject);

    // check if element is a photo and is modified
    activeObject?.type === 'image' && isActiveObjectModified && trackPhotoEdits(activeObject);

    // check if element is a photoTextZone/TAM and is modified (WAM works as the 'image' type above)
    if (activeObject?.type === 'group') {
      const originalTextbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, activeObject as fabric.Group);
      originalTextbox &&
        (originalTextbox as fabric.Textbox).text?.length &&
        isActiveObjectModified &&
        trackTextEdits(activeObject);
    }

    resetEditFormats();
  };

  const trackUploadPhotoError = (errorMessage: string) => {
    const { UPLOAD_PHOTO_ERROR } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(),
      elementPosition: getElementPosition(),
      errorMessage,
    };

    trackEvent(UPLOAD_PHOTO_ERROR, 'link', options);
  };

  const trackAddToCartError = (errorMessage: string) => {
    const { ADD_TO_CART_ERROR } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(),
      errorMessage,
    };

    trackEvent(ADD_TO_CART_ERROR, 'link', options);
  };

  const updateUploadedPhotos = (uploadedImage) => uploadedPhotos.current.push(uploadedImage);

  const trackUploadedPhotos = () => {
    const { UPLOAD_PHOTO } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(),
      elementPosition: getElementPosition(),
      uploadedPhotos: uploadedPhotos.current,
    };

    trackEvent(UPLOAD_PHOTO, 'link', options);
    uploadedPhotos.current = [];
  };

  /**
   * Tracks when user chooses to have their envelope printed or left blank
   * @param choice - The user's choice for envelope printing (e.g., 'blank' or 'printed')
   * @param options - Other options needed for tracking (e.g., productType, productName, etc.)
   */
  const trackEnvelopePrintChoice = (eventAction: string) => {
    const { ENVELOPE_PRINT_CHOICE } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(),
      eventAction,
    };

    trackEvent(ENVELOPE_PRINT_CHOICE, 'link', options);
  };

  /**
   * Tracks when user attempts to move to addressing page, but still has unedited zones on the front of their card.  In these cases,
   * the user is warned and brought to the front of the card.
   *
   * @param statusMessage - The message that is displayed to the user
   */
  const trackUneditedZoneError = (statusMessage: string, object: fabric.Object, face: string) => {
    const { UNEDITED_ZONE_ERROR } = POD_TAGS;

    const typeOfObjectEvent = { eventName: '', eventAction: '' };

    if (object.data.type === CanvasDataTypes.EditableText) {
      typeOfObjectEvent.eventName = 'text';
      typeOfObjectEvent.eventAction = 'edit text-error';
    }

    if (object.data.type === CanvasDataTypes.PhotoZone) {
      typeOfObjectEvent.eventName = 'photo';
      typeOfObjectEvent.eventAction = 'missing image - error';
    }

    const options = {
      ...getDefaultProductOptions(),
      elementPosition: getElementPosition(object, face),
      typeOfObjectEvent,
      statusMessage,
    };

    trackEvent(UNEDITED_ZONE_ERROR, 'link', options);
  };

  /**
   * Tracks when user clicks on the project summary area (quantity-link)
   * to open the project summary drawer
   */
  const trackViewProjectSummary = () => {
    const { VIEW_PROJECT_SUMMARY } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(),
      eventAction: 'view',
    };

    trackEvent(VIEW_PROJECT_SUMMARY, 'link', options);
  };

  /**
   * Tracks when user leaves the project summary top drawer on a 1:many card
   *
   * @param eventAction - The method which the user leaves, either the x button or the exit button
   */

  const trackLeaveProjectSummary = (eventAction: 'x' | 'exit') => {
    const { LEAVE_PROJECT_SUMMARY } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(),
      eventAction,
    };

    trackEvent(LEAVE_PROJECT_SUMMARY, 'link', options);
  };

  /**
   * Tracks when user attempts to change the product quantity from project summary
   */
  const trackChangeQuantityProjectSummary = () => {
    const { CHANGE_QUANTITY_FROM_PROJECT_SUMMARY } = POD_TAGS;

    const options = getDefaultProductOptions();

    trackEvent(CHANGE_QUANTITY_FROM_PROJECT_SUMMARY, 'link', options);
  };

  /**
   * Tracks when user navigates to another page in the card select bar in edit or preview views
   *
   * @param button - Current product quantity in project summary.
   * @param page - Page navigated to
   */
  const trackPageNavigation = () => {
    const { PAGE_NAVIGATION } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(),
      button: pageNavigationButtonClicked.current,
      page: getElementPosition(),
    };

    trackEvent(PAGE_NAVIGATION, 'link', options);
  };

  /**
   * Tracks how a user opens the image-upload-drawer to upload a photo (photozone or toolbar btn)
   *
   * @param eventAction - 'photo object' (toolbar btn) | 'camera icon' (photozone btn)
   */
  const trackPhotoUploadMethod = (eventAction: string) => {
    const { PHOTO_UPLOAD_METHOD } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(),
      elementPosition: getCurrentCardArea(),
      eventAction: eventAction,
    };

    trackEvent(PHOTO_UPLOAD_METHOD, 'link', options);
  };

  const trackSignIn = (eventAction: string) => {
    const { SIGN_IN } = POD_TAGS;

    const options = {
      elementPosition: getElementPosition(),
      eventAction: eventAction,
      ...getDefaultProductOptions(),
    };

    trackEvent(SIGN_IN, 'link', options);
  };

  const trackDGPlayAnimation = () => {
    const { DG_PLAY_ANIMATION } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(),
      elementPosition: getCurrentCardArea(),
      isOnEmailSplashScreen: isOnEmailSplashScreen.current,
    };

    trackEvent(DG_PLAY_ANIMATION, 'link', options);
  };

  const trackSuccesfullySentEmail = () => {
    const { SUCCESFULLY_SENT_EMAIL } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(),
    };

    trackEvent(SUCCESFULLY_SENT_EMAIL, 'page', options);
  };

  const trackViewEmailSplash = () => {
    const { VIEW_EMAIL_SPLASH } = POD_TAGS;
    const options = getDefaultProductOptions();

    trackEvent(VIEW_EMAIL_SPLASH, 'link', options);
  };

  const trackKeepShopping = () => {
    const { KEEP_SHOPPING } = POD_TAGS;
    const options = getDefaultProductOptions();

    trackEvent(KEEP_SHOPPING, 'link', options);
  };

  const trackEmailDeliveryRecipient = () => {
    const { EMAIL_DELIVERY_RECIPIENT } = POD_TAGS;
    const options = getDefaultProductOptions();

    trackEvent(EMAIL_DELIVERY_RECIPIENT, 'page', options);
  };

  const trackDGViewFacePreview = (isEnvelope) => {
    const { DG_VIEW_FACE_PREVIEW } = POD_TAGS;
    const product = getDefaultProductOptions();
    const options = {
      elementPosition: isEnvelope ? 'Envelope' : getElementPosition(),
      button: pageNavigationButtonClicked.current,
      ...product,
    };
    trackEvent(DG_VIEW_FACE_PREVIEW, 'link', options);
  };

  const trackEditProjectName = () => {
    const { EDIT_PROJECT_NAME } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(),
      elementPosition: getElementPosition(),
      onSplashScreen: isOnEmailSplashScreen.current,
    };

    trackEvent(EDIT_PROJECT_NAME, 'link', options);
  };

  const trackDefaultProjectName = () => {
    const { DEFAULT_PROJECT_NAME } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(),
      elementPosition: getElementPosition(),
      onSplashScreen: isOnEmailSplashScreen.current,
    };

    trackEvent(DEFAULT_PROJECT_NAME, 'link', options);
  };

  const trackSaveLink = () => {
    const { SAVE_LINK } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(),
      elementPosition: getElementPosition(),
      onSplashScreen: isOnEmailSplashScreen.current,
    };

    trackEvent(SAVE_LINK, 'link', options);
  };

  const trackExitSaveProject = (eventAction: 'defaultName' | 'editName', eventExitAction: 'exit' | 'exit-x') => {
    const { EXIT_SAVE_PROJECT } = POD_TAGS;
    const options = {
      ...getDefaultProductOptions(),
      eventAction,
      eventExitAction,
    };

    trackEvent(EXIT_SAVE_PROJECT, 'link', options);
  };

  const trackWamPhotoUpload = (imageSize: string) => {
    const { WAM_PHOTO_UPLOAD } = POD_TAGS;

    const options = {
      ...getDefaultProductOptions(),
      elementPosition: getElementPosition(),
      imageSize,
    };

    trackEvent(WAM_PHOTO_UPLOAD, 'link', options);
  };

  useEffect(() => {
    initializeDataDog();
  }, []);

  useEffect(() => {
    config?.gtm?.id && initializeGtmAnalytics(window, document, 'script', 'dataLayer', config?.gtm?.id);
  }, []);

  const onTextChanged = useCallback(() => updateEditFormats({ isTextModified: true }), [updateEditFormats]);
  const onObjectMoving = useCallback(() => updateEditFormats({ move: true }), [updateEditFormats]);
  const onObjectRotated = useCallback(
    () => updateEditFormats({ rotate: `${canvas?.current?.getActiveObject()?.angle}` }),
    [updateEditFormats],
  );
  const onBeforeDeselect = useCallback(() => {
    !isReplacingImage.current && trackEditElement();
  }, [trackEditElement, isReplacingImage]);

  // tracks if text is changed
  useEffect(() => {
    canvas?.current?.on('text:changed', onTextChanged);

    // send activeObject to tracking function before it goes away on deselect
    canvas?.current?.on('before:selection:cleared', onBeforeDeselect);

    // tracks if object is moved
    canvas?.current?.on('object:moving', onObjectMoving);

    // tracks if object is rotated
    canvas?.current?.on('object:rotated', onObjectRotated);

    // unsubscribe from all events
    return () => {
      canvas?.current?.off('text:changed', onTextChanged);
      canvas?.current?.off('before:selection:cleared', onBeforeDeselect);
      canvas?.current?.off('object:moving', onObjectMoving);
      canvas?.current?.off('object:rotated', onObjectRotated);
    };
  }, [canvas, onTextChanged, onBeforeDeselect, onObjectMoving, onObjectRotated]);

  return (
    <AnalyticsContext.Provider
      value={{
        firstElementSelected,
        pageNavigationButtonClicked,
        isReplacingImage,
        isOnEmailSplashScreen,
        trackAddToCart,
        trackAddToCartError,
        trackChangeQuantityProjectSummary,
        trackClickAddressRecipient,
        trackClickNextToAddressSender,
        trackDeleteElement,
        trackLoadPreview,
        trackClickPreviewMode,
        trackObjectSelection,
        trackRetryHandlerTriggered,
        trackReturnToEdit,
        trackStartFlow,
        trackSendEmailNow,
        trackUneditedZoneError,
        updateEditFormats,
        resetEditFormats,
        getEditFormats,
        trackEditElement,
        updateUploadedPhotos,
        uploadedPhotos,
        trackUploadedPhotos,
        trackEnvelopePrintChoice,
        trackUploadPhotoError,
        trackExitFlow,
        trackLeaveProjectSummary,
        trackPageNavigation,
        trackViewProjectSummary,
        trackPhotoUploadMethod,
        trackDGPlayAnimation,
        trackSuccesfullySentEmail,
        trackViewEmailSplash,
        trackKeepShopping,
        trackEmailDeliveryRecipient,
        trackDGViewFacePreview,
        trackEditProjectName,
        trackDefaultProjectName,
        trackExitSaveProject,
        trackSaveLink,
        trackSignIn,
        trackWamPhotoUpload,
      }}
    >
      {shouldInjectScripts && isUS && (
        <HelmetProvider context={{}}>
          <Helmet defer={false}>
            {currentEnvironment === 'prod' ? (
              <>
                <script
                  type="text/javascript"
                  src="https://www.hallmark.com/on/demandware.store/Sites-hmk-onesite-Site/default/DataLayer-Show"
                ></script>
                <script
                  src="https://assets.adobedtm.com/5810f1912eae/124c804a7bd6/launch-2db0eb99f565.min.js"
                  async
                ></script>
              </>
            ) : (
              <>
                <script
                  type="text/javascript"
                  src="https://www.dev.hallmark.com/on/demandware.store/Sites-hmk-onesite-Site/default/DataLayer-Show"
                ></script>
                <script
                  src="https://assets.adobedtm.com/5810f1912eae/124c804a7bd6/launch-f4614507d2e1-development.min.js"
                  async
                ></script>
              </>
            )}
          </Helmet>
        </HelmetProvider>
      )}
      {children}
    </AnalyticsContext.Provider>
  );
};

// Hooks
export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalyticsContext must be used within AnalyticsProvider');
  }

  return context;
};
