import colorsList from '../../../../styles/util.scss';
import { HexToColorMap } from '../font-drawer-types';

export const hexToColorNameMap: HexToColorMap = Object.entries(colorsList).reduce(
  (acc: HexToColorMap, [colorName, hexValue]: [string, unknown]) => {
    if (typeof hexValue === 'string' && /^#[0-9A-F]{6}$/i.test(hexValue)) {
      acc[hexValue as string] = colorName;
    }
    return acc;
  },
  {},
);
