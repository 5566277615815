import { CanvasJson, ObjectJson } from '../../global-types';
import { isImage, isTextbox } from '../helper-settings';

/**
 * Takes canvasJSON from cardface and applies scaling factor, returning a
 * @param canvasJson
 * @param bleed
 * @param scalingFactor
 * @returns
 */
export const scaleCanvasObjects = (canvasJson: CanvasJson, bleed: number, scalingFactor: number) => {
  return [...canvasJson.objects]
    .filter((object) => object.name !== 'edit-icon')
    .map((obj) => {
      const canvasObject = { ...obj } as ObjectJson;
      const { scaleX = 1, scaleY = 1, width = 0, height = 0, left = 0, top = 0 } = canvasObject;
      canvasObject.left = (left + bleed) * scalingFactor;
      canvasObject.top = (top + bleed) * scalingFactor;
      // scaling clipPath
      if (canvasObject.clipPath) {
        const { scaleX = 1, scaleY = 1, left = 0, top = 0 } = canvasObject.clipPath;
        canvasObject.clipPath.scaleX = scaleX * scalingFactor;
        canvasObject.clipPath.scaleY = scaleY * scalingFactor;
        canvasObject.clipPath.left = (left + bleed) * scalingFactor;
        canvasObject.clipPath.top = (top + bleed) * scalingFactor;
      }
      // scaling images
      if (isImage(canvasObject)) {
        const imageSrc = canvasObject.src;
        canvasObject.src = imageSrc?.split('?w=')[0];
      }
      // scaling text boxes
      if (isTextbox(canvasObject)) {
        const { fontSize = 0 } = canvasObject;
        canvasObject.fontSize = fontSize * scalingFactor;
        canvasObject.width = width * scalingFactor;
        canvasObject.height = height * scalingFactor;
        return canvasObject;
      }
      canvasObject.scaleX = scaleX * scalingFactor;
      canvasObject.scaleY = scaleY * scalingFactor;
      return canvasObject;
    });
};
