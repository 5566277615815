import blankEnvelope from '../../assets/BlankEnvelope.svg';
import returnEnvelope from '../../assets/ReturnAddress.svg';
import { SelectedEnvelope } from '../../global-types';

export const envelopes: SelectedEnvelope[] = [
  {
    id: 'blank',
    image: blankEnvelope,
  },
  {
    id: 'return',
    image: returnEnvelope,
  },
];
