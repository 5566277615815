export type ScreenLoaderProps = {
  children?: React.ReactNode | null;
  isVisible?: boolean;
  type?: ScreenLoaderType;
};

export enum ScreenLoaderType {
  Default = 'default',
  Drawer = 'drawer',
}
