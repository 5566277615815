import { Canvas } from 'fabric/fabric-impl';
import { CardFace, InitializationData, RegionalCodesList } from '../global-types';
import { ErrorResponse } from '../global-types/services';
import { useSystemErrorHandling } from '../hooks';
import { CanvasDataTypes } from './canvas';
import { TransformedPersonalizationData } from './helper-settings';
import { addIdToTexts } from './utility/assign-ids-to-texts';

/**
 *
 * @param word String that will get capitalized
 * @returns Capitalized string
 */
export const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1);

/**
 * Returns a new object without the key passed by parameter. It will delete the key of nested arrays and objects
 *
 * @param obj Object from which key will be removed
 * @param key Name of the key to be removed
 * @returns New object not containing the passed key.
 */
export const removeObjectKey = <T extends object, K extends keyof T>(obj: T, key: K): Omit<T, K> => {
  return Object.keys(obj).reduce((result, currentKey) => {
    if (currentKey === key) {
      return result;
    }

    const value = obj[`${currentKey}`];
    if (Array.isArray(value)) {
      // Recursively remove the key from each element of the array
      result[`${currentKey}`] = value.map((item) => removeObjectKey(item, key));
    } else if (typeof value === 'object' && value !== null) {
      // Recursively remove the key from nested objects
      result[`${currentKey}`] = removeObjectKey(value, key);
    } else {
      result[`${currentKey}`] = value;
    }

    return result;
  }, {} as Omit<T, K>);
};

/**
 * Checks a string to see if it returns a 404 as a URL and the function returns a true/false
 *
 * @param url string that represents a url
 * @returns True/False boolean
 */

export const isUrlValid = async (url: string) => {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    return response.ok;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('URL validation failed:', error);
    return false;
  }
};

/**
 * Checks an array of CardFaces' background images and displays an error message if any return an error status
 *
 * @param faces Array of CardFaces from the initialize response
 * @param onSystemError hook function that handles errors
 * @param errorMessage string from translations file for the error to be thrown
 * @returns function that displays an error message if any images are found to be erroring
 */
export const validateImages = (
  faces: CardFace[],
  onSystemError: ReturnType<typeof useSystemErrorHandling>[0],
  errorMessage: string,
) => {
  faces.forEach((face) => {
    try {
      if (!isUrlValid(face.BackgroundUrl)) throw errorMessage;
    } catch (errors) {
      onSystemError(errors as ErrorResponse);
    }
  });
};

/**
 * Checks background image SRCs to ensure none are blank or include ?w=0 param, which has occurred in the past due to CORS errors during card editing
 * and resulted in bad JSON in placed orders
 *
 * @param data Array of TransformedPersonalizationData
 * @returns True if image SRCs are populated, false if image SRCs aren't populated
 */
export const didBackgroundImagesPopulate = (data: TransformedPersonalizationData[]) => {
  data.forEach((face) => {
    if (!face.PrintJson?.backgroundImage?.src || face.PrintJson?.backgroundImage?.src?.includes('?w=0')) {
      return false;
    }
  });
  return true;
};

export const formatResponseData = (data: InitializationData) => {
  if (data.is_digital_fulfillment) {
    data.project_type_code = 'D';
    data.name = 'Digital Greetings';
  }

  const dataWithTextIds = addIdToTexts(data);

  return dataWithTextIds;
};

/**
 * Get all or a single search param from the URL
 * @param param param to get from url
 * @returns URLSeachParams | string
 */
export const getUrlSearchParam = (param?: string) => {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);

  if (!param) {
    return urlParams;
  } else {
    return urlParams.get(`${param}`);
  }
};

/**
 * @param regions the current region we are using. Ex: us, uk, ce, all
 * @returns a boolean value confirming that the region is allowed to be used, based on the RegionalCodesList
 */
export const isRegion = (region = ''): boolean | null => {
  return Object.values(RegionalCodesList).some((regionalCode) => region.includes(regionalCode));
};

/**
 * Checks if the current locale matches any of the provided region codes.
 *
 * @param {RegionalCodesList[]} regions Array of region codes to check against the current locale.
 * @returns {boolean} True if the current locale matches any of the provided region codes.
 */
export const isAnyOfRegions = (regions: RegionalCodesList[]): boolean => {
  const locale: string = process.env.REACT_APP_THEME || '';
  return regions.some((regionCode) => locale === regionCode);
};

export const isToolbarVisible = (
  activeCanvas: React.RefObject<Canvas> | undefined,
  isSignAndSend: boolean,
): boolean => {
  if (!activeCanvas?.current) {
    return false;
  }

  if (!isSignAndSend) {
    return true;
  }

  return Boolean(activeCanvas.current._objects.find((obj) => obj?.name?.includes(CanvasDataTypes.PhotoTextZone)));
};

/**
 * Determines the appropriate Monolith URL based on the current environment and domain.
 * It first selects a base URL depending on the deployment environment (development, staging, production).
 * It then checks the hostname and adjusts the URL to include country-specific segments for supported domains.
 * If no specific domain match is found, it defaults to the general URL.
 *
 * @returns {string} The URL for the Monolith services, adjusted for environment and domain or default.
 */
export const getMonolithUrl = (): string => {
  const env = process.env.REACT_APP_CURRENT_ENV;
  let baseDomain = 'hallmark.com';

  switch (env) {
    case 'dev':
      baseDomain = 'development.hallmark';
      break;
    case 'stage':
      baseDomain = 'staging.hallmark';
      break;
    case 'prod':
      baseDomain = 'temp.hallmark';
      break;
    default:
      baseDomain = 'www.hallmark';
  }

  const hostname = typeof window !== 'undefined' ? window.location.hostname : `${baseDomain}.com`;
  const urlBase = process.env.REACT_APP_MONOLITH_URL || process.env.MONOLITH_URL || `https://${baseDomain}.com`;

  if (hostname && typeof hostname === 'string') {
    if (hostname.includes('hallmark.be')) {
      return `https://${baseDomain}.be/on/demandware.store/Sites-Hallmark-be-Site/nl_BE`;
    } else if (hostname.includes('hallmark.nl')) {
      return `https://${baseDomain}.nl/on/demandware.store/Sites-Hallmark-nl-Site/nl_NL`;
    }
  }

  return urlBase;
};
