import { GetUneditedZoneErrorOptions } from '../analytics-context-types';
import { getModalTag, getPODProductTag } from './utils';

/**
 * Tag that describes event when user receives an error message when trying to proceed from editor without
 * editing all zones on the front cover of their card.
 *
 * @param options - Tag variables passed down through props.
 * @returns
 */
export const getUneditedZoneErrorTag = (options: GetUneditedZoneErrorOptions) => {
  // Create event info
  const uneditedZoneWarningEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
    {
      eventInfo: {
        eventName: options.typeOfObjectEvent.eventName,
        eventAction: options.typeOfObjectEvent.eventAction,
      },
    },
  ];

  // Gets product info from options passed down through props.
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const { modal, user } = getModalTag(options.productType, 'Edit Mode', uneditedZoneWarningEvent, options.projectID);

  // Adds message from props to modal tag.
  const newModal = {
    ...modal,
    messages: [{ statusMessages: options.statusMessage }],
  };

  return {
    product,
    modal: newModal,
    user,
  };
};
