import { ceThemeTokens } from '@hallmark/web.themes.theme-provider';
import { ukThemeTokens } from '@hallmark/web.themes.uk-theme';
import { RegionalCodesList } from '../global-types';

const themeMapping: Partial<Record<RegionalCodesList, object>> = {
  [RegionalCodesList.uk]: ukThemeTokens,
  [RegionalCodesList.ce]: ceThemeTokens,
  [RegionalCodesList.us]: {},
};

/**
 * Determines the current locale's region code and retrieves the corresponding theme tokens.
 *
 * @returns The theme tokens corresponding to the current locale's region code.
 */
export const getThemeForCurrentRegion = (): object => {
  const locale: RegionalCodesList = (process.env.REACT_APP_THEME as RegionalCodesList) || RegionalCodesList.us;

  return themeMapping[locale as keyof typeof themeMapping] || {};
};
