import { GetObjectSelectionOptions } from '../analytics-context-types';
import { getPODModalTag, getPODProductTag } from './utils';

export const getObjectSelectionTag = (options: GetObjectSelectionOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const objectSelectionModalEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
    {
      eventInfo: {
        eventName: options.elementType,
        eventAction: options.actionType,
      },
    },
  ];

  const { modal, user } = getPODModalTag('Edit Mode', objectSelectionModalEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
