import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SFCCRedirect } from '../components/sfcc-redirect';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { PreviewView } from '../views/preview';
import { PODRoutes } from './index';

const FlagControlledRoutes = () => {
  const { SKIP_PREVIEW_STEP } = useFeatureFlags();

  return (
    <Switch>
      <Route path={`/card/customization`} key={'SFCCRedirect'} exact component={SFCCRedirect} />
      {PODRoutes.map(({ route, name, exact, view }) => (
        <Route path={`/card/customization${route}`} key={name} exact={exact} component={view} />
      ))}
      {!SKIP_PREVIEW_STEP && <Route path="/card/customization/preview" exact component={PreviewView} />}
    </Switch>
  );
};

export default FlagControlledRoutes;
