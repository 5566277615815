import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { IconNames } from '@hallmark/web.core.buttons.icon-button';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import paymentImage from '../../../assets/PaymentConfirmation.svg';
import { Layout } from '../../../components/layout';
import { useAnalyticsContext } from '../../../context/analytics-context';
import styles from '../preview.module.scss';

type EmailSplashProps = {
  onPlayAnimations: () => void;
  onContinue: () => void;
};

export const EmailSplash = ({ onContinue, onPlayAnimations }: EmailSplashProps) => {
  const { trackViewEmailSplash, trackKeepShopping, isOnEmailSplashScreen } = useAnalyticsContext();
  const { t } = useTranslation();

  const keepShoppingOnClick = () => {
    trackKeepShopping();
    onContinue();
  };

  const viewDigitalGreetingOnClick = () => {
    trackViewEmailSplash();
    onPlayAnimations();
  };

  useEffect(() => {
    // keep track of splash screen for analytics events
    isOnEmailSplashScreen.current = true;

    return () => {
      isOnEmailSplashScreen.current = false;
    };
  }, []);

  return (
    <Layout hideToolbar>
      <div className={styles['email-splash']}>
        <img src={paymentImage} />
        <Typography variant={TypographyVariants.Headline6} color={BrandColors.Black}>
          {t('emailSplash.title')}
        </Typography>
        <Typography variant={TypographyVariants.Body}>{t('emailSplash.body')}</Typography>
        <div className={styles['buttons']}>
          <Button
            click={viewDigitalGreetingOnClick}
            mode={ButtonModes.Secondary}
            startIcon={{ name: IconNames.PlaysmallBold }}
          >
            {t('emailSplash.animationButton')}
          </Button>
          <Button click={keepShoppingOnClick}>{t('emailSplash.continueButton')}</Button>
        </div>
      </div>
    </Layout>
  );
};
