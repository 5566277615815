export enum EnvironmentsType {
  UkDev = 'uk-dev',
  UkStage = 'uk-stage',
  UkProd = 'uk-prod',
  UsDev = 'us-dev',
  UsStage = 'us-stage',
  UsProd = 'us-prod',
  CeDev = 'ce-dev',
  CeStage = 'ce-stage',
  CeProd = 'ce-prod',
}
