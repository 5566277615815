import React, { useEffect, useState } from 'react';
import { Drawer, DrawerPositions } from '@hallmark/web.core.feedback.drawer';
import { useActiveCanvas } from '../../../hooks';
import { config } from '../../../regional-config';
import { getTextAndImageObjects } from '../../../utils/canvas';
import { FooterContent } from './fragments/footer-content';
import { HeaderContent } from './fragments/header-content';
import { OrderDrawerProps } from './order-drawer-types';
import styles from './order-drawer.module.scss';

export const OrderDrawer = ({ isOpen, handleOrderChange, onClose, onSubmit }: OrderDrawerProps) => {
  const canvas = useActiveCanvas();
  const [originalIndex, setOriginalIndex] = useState(0);

  useEffect(() => {
    if (canvas?.current) {
      const activeObject = canvas.current.getActiveObject();
      const filteredObjects = getTextAndImageObjects(canvas.current);
      if (activeObject) {
        const currentIndex = filteredObjects.findIndex((item: any) => item.obj === activeObject);
        setOriginalIndex(currentIndex);
      }
    }
  }, [canvas]);

  const handleClose = () => {
    handleOrderChange(originalIndex);
    onClose();
  };

  return (
    <Drawer
      hideCloseButton={true}
      showVeil={false}
      addClass={styles['order-drawer']}
      drawerPosition={DrawerPositions.Bottom}
      isOpen={isOpen}
      onClose={handleClose}
      role="toolbar"
      headerContent={<HeaderContent currentOrder={originalIndex ?? 0} handleOrderChange={handleOrderChange} />}
      layoutVariant={config?.orderDrawer?.layoutVariant || undefined}
    >
      <FooterContent onClose={handleClose} onSubmit={onSubmit} />
    </Drawer>
  );
};
