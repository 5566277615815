import { GetRetryHandlerTriggeredOptions, ModalType } from '../analytics-context-types';
import { getPODModalTag } from './utils';

export const getRetryHandlerTriggeredTag = (options: GetRetryHandlerTriggeredOptions) => {
  const retryHandlerModalEvent = [
    {
      eventInfo: {
        eventName: 'system error',
        eventAction: options.eventAction,
      },
    },
  ];

  const { modal }: { modal: ModalType } = getPODModalTag('Edit Mode', retryHandlerModalEvent, options.projectID);

  const newModal = {
    ...modal,
    messages: options.errorMessages.map((errorMessage) => ({ statusMessages: errorMessage })),
  };

  delete newModal.projectID;

  return {
    modal: newModal,
  };
};
