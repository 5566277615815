import React from 'react';
import { Image } from '@hallmark/web.core.display.image';
import { Typography, TypographyVariants, HtmlTagOverrides } from '@hallmark/web.core.typography.typography';
import { config } from '../../../../regional-config';
import { InstructionStepProps } from '../wam-instructions-drawer-types';
import styles from '../wam-instructions-drawer.module.scss';

export const InstructionStep = ({ title, text, imageUrl, stepNumber }: InstructionStepProps) => {
  return (
    <div className={styles['instruction-step']}>
      <div className={styles['instruction-image-wrapper']}>
        <Image src={imageUrl} addClass={styles['instruction-image']} alt="" lazyLoad={false} />
        <Typography variant={TypographyVariants.Caption} addClass={styles.badge}>
          {stepNumber}
        </Typography>
      </div>
      <div className={styles['instruction-text']}>
        <Typography
          addClass={styles['step-title']}
          variant={config?.instructionStep?.titleVariant as TypographyVariants}
          color={config?.instructionStep?.titleColor}
          htmlTagOverride={HtmlTagOverrides.H2}
        >
          {title}
        </Typography>
        <Typography
          addClass={styles['step-description']}
          variant={TypographyVariants.Body}
          color={config?.instructionStep?.descriptionColor}
        >
          {text}
        </Typography>
      </div>
    </div>
  );
};
