import { fabric } from 'fabric';
import i18next from 'i18next';
import userZoneAddPhotoIconUS from '../../../assets/user-zone-add-photo-icon-us.svg';
import userZoneAddStickerIconCE from '../../../assets/user-zone-add-sticker-icon-ce.svg';
import userZoneAddTextIconUS from '../../../assets/user-zone-add-text-icon-us.svg';
import userZoneAddWamIconUS from '../../../assets/user-zone-add-wam-icon-us.svg';
import { config } from '../../../regional-config';
import { CanvasDataTypes, getFontName, helperSettingsConfig } from '../../../utils';
import { getHorizontalCenterPoint, getVerticalCenterPoint } from '../card-editor-utils';
import { getEditableAreaInstructionsSize } from './get-editable-area-instructions-size';

const { userZoneInstructionsTopPosition } = helperSettingsConfig;

/**
 * Generate an Add Photo button for user zones
 *
 * @param zone - zone into which photo button will be added
 *
 * @returns promise
 */
export const getUserZonePhotoButton = (zone: fabric.Object): Promise<fabric.Group> => {
  return new Promise((resolve) => {
    fabric.Image.fromURL(config.userZones?.addPhotoIcon || userZoneAddPhotoIconUS, (image) => {
      image.setOptions({
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-photo-button`,
        hoverCursor: 'pointer',
        angle: zone.angle,
      });
      const addImageButton = new fabric.Group([image], {
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-photo-button-group`,
        hoverCursor: 'pointer',
        // UK PhotoButton positioned to the left (-100px) from our center point
        // US PhotoButton positioned to the left (-33px) from center point
        // CE PhotoButton positioned to the left (0px) from center point
        left: getHorizontalCenterPoint(zone) + (config.userZones?.addPhotoIconOffset || 0),
        top: getVerticalCenterPoint(zone),
        angle: zone.angle,

        data: {
          photoZoneId: zone.name,
          type: CanvasDataTypes.PhotoZoneButton,
        },
      });
      document.fonts.ready.then(() => {
        resolve(addImageButton);
      });
    });
  });
};

/**
 * Generate an Add Text button for user zones
 *
 * @param zone - zone into which photo button will be added
 *
 * @returns promise
 */
export const getUserZoneTextButton = (zone: fabric.Object): Promise<fabric.Group> => {
  return new Promise((resolve) => {
    fabric.Image.fromURL(config.userZones?.addTextIcon || userZoneAddTextIconUS, (image) => {
      image.setOptions({
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-text-button`,
        hoverCursor: 'pointer',
        angle: zone.angle,
      });
      const addTextButton = new fabric.Group([image], {
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-text-button-group`,
        hoverCursor: 'pointer',
        // UK button centered to the right (0px) from center point
        // US button positioned to the right (33px) from center point
        // CE centered to the right (-119px) from center point
        left: getHorizontalCenterPoint(zone) + (config.userZones?.addTextIconOffset || 0),
        top: getVerticalCenterPoint(zone),
        angle: zone.angle,

        data: {
          photoZoneId: zone.name,
          type: CanvasDataTypes.UserZoneAddTextButton,
        },
      });
      document.fonts.ready.then(() => {
        resolve(addTextButton);
      });
    });
  });
};

/**
 * Generate an Add Text button for user zones
 *
 * @param zone - zone into which photo button will be added
 *
 * @returns promise
 */
export const getUserZoneWamButton = (zone: fabric.Object): Promise<fabric.Group> => {
  return new Promise((resolve) => {
    fabric.Image.fromURL(config.userZones?.addWAMIcon || userZoneAddWamIconUS, (image) => {
      image.setOptions({
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-wam-button`,
        hoverCursor: 'pointer',
        angle: zone.angle,
      });
      const addWamButton = new fabric.Group([image], {
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-wam-button-group`,
        hoverCursor: 'pointer',
        // UK WAM Button positioned to the right (+100px) from our centered button
        left: getHorizontalCenterPoint(zone) + (config.userZones?.addWAMIconOffset || 0),
        top: getVerticalCenterPoint(zone),
        angle: zone.angle,

        data: {
          photoZoneId: zone.name,
          type: CanvasDataTypes.UserZoneAddWamButton,
        },
      });
      document.fonts.ready.then(() => {
        resolve(addWamButton);
      });
    });
  });
};

/**
 * Generate an Add Sticker button for user zones
 *
 * @param zone - zone into which photo button will be added
 *
 * @returns promise
 */
export const getUserZoneStickerButton = (zone: fabric.Object): Promise<fabric.Group> => {
  return new Promise((resolve) => {
    fabric.Image.fromURL(config.userZones?.addStickerIcon || userZoneAddStickerIconCE, (image) => {
      image.setOptions({
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-sticker-button`,
        hoverCursor: 'pointer',
        angle: zone.angle,
      });
      const addStickerButton = new fabric.Group([image], {
        originX: 'center',
        originY: 'center',
        selectable: false,
        name: `${zone.name}-user-zone-add-sticker-button-group`,
        hoverCursor: 'pointer',
        // CE Sticker Button positioned to the right (+119px) from our centered button
        left: getHorizontalCenterPoint(zone) + (config.userZones?.addStickerIconOffset || 0),
        top: getVerticalCenterPoint(zone),
        angle: zone.angle,

        data: {
          photoZoneId: zone.name,
          type: CanvasDataTypes.UserZoneAddStickerButton,
        },
      });
      document.fonts.ready.then(() => {
        resolve(addStickerButton);
      });
    });
  });
};

/**
   * Generate instructional text for user zones
   *
   * @param zone - zone into which instructional text will be added
  
   * @returns promise
   */
export const getUserZoneInstructions = (zone: fabric.Object): Promise<fabric.Text> => {
  return new Promise((resolve) => {
    const textElement = new fabric.Textbox(i18next.t('cardEditor.userZoneInstructions'), {
      originX: 'center',
      // Calculate instructions top position based on zone height
      // Try to calculate position of top as 33% of zone height. Fallback to userZoneInstructionsTopPosition (250)
      top: getVerticalCenterPoint(zone) - (zone && zone.height ? zone.height / 3 : userZoneInstructionsTopPosition),
      left: getHorizontalCenterPoint(zone), // Center horizontally
      width: zone.width ? zone.width - 20 : 0, // Reduce width to add 10px padding to left and right of textbox
      hasControls: false,
      editable: false,
      selectable: false,
      hoverCursor: 'default',
      textAlign: 'center',
      fontSize: getEditableAreaInstructionsSize(zone),
      fill: 'white',
      backgroundColor: '#333333',
      fontFamily: getFontName(120),
      data: {
        photoZoneId: zone.name,
        type: CanvasDataTypes.UserZoneInstructions,
      },
      name: `${zone.name}-user-zone-add-instructions`,
    });
    document.fonts.ready.then(() => {
      resolve(textElement);
    });
  });
};
