import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Range, RangeVariants } from '@hallmark/web.core.forms.range';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { CustomFabricObject } from '../../../../global-types/canvas';
import { useActiveCanvas } from '../../../../hooks';
import { config } from '../../../../regional-config';
import { convertRangeToRotation, convertRotationToRange } from '../../../../utils/utility/convert-rotation';
import { HeaderContentProps } from '../rotate-drawer-types';
import styles from '../rotate-drawer.module.scss';

export const HeaderContent = React.memo(({ initialValue }: HeaderContentProps): React.ReactElement => {
  const rangeName = 'RotateRange';
  const additionalClass = config?.rotateDrawer?.rangeLabelSymbol ?? 'percent';
  const [rangeKey, setRangeKey] = useState(0);
  const { register, setValue, watch } = useForm({
    defaultValues: {
      RotateRange: convertRotationToRange(initialValue),
    },
  });
  const rangeValue = watch(rangeName);
  const canvas = useActiveCanvas();
  const { t } = useTranslation();
  const activeObject = useMemo(() => canvas?.current?.getActiveObject() as CustomFabricObject, [canvas?.current]);

  const handleRotation = useCallback(
    (options) => {
      requestAnimationFrame(() => {
        if (activeObject) {
          const rotation = options.transform?.target.angle ?? 0;
          const convertedRotation = convertRotationToRange(rotation);
          setValue(rangeName, convertedRotation);
          setRangeKey((prevKey) => prevKey + 1);
        }
      });
    },
    [activeObject, setValue],
  );

  useEffect(() => {
    if (activeObject) {
      activeObject.on('rotating', handleRotation);
    }

    return () => {
      activeObject?.off('rotating', handleRotation);
    };
  }, [activeObject, handleRotation]);

  useEffect(() => {
    setValue(rangeName, convertRotationToRange(initialValue));
    setRangeKey((prevKey) => prevKey + 1);
  }, [initialValue, setValue, rangeName]);

  const onChange = useCallback(
    (valueInRange: number) => {
      requestAnimationFrame(() => {
        if (activeObject) {
          activeObject._setOriginToCenter();
          const newAngle = convertRangeToRotation(valueInRange);
          activeObject.set('angle', newAngle);
          activeObject.fire('custom:updateBorders');
          activeObject._resetOrigin();
          canvas?.current?.renderAll();
          setValue(rangeName, valueInRange);
        }
      });
    },
    [activeObject, canvas, rangeName, setValue],
  );

  const rotationLabel = useMemo(
    () => (
      <div className={styles['rotation-label']}>
        <Typography variant={config?.rotateDrawer?.labelTypographyVariant as TypographyVariants}>
          {t('rotateDrawer.left')}
        </Typography>
        <Typography
          variant={config?.rotateDrawer?.rangeLabelVariant ?? TypographyVariants.InputText}
          color={config?.rotateDrawer?.rangeLabelColor ?? BrandColors.MediumGray}
          addClass={`${styles['value']} ${styles[additionalClass as string]}`}
        >{`${Math.round(rangeValue)}`}</Typography>
        <Typography variant={config?.rotateDrawer?.labelTypographyVariant as TypographyVariants}>
          {t('rotateDrawer.right')}
        </Typography>
      </div>
    ),
    [rangeValue, t, additionalClass],
  );

  return (
    <div className={styles['header-wrapper']}>
      {rotationLabel}
      <Range
        key={rangeKey}
        domId="drawer-range"
        label={config?.rotateDrawer?.hasRangeLabel ? 'Rotate' : ''}
        min={-180}
        max={180}
        step={1}
        showValueInLabel={true}
        testId="rotate-drawer--range"
        tickCount={9}
        onChange={onChange}
        register={register(rangeName)}
        variant={config?.rotateDrawer?.rangeVariant as RangeVariants}
        enableTickInteraction={config?.rotateDrawer?.enableTickInteraction ?? false}
        initialValue={convertRotationToRange(activeObject?.angle || 0)}
      />
    </div>
  );
});
