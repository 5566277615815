import { ALIGNMENT_OPTIONS } from './constants';

/**
 * Finds the key that matches the given value in the ALIGNMENT_OPTIONS object.
 * @param {string | undefined} value - The value to search for.
 * @returns {number} - The key that matches the value, or 0 if no match is found.
 */
export const findKeyByValue = (value: string | undefined): number => {
  const entries = Object.entries(ALIGNMENT_OPTIONS);
  const foundEntry = entries.find(([, v]) => v === value);

  if (foundEntry) {
    return parseInt(foundEntry[0], 10);
  }

  return 0;
};
