import { DefaultProductOptions } from '../analytics-context-types';
import { getPODModalTag, getPODProductTag } from './utils';

export const getExitFlowTag = (options: DefaultProductOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const exitFlowModalEvent = [
    {
      eventInfo: {
        eventName: 'exit-x',
        eventAction: 'Exit',
      },
    },
  ];

  const { modal, user } = getPODModalTag('Edit Mode', exitFlowModalEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
