import {
  QuickAddressResponse,
  SaveAddressResponse,
  QuickAddressesResponse,
  GetAddresses,
  SaveAssetResponse,
} from '../global-types/addresses';

export const savedAddressResponse: SaveAddressResponse = {
  meta: {
    code: 201,
    version: '2.2.0',
  },
  data: {
    address_id: 'de2f6bfc-aa93-493a-a9e2-ffbe75bf3061',
    first_name: 'Test',
    last_name: 'Test',
    company_name: '',
    address_line_1: '2501 MCgee St',
    address_line_2: '',
    city: 'Kansas City',
    state_code: 'MO',
    zip: '64108',
    country_code: 'USA',
    address_type_code: 'S',
    created_at: '2020-06-09T03:51:45.013368587Z',
    last_updated_at: '2020-06-09T03:51:45.013368587Z',
    is_verified: false,
  },
};

export const getAddressesResponse: GetAddresses = {
  meta: {
    code: 201,
    version: '2.2.0',
  },
  data: [
    {
      address_id: 'de2f6bfc-aa93-493a-a9e2-ffbe75bf3061',
      first_name: 'Test',
      last_name: 'Test',
      company_name: '',
      address_line_1: '2501 MCgee St',
      address_line_2: '',
      city: 'Kansas City',
      state_code: 'MO',
      zip: '64108',
      country_code: 'USA',
      address_type_code: 'S',
      created_at: '2020-06-09T03:51:45.013368587Z',
      last_updated_at: '2020-06-09T03:51:45.013368587Z',
      is_verified: false,
    },
    {
      address_id: 'de2f6bfc-aa93-493a-a9e2-ffbe75bf3062',
      first_name: 'Test',
      last_name: 'Test',
      company_name: '',
      address_line_1: '2501 MCgee St',
      address_line_2: '',
      city: 'Kansas City',
      state_code: 'MO',
      zip: '64108',
      country_code: 'USA',
      address_type_code: 'R',
      created_at: '2020-06-09T03:51:45.013368587Z',
      last_updated_at: '2020-06-09T03:51:45.013368587Z',
      is_verified: false,
    },
  ],
};

export const quickAddressResponse: QuickAddressResponse = {
  meta: {
    code: 201,
    version: '2.2.0',
  },
  data: {
    contact_id: 'de2f6bfc-aa93-493a-a9e2-ffbe75bf3061',
    account_id: '2124857521',
    first_name: 'TestFirst',
    last_name: 'TestLast',
    company_name: 'TestCompany',
    address_line_1: '2501 MCgee St',
    address_line_2: 'Main Office',
    city: 'Kansas City',
    state_code: 'MO',
    zip: '64108',
    country_code: 'USA',
    contact_type_code: 'A',
    created_at: '2020-06-09T03:51:45.013368587Z',
    last_updated_at: '2020-06-09T03:51:45.013368587Z',
    is_verified: true,
  },
};

export const quickAddressesResponse: QuickAddressesResponse = {
  meta: {
    code: 200,
    version: '2.2.0',
  },
  data: {
    contacts: [
      {
        contact_id: 'de2f6bfc-aa93-493a-a9e2-ffbe75bf3061',
        account_id: '2124857521',
        first_name: 'TestFirst',
        last_name: 'TestLast',
        company_name: 'TestCompany',
        address_line_1: '2501 MCgee St',
        address_line_2: 'Main Office',
        city: 'Kansas City',
        state_code: 'MO',
        zip: '64108',
        country_code: 'USA',
        contact_type_code: 'A',
        created_at: '2020-06-09T03:51:45.013368587Z',
        last_updated_at: '2020-06-09T03:51:45.013368587Z',
        is_verified: true,
      },
      {
        contact_id: 'de2f6bfc-aa93-493a-a9e2-ffbe75bf3062',
        account_id: '2124857521',
        first_name: 'First',
        last_name: 'Last',
        company_name: 'Company',
        address_line_1: '2501 MCgee St',
        address_line_2: 'Main Office',
        city: 'Kansas City',
        state_code: 'MO',
        zip: '64108',
        country_code: 'USA',
        contact_type_code: 'A',
        created_at: '2020-06-09T03:51:45.013368587Z',
        last_updated_at: '2020-06-09T03:51:45.013368587Z',
        is_verified: true,
      },
    ],
    paging: {
      total_pages: 1,
      total_items: 2,
      next_page: null,
    },
  },
};

export const saveAssetResponse: SaveAssetResponse = {
  meta: {
    code: 201,
    version: '2.2.0',
  },
  data: {
    url: 'https://testurl.com/front',
  },
};
