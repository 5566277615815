import { useInitializationDataContext } from '../context/data-context';
import { CardType } from '../global-types/card';

/**
 * Hook that returns a boolean to check if the project is a Print on Demand OR Digital Greetings
 * Conditions for POD
 * - project_type_code should equal CardType.POD
 * Conditions for DG
 * - project_type_code should equal CardType.DG
 *
 * @returns true if the project is Digital Greeting or Print on Demand
 */
export const useIsPodProductCode = () => {
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  return [CardType.POD, CardType.DG].includes(initializedData?.project_type_code as CardType);
};
