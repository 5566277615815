import { fabric } from 'fabric';
import { CanvasDataTypes } from '../../../utils';

const LANDSCAPE_SCALING_DIVISOR = 398;
const PORTRAIT_SCALING_DIVISOR = 917;
const PORTRAIT_HEIGHT_ADJUSTED_SCALING_DIVISOR = 617;
const QUARTER_DIVISOR = 4;

/** Original zoom status and whether the original zoom has been applied. */
let originalZoom: number | undefined;
let isZoomApplied = false;
const zoomFactor = 1.4;

export const registerZoomEvent = (canvas: fabric.Canvas, isMobile: boolean, DISPLAY_ZOOM_DEV: boolean) => {
  if (isMobile && DISPLAY_ZOOM_DEV) {
    const handleZoomDownWrapper = () => onZoomText(canvas);
    canvas.on('mouse:down', handleZoomDownWrapper);
  }
};

export const onZoomText = (canvas: fabric.Canvas) => {
  const objActiveCanvas = canvas.getActiveObject();

  /** Handles the case of objects of type 'textbox' or 'userText'. */
  if (
    objActiveCanvas &&
    (objActiveCanvas.data?.type === CanvasDataTypes.EditableText ||
      objActiveCanvas.data?.type === CanvasDataTypes.UserText)
  ) {
    handleZoomIn(canvas, objActiveCanvas);
  } else {
    resetZoom(canvas);
  }
};

/** Save the original zoom state if not already done */
export const saveOriginalZoom = (canvas: fabric.Canvas): void => {
  if (typeof originalZoom === 'undefined') {
    originalZoom = canvas.getZoom();
  }
};

/** Apply the new zoom level if the original zoom has not been applied */
export const applyNewZoomLevel = (canvas: fabric.Canvas, zoomCenterPoint: fabric.Point): void => {
  if (!isZoomApplied) {
    const currentZoom = canvas.getZoom();

    const newZoom = currentZoom * zoomFactor;

    canvas.zoomToPoint(zoomCenterPoint, newZoom);
    canvas.requestRenderAll();
    isZoomApplied = true;
  }
};

export const handleZoomIn = (canvas: fabric.Canvas, objActiveCanvas: fabric.Object) => {
  const { left, top } = objActiveCanvas;
  const { width: viewPortWidth, height: viewPortHeight } = canvas;

  if (!left || !top || !viewPortWidth || !viewPortHeight) {
    return;
  }

  saveOriginalZoom(canvas);

  const landscapeScalingFactor = viewPortWidth / LANDSCAPE_SCALING_DIVISOR;
  const portraitScalingFactor = viewPortHeight / PORTRAIT_SCALING_DIVISOR;
  const portraitHeightAdjustedScalingFactor = viewPortHeight / PORTRAIT_HEIGHT_ADJUSTED_SCALING_DIVISOR;
  const quarterViewportWidth = viewPortWidth / QUARTER_DIVISOR;

  const adjustedLeftPosition = left * landscapeScalingFactor;
  const adjustedTopPosition = top * portraitScalingFactor;
  const adjustedTopPositionHeight = top * portraitHeightAdjustedScalingFactor;

  let zoomCenterPoint: fabric.Point;

  if (viewPortWidth < viewPortHeight) {
    zoomCenterPoint =
      adjustedLeftPosition < quarterViewportWidth
        ? new fabric.Point(viewPortWidth / 2, adjustedTopPosition)
        : new fabric.Point(adjustedLeftPosition, adjustedTopPosition);
  } else {
    zoomCenterPoint = new fabric.Point(adjustedLeftPosition - viewPortWidth / 2, adjustedTopPositionHeight);
  }

  applyNewZoomLevel(canvas, zoomCenterPoint);
};

/**
 * This function resets the zoom each time you click outside the text and leaves it as default for mobiles.
 */
export const resetZoom = (canvas: fabric.Canvas) => {
  /** If the original zoom was already applied, resets the zoom state. */
  if (isZoomApplied) {
    /** Restore the canvas view */
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    typeof originalZoom === 'number' ? canvas.setZoom(originalZoom) : undefined;

    /** Resets the state variables */
    originalZoom = undefined;
    isZoomApplied = false;
  }
};
