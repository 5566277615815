import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextLink } from '@hallmark/web.core.buttons.text-link';
import { Dialog, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useAppContext } from '../../context/app-context';
import styles from './redirect-dialog.module.scss';

export const RedirectDialog = () => {
  const { appState } = useAppContext();
  const { redirectUrl, isRedirectDialogOpen } = appState;
  const { t } = useTranslation();

  const handleCloseDialog = () => {
    window.location.href = redirectUrl;
  };
  return (
    <Dialog
      isOpen={isRedirectDialogOpen}
      footerType={DialogFooterType.Passive}
      onClose={() => handleCloseDialog()}
      titleDisabled
    >
      <div className={styles['dialog-body']}>
        <Typography variant={TypographyVariants.Body}>{t('redirectModal.modalText')}</Typography>
        <br />
        <TextLink url={redirectUrl} title={redirectUrl} />
      </div>
    </Dialog>
  );
};
