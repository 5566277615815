import { useRef } from 'react';
import { useCardContext } from '../../../context/card-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { isUserZone } from '../../../utils';

/**
 * Use this custom hook to manage the user zones validation. Say, if the user is allowed to move to address according to the edited zones in the card.
 *
 * @returns A function validateUserZones to check if the user has edited the necessary zones to move forward.
 * A skipValidation ref, to handle situations in which it is intended to skip the zones validations
 */
export const useUserZonesValidation = () => {
  const {
    cardState: { cardFacesList },
  } = useCardContext();
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const skipValidation = useRef(false);
  const projectTypeCode = initializedData?.project_type_code;

  const userZonesToValidate = cardFacesList
    .filter((face) => !!face.canvas.current)
    .flatMap((face) => {
      const canvas = face.canvas.current as fabric.Canvas;
      return canvas
        .getObjects()
        .filter((object) => isUserZone(object))
        .map((zone) => ({ zone: zone, face: face.type }));
    });

  const validateUserZones = () => {
    const facesToValidate =
      projectTypeCode === 'S' ? userZonesToValidate : userZonesToValidate.filter(({ face }) => face === 'front');
    // Get facesToValidate which have content
    const modifiedUserZones = facesToValidate.filter(({ zone }) => zone.data?.hasContent).length;
    if (projectTypeCode === 'S') {
      return modifiedUserZones > 0;
    }
    return modifiedUserZones === facesToValidate.length;
  };

  const uneditedZones = userZonesToValidate.filter(({ zone }) => !zone.data?.hasContent);

  return { validateUserZones, uneditedZones, skipValidation };
};
