import { Font } from '../../../../global-types';
import { getFontName } from '../../../../utils';

/**
 * Formats the font collection into the desired format.
 * @param {Array} fontCollection - The font collection to be converted.
 * @returns {Array} - The converted font collection.
 */
export const formatFontCollection = (fontCollection: Font[] | undefined) => {
  if (!fontCollection) {
    return [];
  }

  return fontCollection.map(({ name, id }) => ({
    id,
    family: getFontName(id),
    text: name,
    label: name,
  }));
};
