import React from 'react';
import classNames from 'classnames';
import { LoadingIndicator } from '@hallmark/web.core.feedback.loading-indicator';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { LoaderProps } from './loader-types';
import styles from './loader.module.scss';

export const Loader = ({ title, isLoading }: LoaderProps) => {
  const classes = classNames(styles['loader-background'], isLoading && styles.loading);
  return (
    <div className={classes} aria-hidden={!isLoading}>
      <div className={styles['loader-wrapper']} role="alert" aria-live="polite" aria-label="Loading">
        <LoadingIndicator title="Loading indicator with three bouncing dots" addClass={styles['loader-indicator']} />
      </div>
      {title && (
        <Typography addClass={styles['loader-title']} variant={TypographyVariants.Headline3}>
          {title}
        </Typography>
      )}
    </div>
  );
};

Loader.defaultProps = {
  title: '',
};
