import { CardFaceData } from '../../../global-types';
import { PreviewImage } from '../../../global-types/images';

/**
 * Gets image to display in thumbnail in card select bar
 *
 * @param face - Card face, either in the editor or in the preview view
 * @returns void
 */
export const getThumbnailImage = (face: CardFaceData) => {
  if (face.photoZoneTemplate) {
    return face.photoZoneTemplate;
  } else if (face.backgroundImage) {
    return `${face.backgroundImage}?w=40`;
  } else {
    // For FE previews
    return (face as unknown as PreviewImage).dataURL;
  }
};
