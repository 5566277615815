import { createRef, RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { fabric } from 'fabric';
import { setCardDataAction, useCardContext } from '../context/card-context';
import { useInitializationDataContext } from '../context/data-context';
import { CardFaceData } from '../global-types';
import { getSumCardFaceZones, parseCardFace, parseCardState } from '../utils';
import { removeCanvasScalingFactor } from '../utils/helper-settings';
import { useDimensionsWithBleed } from './useDimensionsWithBleed';

/**
 * Custom hook used to get the loadCardData function which gets data from card-context
 * and app-context, parses the card faces and updates the card state.
 *
 * @returns An object containing loadCardData function and the mutable ref object containing all
 * canvasRefs
 */
export const useLoadCardData = () => {
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { cardDispatch, cardState } = useCardContext();
  const { getImageDimensionsWithBleed } = useDimensionsWithBleed();
  const templateData = useMemo(
    () => initializedData?.variables.template_data,
    [initializedData?.variables.template_data],
  );
  const canvasRefs = useRef<RefObject<fabric.Canvas>[]>([]);
  const projectId = initializedData?.project_id;
  const projectType = initializedData?.project_type_code;

  /**
   * Gets data from card-context and app-context, parses the card faces and updates the card state.
   */
  const loadCardData = useCallback(() => {
    if (!templateData || !projectId || !projectType) {
      return;
    }
    canvasRefs.current = templateData.Faces.map((_, index) => canvasRefs.current[+index] ?? createRef<fabric.Canvas>());
    let totalZones = 0;
    const newCardState = parseCardState({
      prevState: cardState,
      projectId,
      cardData: templateData,
    });
    templateData.Faces.forEach((face, index) => {
      const cardFace = parseCardFace({
        currentState: newCardState,
        face,
        index,
        canvasRefs: canvasRefs.current,
      });
      // Check if cardface contains mobile data.  If so, add it to the card state.
      totalZones = getSumCardFaceZones(totalZones, cardFace);
      newCardState.cardFacesList.push(cardFace);
    });
    setCardDataAction(cardDispatch, {
      ...newCardState,
      totalZones,
    });
  }, [templateData, projectId, projectType, canvasRefs]);

  /**
   * Create a fabric canvas instance and sets the background image of the canvas. Then it converts it to JSON and returns it.
   * Use this function to create a JSON representation of the canvas for faces that will not be displayed in the card editor (i. e. preview-only faces).
   *
   * @param face Card face data from the card-context. Element of the array cardFacesList.
   * @returns the JSON representation of canvas in face
   */
  const createCanvasJson = useCallback(
    (face: CardFaceData) => {
      const imageDimensionsWithBleed = getImageDimensionsWithBleed(face);
      const canvas = new fabric.Canvas(`canvas-${face.faceId}`);
      const backgroundSrc = face.backgroundImage.replaceAll('\\', '/');
      return new Promise((resolve) => {
        fabric.Image.fromURL(
          `${backgroundSrc}?w=${imageDimensionsWithBleed.width}`,
          (image) => {
            image.scaleToWidth(imageDimensionsWithBleed.width);
            canvas.setBackgroundImage(image, () => {
              // Typecasting toJSON return as CanvasJSON was causing a publishing error due to insufficient type overlap.  Added this
              // as type any to get around the issue, however we need to revisit this and find a better solution.
              const canvasJson: any = canvas.toJSON();
              resolve(removeCanvasScalingFactor(canvasJson, false, initializedData?.project_type_code ?? ''));
            });
          },
          {
            left: imageDimensionsWithBleed.left,
            top: imageDimensionsWithBleed.top,
            fill: 'transparent',
            originX: 'left',
            originY: 'top',
            crossOrigin: 'anonymous',
          },
        );
      });
    },
    [getImageDimensionsWithBleed],
  );

  /**
   * Updates the card-context state by adding a printJson property to the faces that are preview only and that does not have this property set.
   * This function will be called whenever the useLoadCardData hook is executed.
   */
  const loadPreviewOnlyPrintJson = useCallback(async () => {
    const faces = cardState.cardFacesList;
    if (faces.length === 0) {
      return;
    }
    for (const face of faces) {
      // if the face is not loaded on the editor, the canvas won't be created for that face.
      if (!face.editorDisplayIndicator && !face.printJson) {
        face.printJson = await createCanvasJson(face);
      }
    }
  }, [cardState.cardFacesList]);

  useEffect(() => {
    loadPreviewOnlyPrintJson();
  }, [loadPreviewOnlyPrintJson]);

  return { loadCardData, canvasRefs };
};
