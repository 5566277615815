import { GetSendEmailNowOptions } from '../analytics-context-types';
import { getDGModalTag, getPODProductTag } from './utils';

export const getSendEmailNowTag = (options: GetSendEmailNowOptions) => {
  const sendEmailNowEvent = [
    {
      eventInfo: {
        eventName: 'send email',
        eventAction: options.eventAction,
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const { modal, user } = getDGModalTag('Preview', sendEmailNowEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
