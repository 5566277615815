import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useAnalyticsContext } from '../context/analytics-context';
import { useAppContext, setIsToasterOpen, setErrorMessages, setIsSystemErrorOpen } from '../context/app-context';
import { useCardContext } from '../context/card-context';
import { useInitializationDataContext } from '../context/data-context';
import {
  CardFacePreviewImage,
  ApiResponse,
  ProjectTypeCode,
  AddToCartParams,
  RegionalCodesList,
  AddressResponseData,
  Address,
} from '../global-types';
import { addProjectToCart } from '../services';
import { removeBeforeUnloadEvent } from '../utils';
import { AddToCart } from '../utils/analytics/analytics-types';
import { pushAddToCart } from '../utils/analytics/analytics-utils';
import { constructAddToCartURL } from '../utils/construct-add-to-cart-url';
import { getMonolithUrl, isAnyOfRegions } from '../utils/utility';
import { getAddToCartRequestBody, handleCloseAndKeepShopping } from '../views/preview/utils';
import { useQueryParams } from './useQueryParams';

export const useSubmitOrder = (
  updateIsPreviewDialogOpen: (isOpen: boolean) => void,
  addToCart: ({ requestBody, closeModalOverride, keepShoppingOverride, cartOptions }: AddToCartParams) => void,
) => {
  const { t } = useTranslation();
  const {
    initializedDataState: { data: initializedData, addressData, lineItemUUID },
  } = useInitializationDataContext();
  const { cardState } = useCardContext();
  const { appState, appDispatch } = useAppContext();
  const { trackAddToCartError } = useAnalyticsContext();

  const queryParams = useQueryParams();
  const recipient: AddressResponseData | Address | null = addressData?.recipient ?? null;
  const sender: AddressResponseData | Address | null = addressData?.sender ?? null;
  const { coverPreviewUrl } = cardState;
  const { isSendToMe } = appState;
  const projectId = initializedData?.project_id || '';
  const productId = initializedData?.product_id || '';
  const monolith = getMonolithUrl();
  const quantity = queryParams.get('qty') ?? '1';

  const handleSubmit = useCallback(
    async (previewImages: CardFacePreviewImage[]) => {
      if (isAnyOfRegions([RegionalCodesList.uk])) {
        removeBeforeUnloadEvent();
        const encodedThumbnailPath = encodeURIComponent(coverPreviewUrl);
        const url = `${monolith}/pages/add-to-cart?variant_id=${productId}&project_id=${projectId}&thumbnail=${encodedThumbnailPath}&send_to_myself=${isSendToMe}`;

        window.location.href = url;
        return url;
      } else if (isAnyOfRegions([RegionalCodesList.ce])) {
        removeBeforeUnloadEvent();
        const url = constructAddToCartURL({ monolith, productId, projectId, previewImages });
        window.location.href = url;
      } else {
        const requestBody = getAddToCartRequestBody({
          productId,
          coverPreviewUrl: coverPreviewUrl,
          projectId,
          address: recipient || sender || null,
          scanCode: initializedData?.scan_code || '',
          lineItemUUID,
          quantity,
        });

        const exit = () => handleCloseAndKeepShopping(initializedData?.project_type_code as ProjectTypeCode);

        if (!appState.isProjectSaved) {
          setIsSystemErrorOpen(appDispatch, true);
          return;
        }

        addProjectToCart(projectId)
          .then((response: ApiResponse<Record<string, never>>) => {
            if (response && response.meta?.code >= 400) {
              throw new Error();
            }
            addToCart({ requestBody, closeModalOverride: exit, keepShoppingOverride: exit });

            const addtoCartEventData: Omit<AddToCart, 'event_id'> = {
              event: 'add_to_cart',
              journey_type: 'pod_editor',
            };
            pushAddToCart(addtoCartEventData);
          })
          .catch((errors) => {
            setErrorMessages(appDispatch, errors.errors);
            trackAddToCartError(t('previewView.toastErrorDescription'));
            setIsToasterOpen(appDispatch, {
              title: t('previewView.toastErrorTitle'),
              children: t('previewView.toastErrorDescription'),
              variant: ToastVariants.Error,
            });
          });

        updateIsPreviewDialogOpen(false);
      }
    },
    [productId, coverPreviewUrl, projectId, recipient, appState.isProjectSaved],
  );

  return { handleSubmit };
};
