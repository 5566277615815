import { Address, AddressResponseData, EnvelopePreviewBody, RequestBody } from '../../../global-types';
import { ProjectTypeCode } from '../../../global-types/initialization-data';
import { getMonolithUrl } from '../../../utils/utility';

type Params = {
  productId: string | null;
  projectId: string;
  address: AddressResponseData | Address | null;
  scanCode: string;
  coverPreviewUrl: string;
  lineItemUUID: string | null;
  quantity: string;
};

const monolithUrl = getMonolithUrl();

/**
 *
 * @param param Project information used for the add to cart request payload
 * @returns the request body to be used in add to cart api POST request
 */
export const getAddToCartRequestBody = ({
  productId,
  projectId,
  address,
  scanCode,
  coverPreviewUrl,
  lineItemUUID,
  quantity,
}: Params): RequestBody => {
  const requestBody: RequestBody = {
    pid: productId || '',
    quantity,
    projectID: projectId || '',
    fulfillmentProductTypeCode: 'S', // Sign and Send --- probably returns an error at the moment
    shippingOptionCode: 'S', // Only S - Standard for now
    scanCode: scanCode || '',
    thumbnailImageURL: coverPreviewUrl,
  };
  if (address) {
    const addressBody = {
      firstName: address.first_name,
      lastName: address.last_name,
      companyName: address.company_name,
      addressLine1: address.address_line_1,
      addressLine2: address.address_line_2,
      city: address.city,
      stateCode: address.state_code,
      zip: address.zip,
      countryCode: 'US',
    };
    Object.assign(requestBody, addressBody);
  }
  if (lineItemUUID) {
    requestBody.lineItemUUID = lineItemUUID;
  }
  return requestBody;
};

export const handleCloseAndKeepShopping = (projectTypeCode: ProjectTypeCode) => {
  const sAndSQuery = process.env.REACT_APP_SAS_CARDS_PATH;
  const podCardsPath = process.env.REACT_APP_CARDS_PATH;
  const exitUrl = projectTypeCode !== 'S' ? `${monolithUrl}/${podCardsPath}` : `${monolithUrl}/cards${sAndSQuery}`;
  window.location.href = exitUrl;
};

/**
 * @param address Address to be included in envelope
 * @returns Object containing just the necessary address keys for the envelope preview body
 */
const getEnvelopeAddress = (address: AddressResponseData | Address) => {
  const { first_name, last_name, company_name, address_line_1, address_line_2, city, state_code, zip } = address;
  return { first_name, last_name, company_name, address_line_1, address_line_2, city, state_code, zip };
};

/**
 * Get the request body for the envelope-preview endpoint
 * @param recipient Recipient Address to be included in the envelope
 * @param sender Sender Address to be included in the envelope
 * @param color Color of the envelope
 * @param envelopeSize Size of the envelope
 * @returns Body for the envelope preview API request
 */
export const getEnvelopePreviewBody = (
  recipient: AddressResponseData | Address | null,
  sender: AddressResponseData | Address | null,
  color: string,
  envelopeSize: string,
): string => {
  const reqBody: EnvelopePreviewBody = {
    envelope_size: envelopeSize,
    color,
  };
  if (recipient) {
    reqBody.recipient_address = getEnvelopeAddress(recipient);
  }
  if (sender) {
    reqBody.sender_address = getEnvelopeAddress(sender);
  }
  return JSON.stringify(reqBody);
};

export { getOrderRequestBody } from './get-order-request-body';
