import { useInitializationDataContext } from '../context/data-context';

/**
 * Hook that returns a boolean to check if the project is a Digital Greeting
 * Conditions for DG
 * - is_digital_fulfillment should be true
 *
 * @returns true if the project is Digital Greeting
 */
export const useIsDigitalGreeting = () => {
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  return initializedData?.is_digital_fulfillment;
};
