import { GetCropAndRotateFormDataProps } from '../crop-context-types';

export const getCropAndRotateFormData = ({
  croppingArea,
  currentImage,
  totalRotation,
  xPos,
  yPos,
}: GetCropAndRotateFormDataProps) => {
  if (!croppingArea.current) {
    throw Error('Unable to crop image: Unable to create crop and rotate form data');
  }

  return {
    x_pos: xPos / (currentImage.scaleX as number),
    y_pos: yPos / (currentImage.scaleY as number),
    width: croppingArea.current.getScaledWidth() / (currentImage.scaleX as number),
    height: croppingArea.current.getScaledHeight() / (currentImage.scaleY as number),
    rotation: totalRotation,
    source_version_id: currentImage.data?.version_id ?? '',
    image_url: '',
  };
};
