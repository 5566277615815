import { fabric } from 'fabric';
import { CardContextState } from '../../../context/card-context';
import { CanvasDataTypes, getCardFaceClipPath, getGroupedItemByName, hideMiddleControls } from '../../../utils';
import { CustomFabricType } from '../../../utils/canvas';
import { removeEditableAreaButtons } from './remove-editable-area-buttons';

const MAX_IMAGE_SIZE = 400;
const SCALE_FACTOR = 2;

export const addWamImageToCanvas = async (
  imageUrl: string,
  isPodProductCode: boolean,
  cardState: CardContextState,
  canvas: React.RefObject<fabric.Canvas> | undefined,
  imageId: string,
  cleanUpVersionId: string,
  onImageSelect: () => void,
  onCrop: () => void,
) => {
  let hasError = true;
  const cardFaceClipPath = getCardFaceClipPath(cardState.cardFacesList[cardState.activeCardIndex], 0);
  const activeZone = canvas?.current?.getActiveObject() as fabric.Group;

  if (!canvas?.current) {
    return hasError;
  }

  const zoomLevel = canvas.current.getZoom();
  const centerX = canvas.current.getWidth() / SCALE_FACTOR / zoomLevel;
  const centerY = canvas.current.getHeight() / SCALE_FACTOR / zoomLevel;

  let leftPosition = centerX - MAX_IMAGE_SIZE / SCALE_FACTOR;
  let topPosition = centerY - MAX_IMAGE_SIZE / SCALE_FACTOR;

  if (cardState.cardFormat === 'portrait') {
    const leftCalculation = centerX + centerX / SCALE_FACTOR - MAX_IMAGE_SIZE / SCALE_FACTOR;

    if (cardState.activeCardIndex === 1) {
      leftPosition = leftCalculation;
    }
  } else if (cardState.cardFormat === 'landscape') {
    topPosition = centerY + centerY / SCALE_FACTOR - MAX_IMAGE_SIZE / SCALE_FACTOR;
  }

  fabric.Image.fromURL(
    imageUrl,
    (img) => {
      let left, top, width, height;
      if (isPodProductCode && cardFaceClipPath) {
        // We are adding a handwriting image to a POD card, so there is no pre-existing activeZone like in S&S
        // This card has userZones, so position WAM at top left of editable area
        width = MAX_IMAGE_SIZE;
        height = MAX_IMAGE_SIZE;
        left = cardFaceClipPath.left + cardFaceClipPath.width / 2 - width / 2;
        top = cardFaceClipPath.top + cardFaceClipPath.height / 2 - height / 2;
      } else if (!activeZone) {
        // If no activeZone or clip path, place on top left of card face
        left = leftPosition;
        top = topPosition;
        width = MAX_IMAGE_SIZE;
        height = MAX_IMAGE_SIZE;
      } else {
        // For S&S, place WAM into its pre-defined zone
        ({ left, top, width, height } = activeZone);
      }
      // TODO: When implementing, we need to set the right properties to position and size image as expected
      img.scaleToWidth(width as number);
      if (img.getScaledHeight() > (height as number)) {
        img.scaleToHeight(height as number);
      }

      const clipPathSettings = {
        left,
        top,
        originX: 'center',
        originY: 'center',
        width,
        height,
        absolutePositioned: true,
        evented: false,
      };
      const oImg = img.set({
        // For S&S, fit WAM into zone. For POD, no need to fit into zone
        left: isPodProductCode ? left : (left as number) - img.getScaledWidth() / 2,
        top: isPodProductCode ? top : (top as number) - (height as number) / 2,
        originX: 'left',
        originY: 'top',
        name: imageId,
        clipPath: cardFaceClipPath,
        data: {
          type: CanvasDataTypes.UserImage,
          version_id: cleanUpVersionId,
          // clipPathSettings only apply to S&S WAM
          clipPathSettings: activeZone ? clipPathSettings : null,
          zoneName: activeZone?.name || 'pod-handwriting-zone',
          isPodHandwriting: isPodProductCode,
          // identify if is a WAM image for S&S cards
          isPhotoTextZoneImg: !isPodProductCode,
          customType: CustomFabricType.Handwriting,
        },
      });

      oImg.onSelect = () => {
        onImageSelect();
        return false;
      };

      hideMiddleControls(oImg);
      if (canvas?.current) {
        removeEditableAreaButtons(canvas.current);
      }
      canvas?.current?.add(oImg);
      if (activeZone) {
        // For S&S cards
        const zoneTextbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, activeZone);
        zoneTextbox?.setOptions({ visible: false });
        const zoneButton = getGroupedItemByName(CanvasDataTypes.PhotoTextZoneButton, activeZone);
        zoneButton?.setOptions({ visible: false });
        activeZone.setOptions({ selectable: false });
        activeZone.data.hasContent = true;
      }

      canvas?.current?.setActiveObject(oImg);
      onImageSelect();
      if (activeZone) {
        // For S&S cards
        onCrop();
      }
      hasError = false;
    },
    { crossOrigin: 'anonymous' },
  );
  return hasError;
};
