import { useEffect } from 'react';
import { History, Location, Action } from 'history';

/**
 *  Adds a history listener that blocks transitions when the component is
 *
 * @param history History object returned by the useHistory hook
 * @param callback Function to be triggered whenever a history transition is detected.
 * If returns true - Transition will be executed
 * If returns false - Transition will be blocked
 */
export const useHistoryBlock = (history: History, callback: (location: Location, action: Action) => false | void) => {
  useEffect(() => {
    const unblock = history.block((location: Location, action: Action) => {
      return callback(location, action);
    });

    return () => unblock();
  }, [history, callback]);
};
