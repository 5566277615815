import API from '@ce-digital/api-middleware';
import { savedAddressResponse } from '../data';
import { quickAddressResponse, quickAddressesResponse, getAddressesResponse } from '../data/address-response';
import {
  SaveAddressResponse,
  Address,
  QuickAddressResponse,
  QuickAddressSubmitData,
  QuickAddressesResponse,
  GetAddresses,
  UpdateAddress,
} from '../global-types/addresses';
import { ApiResponse } from '../global-types/services';
import { getCookie } from '../utils';
import { processResponse } from './middleware';

const PODService = new API();
const accountId = getCookie('accountId');
const mockResponse = process.env.REACT_APP_MOCK_INITIALIZATION_SERVICE === 'true';

export const getAddresses = (projectId: string): Promise<GetAddresses> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(getAddressesResponse);
    }
    const response = PODService.get('/customization', `/${accountId}/${projectId}/addresses`);
    processResponse(response, resolve, reject);
  });
};

export const saveAddress = (projectId: string, address: Address): Promise<SaveAddressResponse> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(savedAddressResponse);
    }
    const response = PODService.post('/customization', `/${accountId}/${projectId}/addresses`, address);
    processResponse(response, resolve, reject);
  });
};

export const updateAddress = (
  projectId: string,
  addressId: string,
  address: Omit<Address, 'contact_id' | 'address_type_code'>,
): Promise<UpdateAddress> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(savedAddressResponse);
    }
    const response = PODService.put('/customization', `/${accountId}/${projectId}/addresses/${addressId}`, address);
    processResponse(response, resolve, reject);
  });
};

/**
 * Disassociates an address from a project.  For instance, when a user swaps from wanting
 * a return address envelope to a blank envelope.
 *
 * @param projectId - ProjectID associated with the card
 * @param addressId - AddressID associated with the address
 * @returns void
 */
export const deleteAddress = (projectId: string, addressId: string): Promise<ApiResponse<null> | null> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(null);
    }
    const response = PODService.delete('/customization', `/${accountId}/${projectId}/addresses/${addressId}`);
    processResponse(response, resolve, reject);
  });
};

export const saveQuickAddress = (address: QuickAddressSubmitData): Promise<QuickAddressResponse> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(quickAddressResponse);
    }
    const response = PODService.post('/address-book/', accountId, address);
    processResponse(response, resolve, reject);
  });
};

export const deleteQuickAddress = (contactId: string): Promise<ApiResponse<null> | null> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(null);
    }
    const response = PODService.delete('/address-book', `/${accountId}/${contactId}`);
    processResponse(response, resolve, reject);
  });
};

export const getQuickAddresses = (): Promise<QuickAddressesResponse> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(quickAddressesResponse);
    }
    const path = `${accountId}?p[page]=[{"eq": "1"}]&p[limit]=[{"eq": "10"}]&q[sort_by]=[{"field":"created_at","order":"asc"}]`;
    const response = PODService.get('/address-book/', `${path}`);
    processResponse(response, resolve, reject);
  });
};
