import { CardType } from '../../global-types';
import { PreviewAssetType } from '../../global-types/images';

/**
 * Gets appropriate asset type (F, I, B) to pass to assets endpoint
 *
 * @param index Index of asset in assets array (0 being the front)
 * @returns String equal to 'F', 'I', or 'B' for front, inside, and back
 */
export const getAssetType = (cardType: CardType, index, isOneToMany: boolean): PreviewAssetType => {
  if (cardType === CardType.SAS) {
    // The only S&S preview we store is inside right, which we store as "I"
    return PreviewAssetType.I;
  } else if (isOneToMany) {
    // For 1-to-many cards, we store front as "F" and back as "B"
    return index === 0 ? PreviewAssetType.F : PreviewAssetType.B;
  }
  // For regular POD, front is "F" and inside is "I"
  return index === 0 ? PreviewAssetType.F : PreviewAssetType.I;
};
