import React, { useEffect } from 'react';

/*
 * The Intersection Observer API is technique that can detect when elements appear,
 * thus triggering content loading for infinite scrolling.
 */

export const useIntersectionObserver = (elementRef: React.RefObject<HTMLElement>, func: () => void): void => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        func();
      }
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [func]);
};
