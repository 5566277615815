import { fabric } from 'fabric';
import { CardFaceData, CardType } from '../global-types';
import {
  CanvasObjectsState,
  CardFaceEditableAreasObject,
  CardFaceObjects,
  PODCanvasObjects,
  SASCanvasObjects,
} from '../global-types/save-project';
import {
  CanvasDataTypes,
  getFoldLines,
  getGroupedTextObject,
  getObjectsByType,
  getPhotoTextZoneImages,
  getPhotoTextZoneObjects,
} from './canvas';

/**
 * For POD/DG cards: make a copy of photozones' buttons to be restored after saving the project
 *
 * @param {'S'|'P'} projectTypeCode S|P - identify if the current card is S&S or POD/DG
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 *
 * @returns object containing a copy of canvas objects. May be used to restore the canvas state after saving personalization.
 */
export const copyCanvasObjects = (projectTypeCode: 'S' | 'P' | 'D', cardFaces: CardFaceData[]): CanvasObjectsState => {
  const canvasObjectsCopy: CanvasObjectsState = {
    projectType: projectTypeCode,
    canvasObjects: {},
  };

  // Copy objects for S&S Cards
  if (projectTypeCode === CardType.SAS) {
    canvasObjectsCopy.canvasObjects = {
      phototextzones: copyPhotoTextZones(cardFaces),
      phototextzoneImages: copyPhotoTextZoneImages(cardFaces),
    };
  } else {
    // Copy objects for POD/DG cards
    canvasObjectsCopy.canvasObjects = {
      photozoneButtons: copyPhotozoneButtons(cardFaces),
      editableAreas: copyEditableAreas(cardFaces),
      foldLines: copyFoldLines(cardFaces),
      placeholders: copyPlaceholderTexts(cardFaces),
    };
  }

  return canvasObjectsCopy;
};

/**
 * Restore the canvas objects that were removed or modified to clean the Canvas JSON
 * when making a call to /save-personalization API. The objects are restored to ensure
 * that the user can interact with the card in a normal way.
 *
 * @param {CanvasObjectsState} canvasObjectsCopy copy of original canvas objects
 */
export const restoreCanvasObjects = (cardFaces: CardFaceData[], canvasObjectsCopy: CanvasObjectsState) => {
  const { projectType, canvasObjects } = canvasObjectsCopy;
  if (projectType && canvasObjects) {
    if (projectType === CardType.SAS) {
      // Restore canvas objects for S&S cards
      const sasObjects = canvasObjects as SASCanvasObjects;

      restoreSASCanvasObjects(
        cardFaces,
        sasObjects.phototextzones as CardFaceObjects,
        sasObjects.phototextzoneImages as CardFaceObjects,
      );
    } else {
      // Restore canvas objects for POD/DG cards
      const podObjects = canvasObjects as PODCanvasObjects;

      restorePhotozoneButtons(cardFaces, podObjects.photozoneButtons as CardFaceObjects);
      restoreEditableAreas(cardFaces, podObjects.editableAreas as CardFaceEditableAreasObject);
      restorePlaceholders(cardFaces, podObjects.placeholders as CardFaceObjects);
      restoreFoldLines(cardFaces, podObjects.foldLines as CardFaceObjects);
      bringFrontEditableTexts(cardFaces);
    }
  }
};

// FUNCTIONS FOR POD/DG CARDS

/**
 * For POD/DG cards: make a copy of photozones' buttons to be restored after saving the project
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 *
 * @returns copy of photozone buttons mapped to each cardFace
 */
const copyPhotozoneButtons = (cardFaces: CardFaceData[]): CardFaceObjects => {
  const photoZoneButtons: CardFaceObjects = {};

  cardFaces.forEach((cardFace) => {
    const currentCanvas = cardFace.canvas?.current as fabric.Canvas;
    if (currentCanvas) {
      const faceButtons = getObjectsByType(currentCanvas, CanvasDataTypes.PhotoZoneButton).filter(
        (btn) => btn.name?.indexOf('user-zone') === -1,
      );
      if (faceButtons.length > 0) {
        photoZoneButtons[`${cardFace.faceId}`] = faceButtons;
      }
    }
  });

  return photoZoneButtons;
};

/**
 * For POD/DG cards: make a copy of empty text placeholders to be restored after saving the project
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 *
 * @returns copy of photozone buttons mapped to each cardFace
 */
const copyPlaceholderTexts = (cardFaces: CardFaceData[]): CardFaceObjects => {
  const placeholders: CardFaceObjects = {};

  cardFaces.forEach((cardFace) => {
    const currentCanvas = cardFace.canvas?.current as fabric.Canvas;
    if (currentCanvas) {
      const facePlaceholders = (
        getObjectsByType(currentCanvas, CanvasDataTypes.Placeholder) as fabric.Textbox[]
      ).filter((placeholder) => !placeholder.data?.edited);
      if (facePlaceholders.length > 0) {
        placeholders[`${cardFace.faceId}`] = facePlaceholders;
      }
    }
  });

  return placeholders;
};

/**
 * For POD/DG cards: make a copy of editable areas (user zones) with no images or text on them
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 *
 * @returns copy of editable areas mapped to each cardFace
 */
const copyEditableAreas = (cardFaces: CardFaceData[]): CardFaceEditableAreasObject => {
  const editableAreas: CardFaceEditableAreasObject = {};

  cardFaces.forEach((cardFace) => {
    const currentCanvas = cardFace.canvas?.current as fabric.Canvas;
    if (currentCanvas) {
      const faceAreas = getObjectsByType(currentCanvas, CanvasDataTypes.EditableArea);
      if (faceAreas.length > 0) {
        editableAreas[`${cardFace.faceId}`] = {
          areas: faceAreas,
          buttons: copyEditableAreaButtons(cardFace),
        };
      }
    }
  });

  return editableAreas;
};

/**
 * For POD/DG cards: make a copy of editable area buttons (text, photo, WAM)
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 *
 * @returns copy of editable area buttons mapped to each cardFace
 */
const copyEditableAreaButtons = (cardFace: CardFaceData): fabric.Object[] => {
  let editableAreaButtons: fabric.Object[] = [];

  const currentCanvas = cardFace.canvas?.current as fabric.Canvas;
  if (currentCanvas) {
    editableAreaButtons = currentCanvas.getObjects().filter((obj) => obj.name?.includes('user-zone-add'));
  }

  return editableAreaButtons;
};

/**
 * For POD/DG cards: after sanitizing the canvas and making the save-personalization API call,
 * restore all photoTextZones to their previous state so they work normally.
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 * @param zones copy of original phototextzones previous to save-project call
 * @param buttons uploaded WAM images associated to phototextzones
 */
const restorePhotozoneButtons = (cardFaces: CardFaceData[], buttons: CardFaceObjects) => {
  cardFaces.forEach((cardFace) => {
    const faceButtons = buttons[`${cardFace.faceId}`];

    if (faceButtons?.length > 0) {
      faceButtons.forEach((btn) => {
        cardFace.canvas?.current?.add(btn);
      });
      cardFace.canvas?.current?.requestRenderAll();
    }
  });
};

/**
 * For POD/DG cards: after sanitizing the canvas and making the save-personalization API call,
 * restore all user text placeholders that weren't edited
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 * @param zones copy of original phototextzones previous to save-project call
 * @param buttons uploaded WAM images associated to phototextzones
 */
const restorePlaceholders = (cardFaces: CardFaceData[], placeholders: CardFaceObjects) => {
  cardFaces.forEach((cardFace) => {
    const facePlaceholders = placeholders[`${cardFace.faceId}`];

    if (facePlaceholders?.length > 0) {
      facePlaceholders.forEach((placeholder) => {
        cardFace.canvas?.current?.add(placeholder);
      });
      cardFace.canvas?.current?.requestRenderAll();
    }
  });
};

/**
 * For POD/DG cards: after sanitizing the canvas and making the save-personalization API call,
 * restore all user text placeholders that weren't edited
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 * @param foldLines copy of original fold lines previous to save-project call
 */

const restoreFoldLines = (cardFaces: CardFaceData[], foldLines: CardFaceObjects) => {
  cardFaces.forEach((cardFace) => {
    const faceFoldLines = foldLines[`${cardFace.faceId}`];

    if (faceFoldLines?.length > 0) {
      faceFoldLines.forEach((foldLine) => {
        cardFace.canvas?.current?.add(foldLine);
      });
      cardFace.canvas?.current?.requestRenderAll();
    }
  });
};

/**
 * For POD/DG cards: after sanitizing the canvas and making the save-personalization API call,
 * restore editable areas (user zones) that have no text or images on them, so users can still see the outline
 * and color signalizing the area.
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 * @param editableAreas copy of original editableAreas previous to save-project call
 */
const restoreEditableAreas = (cardFaces: CardFaceData[], editableAreas: CardFaceEditableAreasObject) => {
  cardFaces.forEach((cardFace) => {
    const faceAreas = editableAreas[`${cardFace.faceId}`];

    // Restore editable areas (user zones)
    if (faceAreas?.areas.length > 0) {
      faceAreas.areas.forEach((area) => {
        cardFace.canvas?.current?.add(area);
      });
      cardFace.canvas?.current?.requestRenderAll();
    }
    // Restore editable area buttons
    if (faceAreas?.buttons.length > 0) {
      faceAreas.buttons.forEach((btn) => {
        cardFace.canvas?.current?.add(btn);
      });
      cardFace.canvas?.current?.requestRenderAll();
    }
  });
};

// FUNCTIONS FOR S&S CARDS

/**
 * For S&S cards: make a copy of photoTextZones to be restored after saving the project
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 *
 * @returns copy of S&S photoTextZones mapped to card faces
 */
const copyPhotoTextZones = (cardFaces: CardFaceData[]): CardFaceObjects => {
  const phototextzones: CardFaceObjects = {};

  cardFaces.forEach((cardFace) => {
    const currentCanvas = cardFace.canvas?.current as fabric.Canvas;
    if (currentCanvas) {
      const faceZones = getPhotoTextZoneObjects(currentCanvas);
      if (faceZones.length > 0) {
        phototextzones[`${cardFace.faceId}`] = faceZones;
      }
    }
  });

  return phototextzones;
};

/**
 * For S&S cards: copy WAM images associated to a phototextzone to be restored after saving the project
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 *
 * @returns copy of S&S WAM images mapped to card faces
 */
const copyPhotoTextZoneImages = (cardFaces: CardFaceData[]): CardFaceObjects => {
  const phototextzoneImages: CardFaceObjects = {};

  cardFaces.forEach((cardFace) => {
    const currentCanvas = cardFace.canvas?.current as fabric.Canvas;
    if (currentCanvas) {
      const faceImages = getPhotoTextZoneImages(currentCanvas);
      if (faceImages.length > 0) {
        phototextzoneImages[`${cardFace.faceId}`] = faceImages;
      }
    }
  });

  return phototextzoneImages;
};

/**
 *
 * @param {CardFaceData[]} cardFaces array containing all fold lines of a card
 *
 * @returns copy of fold lines mapped to card faces
 */
const copyFoldLines = (cardFaces: CardFaceData[]): CardFaceObjects => {
  const foldLines: CardFaceObjects = {};

  cardFaces.forEach((cardFace) => {
    const currentCanvas = cardFace.canvas?.current as fabric.Canvas;
    if (currentCanvas) {
      const faceFoldLines = getFoldLines(currentCanvas);
      if (faceFoldLines.length > 0) {
        foldLines[`${cardFace.faceId}`] = faceFoldLines;
      }
    }
  });

  return foldLines;
};

/**
 * For S&S cards: after sanitizing the canvas and making the save-personalization API call,
 * restore all photoTextZones to their previous state so they work normally.
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 * @param phototextzones copy of original phototextzones previous to save-project call
 * @param zoneImages uploaded WAM images associated to phototextzones
 */
const restoreSASCanvasObjects = (
  cardFaces: CardFaceData[],
  phototextzones: CardFaceObjects,
  zoneImages: CardFaceObjects,
) => {
  cardFaces.forEach((cardFace) => {
    // Clean canvas by removing all ungrouped/regrouped objects that were modified for /save call
    const canvasObjects = cardFace.canvas?.current?.getObjects() as fabric.Object[];
    cardFace.canvas?.current?.remove(...canvasObjects);
    cardFace.canvas.current?.renderAll();

    // Restore copy of unmodified photoTextZones
    const faceZones = phototextzones[`${cardFace.faceId}`];
    if (faceZones?.length > 0) {
      faceZones.forEach((zone) => {
        cardFace.canvas?.current?.add(zone);
        // Restore original position of a phototextzone's textbox object
        const zoneTextbox = getGroupedTextObject(cardFace.canvas?.current, zone as fabric.Group);
        if (zoneTextbox && zoneTextbox.data?.originalData) {
          zoneTextbox.set({
            top: zoneTextbox.data.originalData.top,
            left: zoneTextbox.data.originalData.left,
            width: zoneTextbox.data.originalData.width,
            height: zoneTextbox.data.originalData.height,
            originY: zoneTextbox.data.originalData.originY,
            originX: zoneTextbox.data.originalData.originX,
          });
        }
      });
      cardFace.canvas.current?.requestRenderAll();
    }

    // Restore phototextzones' WAM images
    const faceImages = zoneImages[`${cardFace.faceId}`];
    if (faceImages?.length > 0) {
      faceImages.forEach((img) => {
        cardFace.canvas?.current?.add(img);
      });
      cardFace.canvas.current?.requestRenderAll();
    }
  });
};

/**
 * For POD/DG cards: after sanitizing the canvas and making the save-personalization API call,
 * bring front editable texts to their previous state so they work normally.
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 */
const bringFrontEditableTexts = (cardFaces: CardFaceData[]) => {
  cardFaces.forEach((cardFace) => {
    const currentCanvas = cardFace.canvas?.current as fabric.Canvas;

    if (!currentCanvas) return;

    const editableTexts = getObjectsByType(currentCanvas, CanvasDataTypes.EditableText);
    const userTextZones = getObjectsByType(currentCanvas, CanvasDataTypes.UserText);
    const userImageZones = getObjectsByType(currentCanvas, CanvasDataTypes.UserImage);
    const stickerImageZones = getObjectsByType(currentCanvas, CanvasDataTypes.StickerImage);
    const textZoneButtons = getObjectsByType(currentCanvas, CanvasDataTypes.EditableTextButton);

    editableTexts.forEach((editableText) => {
      editableText.bringToFront();
    });

    userTextZones.forEach((userTextZone) => {
      userTextZone.bringToFront();
    });

    userImageZones.forEach((userImageZone) => {
      userImageZone.bringToFront();
    });

    stickerImageZones.forEach((userImageZone) => {
      userImageZone.bringToFront();
    });

    textZoneButtons.forEach((textZoneButton) => {
      textZoneButton.bringToFront();
    });
  });
};
