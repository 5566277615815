import { DefaultProductOptions } from '../analytics-context-types';
import { getPODModalTag, getPODProductTag } from './utils';

export const getAddToCartTag = (options: DefaultProductOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const addToCartModalEvent = [
    {
      eventInfo: {
        eventName: 'Flow End',
        eventAction: 'Print on Demand',
      },
    },
    {
      eventInfo: {
        eventName: 'Add to Cart',
        eventAction: 'Print on demand',
      },
    },
    {
      eventInfo: {
        eventName: 'Ship location',
        eventAction: 'address',
      },
    },
  ];

  const { modal, user } = getPODModalTag('Print on Demand - Add to Cart', addToCartModalEvent, options.projectID);

  modal.pageType = 'Checkout';

  return {
    modal,
    product,
    user,
  };
};
