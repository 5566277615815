import { useCardContext } from '../../../context/card-context';
import { setBtlyUrl, setImages } from '../../../context/card-context/card-context-actions';
import { useInitializationDataContext } from '../../../context/data-context';
import {
  ApiResponse,
  CardFacePreviewImage,
  CardType,
  ErrorResponse,
  RecipientResponseData,
} from '../../../global-types';
import { useSystemErrorHandling } from '../../../hooks';
import { getPreview, saveRecipientAssets } from '../../../services';
import { TransformedPersonalizationData } from '../../../utils/helper-settings';

export const usePreviewGenerator = () => {
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { cardState, cardDispatch } = useCardContext();
  const [onSystemError] = useSystemErrorHandling();
  const { cardFacesList } = cardState;

  const updateImages = (newImages: CardFacePreviewImage[]) => setImages(cardDispatch, newImages);
  const updateBtlyUrl = (newUrl: string) => setBtlyUrl(cardDispatch, newUrl);

  const projectId = initializedData?.project_id ?? '';

  const projectTypeCode = sessionStorage.getItem('lastProjectTypeCode');
  const isDigitalGreetings = projectTypeCode === CardType.DG;

  const fetchDigitalAssets = async (frontUrl: string, insideUrl: string) => {
    if (!isDigitalGreetings) {
      return;
    }

    try {
      const assetFormData = {
        front_url: frontUrl,
        inside_url: insideUrl,
      };
      const response: ApiResponse<RecipientResponseData> = await saveRecipientAssets(projectId, assetFormData);

      if (response.data) {
        updateBtlyUrl(response.data?.url ?? '');
        return response.data;
      }
    } catch (errors: any) {
      onSystemError(errors as ErrorResponse);
      throw errors;
    }
  };

  const loadPreview = async (saveData: TransformedPersonalizationData[]) => {
    if (!saveData || !projectId) {
      return Promise.reject('Necessary data is missing.');
    }

    // showLoadingScreen(appDispatch, '');

    const previewPromises = saveData.map(async (cardFace, index) => {
      const showPreview = cardFacesList[+index]?.previewDisplayIndicator ?? false;
      if (!showPreview) {
        return null;
      }

      const { version, objects = [], backgroundImage } = cardFace.PrintJson ?? {};
      const { dimensions, type: cardType } = cardFacesList[+index];

      return getPreview(projectId, {
        save_preview_image: true,
        asset_type_code: 'P',
        personalization_data_json: { version, objects, backgroundImage },
      }).then((response) => ({
        photoZoneTemplate: response.data?.encoded_image as string,
        type: cardType.replace('-', ' '),
        dimensions: dimensions,
        url: response.data?.image_url || '',
        faceNumber: cardFace.FaceNumber,
      }));
    });

    return Promise.all(previewPromises.filter(Boolean))
      .then((previewImages) => {
        const validPreviews: CardFacePreviewImage[] = previewImages.filter(Boolean) as CardFacePreviewImage[];

        updateImages(validPreviews);
        return validPreviews;
      })
      .catch((error) => {
        onSystemError(error);
        throw error;
      });
  };

  return { loadPreview, fetchDigitalAssets };
};
