import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { removeEditableAreas } from '../components/card-editor/utils/remove-editable-areas';
import {
  setIsSavingSaveButton,
  setIsSystemErrorOpen,
  setIsToasterOpen,
  setPersonalizationData,
  useAppContext,
} from '../context/app-context';
import { CardContextState, useCardContext } from '../context/card-context';
import { updateProjectName, useInitializationDataContext } from '../context/data-context';
import { CardFaceData, ErrorResponse } from '../global-types';
import { savePersonalization } from '../services';
import { updateDraftName } from '../services/customization';
import { getTransformedPersonalizationData, validateImages } from '../utils';
import { PodCardPreview } from '../utils/analytics/analytics-types';
import { pushPodCardPreview } from '../utils/analytics/analytics-utils';
import { CustomFabricType } from '../utils/canvas';
import { useGeneratePreviews } from '../utils/previews/generate-preview-images';
import { copyCanvasObjects, restoreCanvasObjects } from '../utils/save-project';
import { didBackgroundImagesPopulate } from '../utils/utility';
import { setEnvelopeSearchParam } from '../views/editor/utils/search-param-utils';
import { useActiveCanvas } from './useActiveCanvas';
import { useFeatureFlags } from './useFeatureFlags';
import { useIsDigitalGreeting } from './useIsDigitalGreeting';
import { useIsOneToMany } from './useIsOneToMany';
import { useQueryParams } from './useQueryParams';
import { useSystemErrorHandling } from './useSystemErrorHandling';

type SaveProjectOptions = {
  shouldRestoreCanvas: boolean;
  isSaveButtonClicked?: boolean;
  generatePreviews?: boolean;
};
/**
 * Custom hook used to save a project's draft name and personalization.
 * After the project is saved, the card canvas gets restored to its original state.
 * @returns callbacks to save the current project and update draft name
 */
export const useSaveProject = () => {
  const { t } = useTranslation();
  const {
    initializedDataState: { data: initializedData },
    initializationDataDispatch,
  } = useInitializationDataContext();
  const { cardState } = useCardContext();
  const { generatePreviewImages } = useGeneratePreviews();
  const isDigitalGreeting = useIsDigitalGreeting();
  const {
    appState: { productQuantity },
    appDispatch,
  } = useAppContext();
  const [onSystemError] = useSystemErrorHandling();
  const canvas = useActiveCanvas();
  const isOneToMany = useIsOneToMany();
  const { search, pathname } = useLocation();
  const { SAVED_PROJECTS, GENERATE_FE_PREVIEWS = true } = useFeatureFlags();
  const queryParams = useQueryParams();
  const history = useHistory();
  const missingBackgroundImageError = t('editorView.missingBackgroundImageError');
  const searchParams = new URLSearchParams(search);

  const initialCanvasJsonRef = useRef<string | null>(null);
  const currentCanvasJson = JSON.stringify(canvas?.current?.toJSON().objects ?? {});

  useEffect(() => {
    if (canvas?.current) {
      initialCanvasJsonRef.current = JSON.stringify(canvas.current.toJSON().objects);
    }
  }, [canvas]);

  const saveProject = useCallback(
    async (
      options: SaveProjectOptions = {
        shouldRestoreCanvas: false,
        isSaveButtonClicked: false,
        generatePreviews: false,
      },
    ) => {
      setIsSavingSaveButton(appDispatch, true);

      if (canvas?.current && canvas.current.getActiveObject()) {
        canvas.current.discardActiveObject();
        canvas.current.requestRenderAll();
      }

      if (options.isSaveButtonClicked && !searchParams.has('saveProjectDialog')) {
        queryParams.append('saveProjectDialog', 'true');
        history.replace({ pathname, search: queryParams.toString() });
      }

      const projectTypeCode = initializedData?.project_type_code;
      const projectId = initializedData?.project_id;

      if (!projectTypeCode || !projectId || !SAVED_PROJECTS) {
        return Promise.reject('Missing necessary project data.');
      }

      if (options.isSaveButtonClicked && localStorage.getItem('hasSavedProject') !== 'true') {
        localStorage.setItem('hasSavedProject', 'true');
      }

      const cardStateCopy = { ...cardState };

      canvas?.current?.discardActiveObject();
      const canvasObjectsCopy = copyCanvasObjects(projectTypeCode, cardStateCopy.cardFacesList as CardFaceData[]);

      removeEditableAreas(cardStateCopy.cardFacesList as CardFaceData[]);

      const saveData = getTransformedPersonalizationData(cardStateCopy as CardContextState, projectTypeCode);

      if (options.shouldRestoreCanvas) {
        restoreCanvasObjects(cardStateCopy.cardFacesList as CardFaceData[], canvasObjectsCopy);
      }

      const faces = initializedData?.variables?.template_data?.Faces || [];
      validateImages(faces, onSystemError, missingBackgroundImageError);
      if (!didBackgroundImagesPopulate(saveData)) {
        setIsSystemErrorOpen(appDispatch, true);
        return;
      }
      try {
        if (
          !isDigitalGreeting &&
          GENERATE_FE_PREVIEWS &&
          options.generatePreviews &&
          initialCanvasJsonRef.current !== currentCanvasJson
        ) {
          await generatePreviewImages(saveData);
        }

        await Promise.all([
          savePersonalization(saveData, projectId),
          saveDraftName(initializedData.name || 'Draft', true),
        ])
          .then(() => {
            if (!options.generatePreviews) {
              setIsSavingSaveButton(appDispatch, false);
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error while saving project and draft name:', error);
          });
        setPersonalizationData(appDispatch, saveData);

        if (canvas?.current) {
          const textAdded = canvas.current.getObjects().some((obj) => obj.type === 'textbox');
          const photoAdded = canvas.current
            .getObjects()
            .some((obj) => obj.type === 'image' && obj.data?.customType === CustomFabricType.Image);
          const stickerAdded = canvas.current
            .getObjects()
            .some((obj) => obj.type === 'image' && obj.data?.customType === CustomFabricType.Sticker);
          const handwritingAdded = canvas.current
            .getObjects()
            .some((obj) => obj.type === 'image' && obj.data?.customType === CustomFabricType.Handwriting);

          if (textAdded || photoAdded || stickerAdded || handwritingAdded) {
            const podCardPreviewData: Omit<PodCardPreview, 'event_id'> = {
              event: 'pod_card_preview',
              text_added: textAdded,
              photo_added: photoAdded,
              sticker_added: stickerAdded,
              handwriting_added: handwritingAdded,
            };
            pushPodCardPreview(podCardPreviewData);
          }
        }

        setEnvelopeSearchParam(searchParams, search, isOneToMany);
        initialCanvasJsonRef.current === currentCanvasJson && setIsSavingSaveButton(appDispatch, false);

        return saveData;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to save project:', error);
        setIsToasterOpen(appDispatch, {
          title: t('saveProject.title'),
          children: t('saveProject.description'),
          variant: ToastVariants.Error,
        });
        onSystemError(error as ErrorResponse);

        throw error;
      }
    },
    [
      initializedData?.project_type_code,
      initializedData?.project_id,
      initializedData?.variables?.template_data?.Faces,
      cardState,
      onSystemError,
    ],
  );

  /**
   * Update a project's name
   * @param draftName the new name for the project
   * @param isDraft
   */
  const saveDraftName = useCallback(
    (draftName: string, isDraft: boolean) => {
      const projectId = initializedData?.project_id;
      if (!projectId) {
        // eslint-disable-next-line no-console
        console.log('No project ID found for saving draft name.');
        return;
      }

      return updateDraftName(draftName, projectId, productQuantity ?? '1', isDraft)
        .then(() => updateProjectName(initializationDataDispatch, draftName))
        .catch((error) => onSystemError(error));
    },
    [initializedData?.project_id, onSystemError],
  );

  return { saveProject, saveDraftName };
};
