import { GetUploadPhotoErrorOptions } from '../analytics-context-types';
import { getModalTag, getPODProductTag } from './utils';

export const getUploadPhotoErrorTag = (options: GetUploadPhotoErrorOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const uploadPhotoErrorModalEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
    {
      eventInfo: {
        eventName: 'photo',
        eventAction: 'upload-error',
      },
    },
  ];

  const { modal, user } = getModalTag(options.productType, 'Edit Mode', uploadPhotoErrorModalEvent, options.projectID);

  const newModal = {
    ...modal,
    messages: [{ statusMessages: options.errorMessage }],
  };

  return {
    product,
    modal: newModal,
    user,
  };
};
