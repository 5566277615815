import {
  DefaultProductOptions,
  GetAddNewColorOptions,
  GetAddToCartErrorOptions,
  GetDeleteElementOptions,
  GetImageFormatsOptions,
  GetLeaveProjectSummaryOptions,
  GetObjectSelectionOptions,
  GetPreviewModeOptions,
  GetRetryHandlerTriggeredOptions,
  GetTextFormatsOptions,
  GetUneditedZoneErrorOptions,
  GetUploadPhotoErrorOptions,
  GetUploadPhotoOptions,
  TagOptions,
  GetChangeQuantityFromProjectSummaryOptions,
  GetPageNavigation,
  GetViewProjectSummaryOptions,
  GetPhotoUploadMethodOptions,
  GetEnvelopePrintChoiceOptions,
  GetDGPlayAnimationOptions,
  GetSendEmailNowOptions,
  GetEmailDeliveryOptions,
  GetStartFlowOptions,
  getEditProjectNameOptions,
  GetSaveLinkOptions,
  GetSignInOptions,
  GetExitSaveProjectOptions,
  GetWamPhotoUploadTag,
} from './analytics-context-types';
import {
  getAddNewColorTag,
  getAddToCartTag,
  getClickAddressRecipientTag,
  getClickNextAddressSenderTag,
  getLoadPreview,
  getClickPreviewModeTag,
  getDeleteElementTag,
  getInitializeEditTag,
  getObjectSelectionTag,
  getUploadPhotoTag,
  getRetryHandlerTriggeredTag,
  getReturnToEditTag,
  getStartFlowTag,
  getTextFormatsTag,
  getUploadPhotoErrorTag,
  getImageFormats,
  getExitFlowTag,
  getChangeQuantityFromProjectSummaryTag,
  getPageNavigationTag,
  getViewProjectSummaryTag,
  getPhotoUploadMethodTag,
  getEnvelopePrintChoiceTag,
  getDGPlayAnimationTag,
  getEmailDeliveryRecipientTag,
  getEditProjectNameTag,
  getDefaultProjectNameTag,
  getSaveLinkTag,
  getExitSaveProjectTag,
} from './tags';
import { getAddToCartErrorTag } from './tags/add-to-cart-error-tag';
import { getDGViewFacePreview } from './tags/dg-view-face-preview';
import { getLeaveProjectSummaryTag } from './tags/leave-project-summary-tag';
import { getSendEmailNowTag } from './tags/send-email-now-tag';
import { getSignIn } from './tags/sign-in-tag';
import { getSuccesfullySentEmailTag } from './tags/succesfully-sent-email-tag';
import { getUneditedZoneErrorTag } from './tags/unedited-zone-error-tag';
import { getKeepShoppingTag, getViewEmailSplashTag } from './tags/view-email-splash-tag';
import { getWamPhotoUploadTag } from './tags/wam-photo-upload-tag';

export const POD_TAGS = {
  ADD_NEW_COLOR: 'POD/addNewColor',
  ADD_TO_CART: 'POD/addToCart',
  ADD_TO_CART_ERROR: 'POD/addToCartError',
  CHANGE_QUANTITY_FROM_PROJECT_SUMMARY: 'POD/changeQuantityFromProjectSummary',
  CLICK_ADDRESS_RECIPIENT: 'POD/clickAddressRecipient',
  CLICK_NEXT_ADDRESS_SENDER: 'POD/clickNextAddressSender',
  CLICK_PREVIEW: 'POD/clickPreview',
  CLICK_PREVIEW_MODE: 'POD/clickPreviewMode',
  DELETE_ELEMENT: 'POD/deleteElement',
  ENVELOPE_PRINT_CHOICE: 'POD/envelopePrintChoice',
  EXIT_FLOW: 'POD/exitFlow',
  EMAIL_DELIVERY_RECIPIENT: 'POD/EmailDeliveryRecipient',
  INITIALIZE_EDIT: 'POD/initializeEdit',
  IMAGE_FORMATS: 'POD/imageFormats',
  LEAVE_PROJECT_SUMMARY: 'POD/leaveProjectSummary',
  OBJECT_SELECTION: 'POD/objectSelection',
  RETRY_TRIGGERED: 'POD/retryTriggered',
  RETURN_TO_EDIT: 'POD/returnToEdit',
  SEND_EMAIL_NOW: 'POD/sendEmailNow',
  START_FLOW: 'POD/startFlow',
  SIGN_IN: 'POD/signIn',
  SAVE_LINK: 'POD/saveLink',
  TEXT_FORMATS: 'POD/textFormats',
  UNEDITED_ZONE_ERROR: 'POD/uneditedZoneError',
  UPLOAD_PHOTO: 'POD/uploadPhoto',
  UPLOAD_PHOTO_ERROR: 'POD/uploadPhotoError',
  PAGE_NAVIGATION: 'POD/pageNavigation',
  VIEW_PROJECT_SUMMARY: 'POD/viewProjectSummary',
  PHOTO_UPLOAD_METHOD: 'POD/photoUploadMethod',
  DG_PLAY_ANIMATION: 'POD/dgPlayAnimation',
  SUCCESFULLY_SENT_EMAIL: 'POD/sucessfullySentEmail',
  VIEW_EMAIL_SPLASH: 'POD/viewEmailSplash',
  KEEP_SHOPPING: 'POD/keepShopping',
  DG_VIEW_FACE_PREVIEW: 'POD/dgViewFacePreview',
  EDIT_PROJECT_NAME: 'POD/editProjectName',
  DEFAULT_PROJECT_NAME: 'POD/defaultProjectName',
  EXIT_SAVE_PROJECT: 'POD/exitSaveProject',
  WAM_PHOTO_UPLOAD: 'POD/wamPhotoUpload',
};

export const getTag = (tagType: string, options: TagOptions) => {
  switch (tagType) {
    case POD_TAGS.ADD_NEW_COLOR:
      return getAddNewColorTag(options as GetAddNewColorOptions);
    case POD_TAGS.ADD_TO_CART:
      return getAddToCartTag(options as DefaultProductOptions);
    case POD_TAGS.ADD_TO_CART_ERROR:
      return getAddToCartErrorTag(options as GetAddToCartErrorOptions);
    case POD_TAGS.CHANGE_QUANTITY_FROM_PROJECT_SUMMARY:
      return getChangeQuantityFromProjectSummaryTag(options as GetChangeQuantityFromProjectSummaryOptions);
    case POD_TAGS.CLICK_ADDRESS_RECIPIENT:
      return getClickAddressRecipientTag(options as DefaultProductOptions);
    case POD_TAGS.CLICK_NEXT_ADDRESS_SENDER:
      return getClickNextAddressSenderTag(options as DefaultProductOptions);
    case POD_TAGS.CLICK_PREVIEW:
      return getLoadPreview(options as DefaultProductOptions);
    case POD_TAGS.UNEDITED_ZONE_ERROR:
      return getUneditedZoneErrorTag(options as GetUneditedZoneErrorOptions);
    case POD_TAGS.CLICK_PREVIEW_MODE:
      return getClickPreviewModeTag(options as GetPreviewModeOptions);
    case POD_TAGS.DELETE_ELEMENT:
      return getDeleteElementTag(options as GetDeleteElementOptions);
    case POD_TAGS.ENVELOPE_PRINT_CHOICE:
      return getEnvelopePrintChoiceTag(options as GetEnvelopePrintChoiceOptions);
    case POD_TAGS.EMAIL_DELIVERY_RECIPIENT:
      return getEmailDeliveryRecipientTag(options as GetEmailDeliveryOptions);
    case POD_TAGS.INITIALIZE_EDIT:
      return getInitializeEditTag(options as DefaultProductOptions);
    case POD_TAGS.IMAGE_FORMATS:
      return getImageFormats(options as GetImageFormatsOptions);
    case POD_TAGS.OBJECT_SELECTION:
      return getObjectSelectionTag(options as GetObjectSelectionOptions);
    case POD_TAGS.UPLOAD_PHOTO:
      return getUploadPhotoTag(options as GetUploadPhotoOptions);
    case POD_TAGS.RETRY_TRIGGERED:
      return getRetryHandlerTriggeredTag(options as GetRetryHandlerTriggeredOptions);
    case POD_TAGS.RETURN_TO_EDIT:
      return getReturnToEditTag(options as DefaultProductOptions);
    case POD_TAGS.SEND_EMAIL_NOW:
      return getSendEmailNowTag(options as GetSendEmailNowOptions);
    case POD_TAGS.START_FLOW:
      return getStartFlowTag(options as GetStartFlowOptions);
    case POD_TAGS.SIGN_IN:
      return getSignIn(options as GetSignInOptions);
    case POD_TAGS.SAVE_LINK:
      return getSaveLinkTag(options as GetSaveLinkOptions);
    case POD_TAGS.TEXT_FORMATS:
      return getTextFormatsTag(options as GetTextFormatsOptions);
    case POD_TAGS.UPLOAD_PHOTO_ERROR:
      return getUploadPhotoErrorTag(options as GetUploadPhotoErrorOptions);
    case POD_TAGS.EXIT_FLOW:
      return getExitFlowTag(options as DefaultProductOptions);
    case POD_TAGS.LEAVE_PROJECT_SUMMARY:
      return getLeaveProjectSummaryTag(options as GetLeaveProjectSummaryOptions);
    case POD_TAGS.PAGE_NAVIGATION:
      return getPageNavigationTag(options as GetPageNavigation);
    case POD_TAGS.VIEW_PROJECT_SUMMARY:
      return getViewProjectSummaryTag(options as GetViewProjectSummaryOptions);
    case POD_TAGS.PHOTO_UPLOAD_METHOD:
      return getPhotoUploadMethodTag(options as GetPhotoUploadMethodOptions);
    case POD_TAGS.DG_PLAY_ANIMATION:
      return getDGPlayAnimationTag(options as GetDGPlayAnimationOptions);
    case POD_TAGS.SUCCESFULLY_SENT_EMAIL:
      return getSuccesfullySentEmailTag(options as DefaultProductOptions);
    case POD_TAGS.VIEW_EMAIL_SPLASH:
      return getViewEmailSplashTag(options as DefaultProductOptions);
    case POD_TAGS.KEEP_SHOPPING:
      return getKeepShoppingTag(options as DefaultProductOptions);
    case POD_TAGS.DG_VIEW_FACE_PREVIEW:
      return getDGViewFacePreview(options as GetPreviewModeOptions);
    case POD_TAGS.EDIT_PROJECT_NAME:
      return getEditProjectNameTag(options as getEditProjectNameOptions);
    case POD_TAGS.DEFAULT_PROJECT_NAME:
      return getDefaultProjectNameTag(options as getEditProjectNameOptions);
    case POD_TAGS.EXIT_SAVE_PROJECT:
      return getExitSaveProjectTag(options as GetExitSaveProjectOptions);
    case POD_TAGS.WAM_PHOTO_UPLOAD:
      return getWamPhotoUploadTag(options as GetWamPhotoUploadTag);
    default:
      return {};
  }
};
