import { FormValidation } from '../../global-types';

export enum addressStatus {
  UNKNOWN = 'unknown',
  VALIDATING = 'validating',
  FILLED_IN = 'filledIn',
  VALID = 'valid',
  INVALID = 'invalid',
  INCOMPLETE = 'incomplete',
  ERROR = 'error',
  ERROR_INVALID = 'err_invalid_address',
  ERROR_INCOMPLETE = 'err_incomplete_address',
}

export enum addressAction {
  CONTINUE = 'continue',
  FINISH = 'finish',
}

type addressProps = {
  address1: string;
  address2?: string;
  address3?: string;
  city: string;
  state: string;
  zip: string;
};

export type AddressConfirmationProps = {
  isOpen: boolean;
  close: () => void;
  onSkipValidation: (validationAddresses: FormValidation[]) => void;
  onEditAddress: (stepToEdit: number) => void;
  addressesToValidate: FormValidation[];
};

export type AddressBoxProps = {
  address: addressProps;
  addressOwner: string;
  addClass?: string;
  onEditAddress: () => void;
};
