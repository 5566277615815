import { GetEmailDeliveryOptions } from '../analytics-context-types';
import { getDGModalTag, getPODProductTag } from './utils';

export const getEmailDeliveryRecipientTag = (options: GetEmailDeliveryOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const { modal, user } = getDGModalTag('Email Delivery - Recipient', [], options.projectID);

  return {
    modal,
    product,
    user,
  };
};
