/* eslint-disable max-len */
export const digitalOrdersMock = {
  meta: {
    code: 201,
    version: '97e180e0',
  },
  data: {
    order_reference_id: 'fakeid10',
  },
};
