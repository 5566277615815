import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks';
import { PODRoutes } from '../../routes';

export const SFCCRedirect = (): ReactElement => {
  const queryParams = useQueryParams();
  const history = useHistory();

  useEffect(() => {
    const queryRoute = queryParams.get('route');
    const queryPath = queryParams.get('path');
    const podRoutes = PODRoutes.map((object) => object.route.split('/')[1]); // gets names of routes, since "create" is technically named "Editor"
    const viewEqualsRoute = podRoutes.find((podRoute) => podRoute === queryRoute);
    const viewIsCreateOrEdit = ['create', 'edit'].find((podRoute) => podRoute === queryRoute);

    if (!viewEqualsRoute) return;

    let pathName = `/card/customization/${queryRoute}`;
    if (viewIsCreateOrEdit) pathName = `${pathName}/${queryPath}`;

    history.replace({
      pathname: pathName,
      search: queryParams.toString(),
    });
  });

  // TODO: add some kind of instructions to redirect the user if they reach the message below.
  return (
    <div>
      <h1>Redirect View</h1>
      <p>Route was not found</p>
    </div>
  );
};
