import { AddressView } from '../views/address';
import { EditorView } from '../views/editor';

export const PODRoutes = [
  {
    name: 'Editor',
    route: '/create/:productId',
    exact: true,
    view: EditorView,
  },
  {
    name: 'Editor',
    route: '/edit/:projectId',
    exact: true,
    view: EditorView,
  },
  {
    name: 'Address',
    route: '/address',
    exact: true,
    view: AddressView,
  },
];
