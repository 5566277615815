import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  IconButton,
  IconButtonVariants,
  IconButtonBrandColors,
  IconNames,
} from '@hallmark/web.core.buttons.icon-button';
import { TextField } from '@hallmark/web.core.forms.text-field';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { isLoggedIn } from '../../../../utils';
import { HeaderContentProps } from '../summary-drawer-types';
import styles from '../summary-drawer.module.scss';

export const HeaderContent = ({ projectName, onUpdateDraftName, isSaving }: HeaderContentProps): React.ReactElement => {
  const [isEditing, setIsEditing] = useState(false);
  const [draftName, setDraftName] = useState(projectName);
  const isLogged = isLoggedIn();

  type FormInputs = {
    draftName: string;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: { draftName: draftName },
  });

  const onSubmit = (data: FormInputs) => {
    setIsEditing(false);
    setDraftName(data.draftName);
    onUpdateDraftName(data.draftName, true);
  };

  const onEditClick = () => {
    setIsEditing(true);
  };

  return (
    <div className={styles['header-wrapper']} data-testid="summary-header-wrapper">
      {isEditing && (
        <form className={styles['draft-name-form']} data-testid="summary-draft-form">
          <TextField
            addClass={styles['summary-input']}
            label="Draft name"
            placeholder=""
            domId="summaryDraftInputId"
            testId="summary-input"
            register={register('draftName', { required: 'Draft Name is required' })}
            helperText={errors.draftName ? errors.draftName.message : ''}
            isError={Boolean(errors.draftName)}
          />
          <IconButton
            icon={IconNames.CheckDefault}
            iconColor={IconButtonBrandColors.PrimaryFontColor}
            variant={IconButtonVariants.Simple}
            size={22}
            click={handleSubmit(onSubmit)}
            testId="draft-name-submit-button"
          />
        </form>
      )}
      {!isEditing && (
        <>
          <div className={styles['draft-name-container']} data-testid="summary-draft-name-container">
            <Typography variant={TypographyVariants.Body}>{draftName}</Typography>
          </div>
          <div>
            {isLogged && (
              <IconButton
                icon={IconNames.Edit}
                iconColor={IconButtonBrandColors.PrimaryFontColor}
                variant={IconButtonVariants.Simple}
                size={22}
                click={onEditClick}
                disabled={isSaving}
                testId="draft-name-edit-button"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
