import { useEffect } from 'react';
import { fabric } from 'fabric';
import { setDialogType, useAppContext } from '../context/app-context';
import { DialogType } from '../global-types/dialog';
import { CanvasDataTypes } from '../utils';

interface UseKeyboardDeleteProps {
  canvas: fabric.Canvas | null | undefined;
  openDeleteConfirmationDialog: () => void;
}

export const useKeyboardDelete = ({ canvas, openDeleteConfirmationDialog }: UseKeyboardDeleteProps): void => {
  const { appDispatch } = useAppContext();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Delete') {
        const activeObject = canvas?.getActiveObject();
        if (activeObject) {
          switch (activeObject?.data?.type) {
            case CanvasDataTypes.UserText:
              setDialogType(appDispatch, DialogType.Text);
              break;
            case CanvasDataTypes.StickerImage:
              setDialogType(appDispatch, DialogType.Sticker);
              break;
            case CanvasDataTypes.UserImage:
              setDialogType(appDispatch, DialogType.Image);
              break;
            default:
              setDialogType(appDispatch, DialogType.Text);

              break;
          }

          openDeleteConfirmationDialog();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [canvas, openDeleteConfirmationDialog]);
};
