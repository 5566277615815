import API from '@ce-digital/api-middleware';
import { fulfillmentResponse, envelopPreviewResponse, previewResponse } from '../data';
import {
  ApiResponse,
  EnvelopePreviewResponse,
  FulfillmentResponse,
  PreviewResponseData,
  PreviewFormData,
  GetProjectResponseData,
} from '../global-types';
import { getCookie } from '../utils';
import { processResponse } from './middleware';

const PODService = new API();
const accountId = getCookie('accountId');
const mockResponse = process.env.REACT_APP_MOCK_INITIALIZATION_SERVICE === 'true';

export const getPreview = async (
  projectId: string,
  formData: PreviewFormData,
): Promise<ApiResponse<PreviewResponseData>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(previewResponse);
    }

    // TODO Add correct type to return from preview endpoint, since there is still work to be done in this
    // endpoint , for now we keep an any type

    const response = PODService.post('/customization', `/${accountId}/${projectId}/preview`, formData);
    processResponse(response, resolve, reject);
  });
};

export const getEnvelopePreview = (projectId: string, formData: string): Promise<EnvelopePreviewResponse> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: EnvelopePreviewResponse = envelopPreviewResponse;
      resolve(response);
    }

    const response = PODService.post('/customization', `/${accountId}/${projectId}/envelope-preview`, formData);
    processResponse(response, resolve, reject);
  });
};

export const getFulfillment = (projectTypeCode: string, productId: string): Promise<FulfillmentResponse> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response = fulfillmentResponse;
      resolve(response);
    }

    const response = PODService.get(
      `/customization/fulfillment?project_type_code=${projectTypeCode}&product_id=${productId}`,
    );
    processResponse(response, resolve, reject);
  });
};

export const updateFulfillment = (
  quantity: number,
  deliveryMode: string,
  projectId: string,
): Promise<ApiResponse<FulfillmentResponse>> => {
  return new Promise((resolve, reject) => {
    const response = PODService.patch('customization/', `${accountId}/${projectId}/fulfillment`, {
      purchase_date: new Date(),
      purchase_quantity: quantity,
      delivery_mode: deliveryMode,
    });
    processResponse(response, resolve, reject);
  });
};

export const getProjectDetails = (projectId: string): Promise<ApiResponse<GetProjectResponseData>> => {
  return new Promise((resolve, reject) => {
    const response = PODService.get('customization/', `${accountId}/${projectId}/load`);
    processResponse(response, resolve, reject);
  });
};
