import { CardContextState } from '../../context/card-context';
import { FabricTextBox, Breakpoints } from '../../global-types';
import { mergeAndShrinkText } from '../helper-settings';
import { findTextById } from '../utility/find-text-by-id';

export const lockEditableSize = (editableInput: FabricTextBox, cardState: CardContextState, canvas, appDispatch, t) => {
  // Filter the Faces and brings the settings for each editable textbox
  const templateFieldsFiltered = findTextById(cardState.cardFacesList, editableInput.data.ID);

  editableInput.on('changed', () => {
    if (!editableInput.isModified) editableInput.isModified = true;

    let breakpoints = editableInput.breakpoints?.length;

    if (!templateFieldsFiltered?.IsMultiline) {
      if (breakpoints) {
        let lastBreakpoint = (editableInput.breakpoints as Breakpoints)[breakpoints - 1];

        // checking if they went below the most recent breakpoint and restoring state
        while ((editableInput.text as string).length < lastBreakpoint.chars) {
          editableInput.fontSize = lastBreakpoint.fontsize;
          (editableInput.breakpoints as Breakpoints).pop();
          breakpoints -= 1;

          if (breakpoints > 0) {
            lastBreakpoint = (editableInput.breakpoints as Breakpoints)[breakpoints - 1];
          } else break;
        }
      }
      mergeAndShrinkText(editableInput, canvas, appDispatch, t);
    } else {
      const editableText: string = editableInput.text || '';
      while (editableInput.text?.endsWith('\n')) {
        const cleanedEditableText: string = editableText.replace(/\n+$/, '');

        editableInput.selectionStart = cleanedEditableText.length;
        editableInput.selectionEnd = cleanedEditableText.length;
        (editableInput.hiddenTextarea as HTMLTextAreaElement).value = cleanedEditableText;
        editableInput.text = cleanedEditableText;
      }
    }
  });
};
