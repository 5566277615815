import React from 'react';
import ReactDom from 'react-dom';
import PrintOnDemand from './App';
import { RegionalCodesList } from './global-types';
import './i18n';
import './index.css';
import { getTokensFromCookies, removeTokens } from './utils';
import { isAnyOfRegions } from './utils/utility';

const locale = process.env.REACT_APP_THEME;

if (isAnyOfRegions([RegionalCodesList.ce, RegionalCodesList.uk])) {
  // Shopify stores the tokens on Cookies, but our api-middleware needs them on localStorage
  getTokensFromCookies();
}

if (locale === RegionalCodesList.us) {
  removeTokens();
}

ReactDom.render(
  <React.StrictMode>
    <PrintOnDemand />
  </React.StrictMode>,
  document.getElementById('root'),
);
