import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Range, RangeVariants } from '@hallmark/web.core.forms.range';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useActiveCanvas } from '../../../../hooks';
import { config } from '../../../../regional-config';
import styles from '../scale-drawer.module.scss';

export const HeaderContent = React.memo((): React.ReactElement => {
  const { register, setValue } = useForm();
  const canvas = useActiveCanvas();
  const { t } = useTranslation();

  const rangeName = 'ScaleRange';
  const [scaleValue, setScaleValue] = useState(100);
  const [initialScaleValue, setInitialScaleValue] = useState<number>(1);

  const onChange = useCallback(
    (valueInRange: number) => {
      requestAnimationFrame(() => {
        const scaleFactor = valueInRange / 100;
        const activeObject = canvas?.current?.getActiveObject();
        if (activeObject && activeObject.type === 'image') {
          activeObject.set({
            scaleX: initialScaleValue * scaleFactor,
            scaleY: initialScaleValue * scaleFactor,
          });
          canvas?.current?.renderAll();
          setScaleValue(valueInRange);
          setValue(rangeName, valueInRange);
          activeObject?.fire('custom:updateBorders');
        }
      });
    },
    [canvas, initialScaleValue],
  );

  const handleScaling = useCallback(() => {
    const activeObject = canvas?.current?.getActiveObject();
    requestAnimationFrame(() => {
      if (activeObject && activeObject.type === 'image') {
        const newScale = Math.max(0.01, Math.min(activeObject.scaleX ?? 1, 2));
        const scaleInPercent = (newScale / initialScaleValue) * 100;
        setScaleValue(scaleInPercent);
        setValue(rangeName, scaleInPercent);
      }
    });
  }, [canvas, setValue, initialScaleValue]);

  useEffect(() => {
    const activeObject = canvas?.current?.getActiveObject();
    if (activeObject && activeObject.type === 'image') {
      if (activeObject.scaleX !== undefined) {
        setInitialScaleValue(activeObject.scaleX);
      }
    }

    canvas?.current?.on('object:scaling', handleScaling);
    return () => {
      canvas?.current?.off('object:scaling', handleScaling);
    };
  }, [canvas, handleScaling]);

  const scaleLabel = useMemo(
    () => (
      <div className={styles['scale-label']}>
        <Typography variant={config?.scaleDrawer?.labelTypographyVariant as TypographyVariants}>
          {t('scaleDrawer.out')}
        </Typography>
        <Typography
          variant={config?.scaleDrawer?.labelValueTypographyVariant as TypographyVariants}
          addClass={styles['value']}
        >
          {`${Math.round(scaleValue)}`}
        </Typography>
        <Typography variant={config?.scaleDrawer?.labelTypographyVariant as TypographyVariants}>
          {t('scaleDrawer.in')}
        </Typography>
      </div>
    ),
    [scaleValue, t],
  );

  return (
    <div className={styles['header-wrapper']}>
      {scaleLabel}
      <Range
        domId={'scale'}
        label={'Scale'}
        min={0}
        max={200}
        step={1}
        tickCount={0}
        onChange={onChange}
        register={register(rangeName)}
        variant={config?.scaleDrawer?.rangeVariant as RangeVariants}
        initialValue={scaleValue}
      />
    </div>
  );
});
