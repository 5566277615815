import { InitializationData } from '../../global-types';

/**
 * Checks if the Faces property exists within the responseData object
 * Note: This function is TEMPORARY until IDs are added to the texts from the API response
 * @param {InitializationData} responseData - The data to be checked
 * @returns {boolean} - True if Faces property exists, false otherwise
 */
const isFacesPropertyExists = (responseData: InitializationData): boolean => {
  return !!(
    responseData &&
    responseData.variables &&
    responseData.variables.template_data &&
    responseData.variables.template_data.Faces.length > 0
  );
};

/**
 * Adds an ID to each text in the responseData by concatenating TopPosition and LeftPosition
 * Note: This function is TEMPORARY until IDs are added to the texts from the API response
 * @param {InitializationData} responseData - The data to be updated
 * @returns {InitializationData} - The updated data with IDs added to texts
 */
const addIdToTexts = (responseData: InitializationData): InitializationData => {
  if (isFacesPropertyExists(responseData)) {
    responseData.variables.template_data.Faces.forEach((face) => {
      if (face.Texts) {
        face.Texts.forEach((text) => {
          // We create an ID by concatenating the TopPosition and LeftPosition after removing any decimal points
          if (text.TopPosition && text.LeftPosition) {
            text.ID = String(text.TopPosition).replace('.', '') + String(text.LeftPosition).replace('.', '');
          }
        });
      }
    });
  }

  return responseData;
};

export { isFacesPropertyExists, addIdToTexts };
