import { GetExitSaveProjectOptions } from '../analytics-context-types';
import { getModalLevel2, getModalTag, getPODProductTag } from './utils';

/**
 * Gets the modal and product tag to track when the user saves project after clicking 'X' or crown button to exit
 *
 * @param options attributes to be included in product tag and modal event tag. eventName identifies if project name was updated
 * @returns
 */
export const getExitSaveProjectTag = (options: GetExitSaveProjectOptions) => {
  const SAVE_PROJECT_NAMES = {
    defaultName: 'save project - default project name',
    editName: 'save project - edit project name',
  };

  const EXIT_PROJECT_NAMES = {
    'exit-x': 'exit-x',
    exit: 'exit',
  };

  const exitSaveProjectEvent = [
    {
      eventInfo: {
        eventName: 'exit',
        eventAction: EXIT_PROJECT_NAMES[`${options.eventExitAction}`],
      },
    },
    {
      eventInfo: {
        eventName: 'exit',
        eventAction: SAVE_PROJECT_NAMES[`${options.eventAction}`],
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  // We may need to verify that this returns only "Preview Mode" (user is in editor view and has NOT made changes to the card yet)
  // or "Edit mode" (user is in editor view and has made changes to the card)
  const level2 = getModalLevel2(options.productType);

  const { modal, user } = getModalTag(options.productType, level2, exitSaveProjectEvent, options.projectID);

  return {
    product,
    modal,
    user,
  };
};
