import heic2any from 'heic2any';
import { detectHeicImage } from './detect-heic-image';

export const imageConverter = (file: File): (() => Promise<File>) => {
  const fileTypesToConvert = ['image/webp', 'image/gif'];

  const convertHeicToJpeg = async (file: File): Promise<File> => {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.7,
      });
      if (convertedBlob !== undefined) {
        return new File([convertedBlob as Blob], file.name, { type: 'image/jpeg' });
      } else {
        throw new Error('Failed to convert HEIC image to JPEG');
      }
    } catch (error) {
      throw new Error('Failed to convert HEIC image to JPEG: ' + error);
    }
  };

  const convertImageToJpeg = async (file: File): Promise<File> => {
    try {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      await new Promise((resolve) => {
        img.onload = resolve;
      });

      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        throw new Error('Unable to get 2D context');
      }

      ctx.drawImage(img, 0, 0);

      const jpegDataUrl = canvas.toDataURL('image/jpeg', 0.7);
      const jpegBlob = await fetch(jpegDataUrl).then((res) => res.blob());

      return new File([jpegBlob], file.name, { type: 'image/jpeg' });
    } catch (error) {
      throw new Error('Failed to convert image to JPEG: ' + error);
    }
  };

  const convertImage = async (): Promise<File> => {
    // If the image is not a HEIC or WebP image, return the original file
    const isHeicImage = detectHeicImage(file);
    if (!fileTypesToConvert.includes(file.type) && !isHeicImage) {
      return file;
    }

    if (isHeicImage) {
      return await convertHeicToJpeg(file);
    } else {
      // At this point, the file must be a WebP image
      return await convertImageToJpeg(file);
    }
  };

  return convertImage;
};
