import { ApiResponse } from '../../global-types';
import { AssetStorageResponse } from '../../global-types/images';
import { uploadPreviewToS3 } from '../../services/image';
import { dataUriToBlob } from '../utility/data-uri-to-blob';

/**
 * Uploads preview image to S3
 * Once finished uploading images, links assets
 *
 * @param assetStorageUrls A list of asset storage urls for each preview image
 * @param previewImage The preview image to upload
 * @param index The index of the preview image to upload
 * @returns Promise
 */
export const uploadPreviewImage = async (
  assetStorageUrls: ApiResponse<AssetStorageResponse>,
  previewImage: string,
  index: number,
) => {
  try {
    return new Promise((resolve) => {
      if (assetStorageUrls?.data?.[`${index}`]) {
        const myFormData = prepareFormData(previewImage);
        uploadPreviewToS3(myFormData, assetStorageUrls?.data?.[`${index}`]).then(async () => {
          resolve(null);
        });
      }
    });
  } catch (error) {
    throw new Error(`Error during uploadPreviewImage process`);
  }
};

// TODO: When MIMIR-3234 is finished, we can probably remove prepareFormData
/**
 * Create formData for S3 preview image upload
 *
 * @param saveData A list of card faces with printJson, which we use for printJson previews only
 * @returns void
 */
const prepareFormData = (image) => {
  const blob = dataUriToBlob(image);
  const formData = new FormData();
  if (blob) {
    formData.append('file', blob);
    formData.append('is_handwriting_image', `false`);
    formData.append('display_indicator', `false`);
  } else {
    throw new Error('Error generating preview blob');
  }
  return formData;
};
