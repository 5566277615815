import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, ButtonModes, ButtonTypographyVariants } from '@hallmark/web.core.buttons.button';
import { Dialog, DialogContentType, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { Address } from '@hallmark/web.core.typography.address';
import { TypographyVariants, Typography } from '@hallmark/web.core.typography.typography';
import { useInitializationDataContext } from '../../context/data-context';
import { AddressTypes, ErrorType } from '../../global-types';
import { capitalize } from '../../utils';
import { AddressConfirmationProps, AddressBoxProps, addressStatus } from './address-confirmation-types';
import styles from './address-confirmation.module.scss';

const AddressConfirmation = ({
  isOpen,
  close,
  addressesToValidate,
  onSkipValidation,
  onEditAddress,
}: AddressConfirmationProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();
  const { initializedDataState } = useInitializationDataContext();
  const { isUK } = initializedDataState;

  const incompleteAddressMessage = (addressOwner: AddressTypes) =>
    t('addressConfirmation.inCompleteAddressMessage', { addressOwner });

  const invalidAddressMessage = (addressOwner: AddressTypes) =>
    t('addressConfirmation.inValidAddressMessage', { addressOwner });

  const currentValidationAddress = addressesToValidate[`${activeIndex}`];
  const isIncomplete = currentValidationAddress.errorType === ErrorType.Incomplete;
  const addressOwner = currentValidationAddress.addressType;
  const addressStatusStr = isIncomplete ? addressStatus.INCOMPLETE : addressStatus.INVALID;
  const finalValidationBody = isIncomplete
    ? incompleteAddressMessage(addressOwner)
    : invalidAddressMessage(addressOwner);

  return (
    <Dialog
      type={DialogContentType.Modal}
      isOpen={isOpen}
      title={`${capitalize(addressStatusStr)} ${capitalize(addressOwner)} Address`}
      onClose={close}
      addDialogClass={styles['address-confirmation-dialog']}
      footerType={DialogFooterType.Passive}
      locale={isUK ? 'uk' : 'us'}
    >
      <div className={styles['suggested-address-content']}>
        <div className={styles['suggested-address-description']}>
          <Typography variant={TypographyVariants.Body} addClass={styles['suggested-address-body']}>
            {finalValidationBody}
          </Typography>
        </div>
      </div>
      <div className={styles['bottom-content']}>
        <AddressBox
          addClass={styles['address-box']}
          address={currentValidationAddress.address}
          addressOwner={addressOwner}
          onEditAddress={() => onEditAddress(currentValidationAddress.step)}
        />
        <div className={styles['modal-actions']}>
          {addressesToValidate?.length > 1 && (
            <Typography variant={TypographyVariants.Body} addClass={styles['address-confirmation-steps']}>{`
            ${activeIndex + 1} / ${addressesToValidate.length}`}</Typography>
          )}
          <Button
            addClass={styles['confirm-address-btn']}
            click={() => {
              if (addressesToValidate?.length === activeIndex + 1) {
                onSkipValidation(addressesToValidate);
              } else {
                setActiveIndex((activeIndex) => activeIndex + 1);
              }
            }}
          >
            Confirm Address
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const AddressBox = ({ address, addressOwner, addClass, onEditAddress }: AddressBoxProps) => {
  const addressBoxClassNames = classNames('address-box', addClass);
  const { t } = useTranslation();

  return (
    <div className={addressBoxClassNames}>
      <Typography variant={TypographyVariants.Eyebrow} addClass={styles['address-header']}>
        {`${addressOwner} Address`}
      </Typography>
      <Address {...address} addClass={styles.address} />
      <Button
        mode={ButtonModes.TextLink}
        typographyVariant={ButtonTypographyVariants.TextLink}
        addClass={styles['edit-button']}
        click={onEditAddress}
      >
        {t('addressConfirmation.editAddress')}
      </Button>
    </div>
  );
};

export default AddressConfirmation;
