import { RefObject } from 'react';
import { CardFaceData } from '../../../global-types';

export type OnCardFaceIndexChangeOptions = {
  activeCanvas: RefObject<fabric.Canvas> | undefined;
  editableFaces: CardFaceData[];
  slideIndex: number;
  beforeEditingFaceJson?: string | null | undefined;
  isLoggedIn: boolean;
};

/**
 * Hook to handle extra logic and save project if current face canvas was changed and user is logged in
 * @returns onCardFaceIndexChange function
 */
export const useOnCardFaceIndexChange = () => {
  /**
   * Triggered when current card face index is changed by clicking on next button or card-select-bar thumbnail btn
   * discards active object from the current canvas and calls autoSaveProject if changes were made to canvas
   * @param options { activeCanvas, editableFaces, slideIndex, beforeEditingFaceJson, isLoggedIn }
   */
  const onCardFaceIndexChange = (options: OnCardFaceIndexChangeOptions) => {
    const { activeCanvas } = options;
    const activeObject = activeCanvas?.current?.getActiveObject();
    if (activeObject) {
      activeCanvas?.current?.discardActiveObject().requestRenderAll();
    }
  };

  return { onCardFaceIndexChange };
};
