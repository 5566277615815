export enum RegionalCodesList {
  us = 'us',
  uk = 'uk',
  ce = 'ce',
  all = 'all',
}

export enum TextEditorDrawerType {
  Side = 'Side',
  Bottom = 'Bottom',
}
