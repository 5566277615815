import { useEffect } from 'react';
import { setPersonalizationData, useAppContext } from '../../../context/app-context';
import { useCardContext } from '../../../context/card-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { useLoadCardData } from '../../../hooks';
import { getTransformedPersonalizationData } from '../../../utils';

/**
 * Handles the update of cardFacesList state from card-context and personalizationData state for app-context
 * After login the contexts are re-initialized with the refresh of the page so we need to populate this states after getting the data from the loaded project
 */
export const useLoadCardAfterLogin = () => {
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { appState, appDispatch } = useAppContext();
  const { cardState } = useCardContext();
  const { loadCardData } = useLoadCardData();

  useEffect(() => {
    const projectTypeCode = initializedData?.project_type_code;
    if (!projectTypeCode || appState.personalizationData.length > 0) {
      return;
    }
    if (cardState.cardFacesList.length > 0) {
      const personalizationData = getTransformedPersonalizationData(cardState, projectTypeCode);
      return setPersonalizationData(appDispatch, personalizationData);
    }
    loadCardData();
  }, [cardState.cardFacesList, loadCardData, initializedData?.project_type_code]);
};
