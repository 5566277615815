import { useCallback } from 'react';
import { fabric } from 'fabric';
import { Canvas } from 'fabric/fabric-impl';
import { useAnalyticsContext } from '../../../../context/analytics-context';
import { setIsImageEditDrawerOpen, useAppContext } from '../../../../context/app-context';
import { ImageEditDrawerModes } from '../../../../context/app-context/app-context-types';
import { setSelectedPhotoZone, useCardContext } from '../../../../context/card-context';
import { useActiveCanvas } from '../../../../hooks';
import { CanvasDataTypes, getCardFaceClipPath, getObjectsByType, fillPhotoZone } from '../../../../utils';
import { removeEditableAreaButtons } from '../../../card-editor/utils';

export const useImageUploadHandlers = (imageEditDrawerMode?: ImageEditDrawerModes) => {
  const { appDispatch } = useAppContext();
  const { firstElementSelected, updateEditFormats, isReplacingImage } = useAnalyticsContext();
  const { cardState, cardDispatch } = useCardContext();
  const { selectedPhotoZone, cardFacesList } = cardState;
  const canvas = useActiveCanvas();
  const currentCardFace = cardState.cardFacesList[`${cardState.activeCardIndex}`];
  const isFrontFace = currentCardFace?.type === 'front';

  const getNextCoverPhotoZone = () => {
    if (!canvas?.current) {
      return;
    }
    const photoZones = getObjectsByType(canvas.current as Canvas, CanvasDataTypes.PhotoZone);
    const slots = photoZones.filter((zone) => !zone.data?.hasContent);
    let nextSlot = slots[0];
    slots.forEach((slot) => {
      if (slot.data.order < nextSlot.data.order) {
        nextSlot = slot;
      }
    });

    return nextSlot;
  };

  const openImageEditDrawer = useCallback(() => {
    if (imageEditDrawerMode) setIsImageEditDrawerOpen(appDispatch, imageEditDrawerMode);
  }, [appDispatch, imageEditDrawerMode]);

  const onImageUpload = useCallback(
    (image: fabric.Image) => {
      image.onSelect = () => {
        onImageSelect();
        return false;
      };
      if (image?.width === 0 && image?.height === 0) {
        throw new Error('The height and width of the image being attempted to upload is 0 (zero).');
      }
      if (isFrontFace) {
        if (selectedPhotoZone.id) {
          fillPhotoZone(selectedPhotoZone.id, image, cardFacesList);
        } else {
          const nextCoverSlot = getNextCoverPhotoZone();
          if (nextCoverSlot) {
            fillPhotoZone(nextCoverSlot.data.id, image, cardFacesList);
          }
        }
      } else {
        // adds the image as usual.
        const cardFaceClipPath = getCardFaceClipPath(currentCardFace, 0);
        if (cardFaceClipPath) {
          //  Center image in cardFaceClipPath
          // We use '100' because these images are scaled to 200 width/height by addImageToCanvas
          image.set({
            top: cardFaceClipPath.top + cardFaceClipPath.height / 2 - 100,
            left: cardFaceClipPath.left + cardFaceClipPath.width / 2 - 100,
            clipPath: cardFaceClipPath,
          });
        }
        removeEditableAreaButtons(canvas?.current as fabric.Canvas);
      }
      setSelectedPhotoZone(cardDispatch, null, null);
      //using setTimeout to avoid issues with the upload drawer closing.
      setTimeout(() => setIsImageEditDrawerOpen(appDispatch, ImageEditDrawerModes.UserImage), 0);
    },
    [selectedPhotoZone, cardFacesList, cardDispatch, isFrontFace, currentCardFace, canvas, setIsImageEditDrawerOpen],
  );

  const onImageSelect = useCallback(() => {
    setTimeout(() => setIsImageEditDrawerOpen(appDispatch, imageEditDrawerMode ?? ImageEditDrawerModes.UserImage), 0);
    !isReplacingImage.current && updateEditFormats({ eventAction: !firstElementSelected.current ? 'start' : 'add' });
  }, [appDispatch, firstElementSelected, isReplacingImage, updateEditFormats]);

  const openImageEditDrawerByCanvasDataType = (canvasDataType: CanvasDataTypes): boolean => {
    if (
      canvasDataType === CanvasDataTypes.UserImage ||
      canvasDataType === CanvasDataTypes.PhotoZoneImage ||
      canvasDataType === CanvasDataTypes.StickerImage
    ) {
      setIsImageEditDrawerOpen(appDispatch, canvasDataType as unknown as ImageEditDrawerModes);
      return true;
    }

    return false;
  };

  return { onImageUpload, onImageSelect, openImageEditDrawer, openImageEditDrawerByCanvasDataType };
};
