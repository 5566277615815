import { GetPhotoUploadMethodOptions } from '../analytics-context-types';
import { getModalLevel2, getModalTag, getPODProductTag } from './utils';

/**
 * Gets the modal and product tag to track when user opens image upload drawer
 * via toolbar or photozone button
 *
 * @param options attributes to be included in product tag and modal event tag
 * @returns
 */
export const getPhotoUploadMethodTag = (options: GetPhotoUploadMethodOptions) => {
  const photoUploadEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
    {
      eventInfo: {
        eventName: 'upload photo',
        eventAction: options.eventAction,
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const level2 = getModalLevel2();

  const { modal, user } = getModalTag(options.productType, level2, photoUploadEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
