import { CardType } from '../../../global-types';

type AdjustScaleParams = {
  containerWidth: number;
  canvasWidth: number;
  containerHeight: number;
  canvasHeight: number;
  isMobile: boolean | null;
  isOneToMany: boolean;
  projectTypeCode: 'S' | 'P' | 'D' | undefined;
  isInsideRight: boolean;
  instructionsRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const calculateAdjustedScale = ({
  containerWidth,
  canvasWidth,
  containerHeight,
  canvasHeight,
  isMobile,
  isOneToMany,
  projectTypeCode,
  isInsideRight,
  instructionsRef,
}: AdjustScaleParams): number => {
  let scale = Math.min(containerWidth / canvasWidth, containerHeight / canvasHeight);

  // If the project is 1:Many we take in consider the instructions text height to make
  // the canvas smaller so we can fit the beta pill text without overflowing the instrucitons text
  if (isMobile && isOneToMany && instructionsRef.current) {
    scale = Math.min(
      containerWidth / canvasWidth,
      (containerHeight - instructionsRef.current.offsetHeight / 2) / canvasHeight,
    );

    return scale;
  }

  /* Adjust scale factor if isMobile, projectTypeCode is SAS, and isInsideRight are true */
  if (isMobile && projectTypeCode === CardType.SAS && isInsideRight) {
    const scaleFactor = 0.9;
    return (scale *= scaleFactor);
  }

  return scale;
};
