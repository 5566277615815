import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Theme } from '@hallmark/web.themes.theme-provider';
import styles from './App.module.scss';
import { ContextProvider } from './context';
import { InitializationDataContext } from './context/data-context';
import './regional-config';
import FlagControlledRoutes from './routes/flag-controlled-routes';
import { preventUnload } from './utils';
import { getThemeForCurrentRegion } from './utils/get-theme-for-current-region';

const PrintOnDemand = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', preventUnload);
  }, []);

  const themeOverrides = getThemeForCurrentRegion();

  return (
    <BrowserRouter>
      <ContextProvider>
        <InitializationDataContext.Consumer>
          {() => (
            <Theme.ThemeProvider overrides={themeOverrides}>
              <div className={styles.app}>
                <FlagControlledRoutes />
              </div>
            </Theme.ThemeProvider>
          )}
        </InitializationDataContext.Consumer>
      </ContextProvider>
    </BrowserRouter>
  );
};

export default PrintOnDemand;
