import { fabric } from 'fabric';
import { CustomFabricObject } from '../../../global-types';

export const applyClipPathToImage = (image: CustomFabricObject) => {
  if (image.data?.clipPathSettings) {
    const clipPath = new fabric.Rect(image.data.clipPathSettings);
    image.clipPath = clipPath;
    return true;
  }
  return false;
};
