import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@hallmark/web.core.buttons.button';
import { config } from '../../../../regional-config';
import { FooterContentProps } from '../order-drawer-types';
import styles from '../order-drawer.module.scss';

export const FooterContent = ({ onSubmit, onClose }: FooterContentProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles['footer-wrapper']}>
      {config?.orderDrawer?.hasCancelButton && (
        <Button
          testId="rotate-drawer--cancel"
          addClass={styles['footer-cancel-button']}
          click={onClose}
          mode={config?.orderDrawer?.cancelButtonMode}
        >
          {t('footerContent.cancel')}
        </Button>
      )}
      {config?.orderDrawer?.hasActionButton && onSubmit && (
        <Button
          testId="rotate-drawer--apply"
          addClass={styles['footer-primary-button']}
          click={onSubmit}
          mode={config?.orderDrawer?.actionButtonMode}
        >
          {t('footerContent.apply')}
        </Button>
      )}
    </div>
  );
};
