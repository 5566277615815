/**
 * Function to force modal to show when leaving page.
 *
 * @param event Unload event to prevent
 * @returns
 */
export const preventUnload = (event: BeforeUnloadEvent) => {
  // message does not need to be changed but override is necessary to get modal to appear
  const message = '';
  event.preventDefault();
  event.returnValue = message;
  return message;
};

export const removeBeforeUnloadEvent = () => {
  window.removeEventListener('beforeunload', preventUnload);
};

export const navigateTo = (url: string) => {
  removeBeforeUnloadEvent();
  window.location.href = url;
};

/**
 * Redirects user to edit view
 *
 * @param projectId Id of the current project
 * @param search url search params to be preserved
 */
export const redirectToEdit = (projectId: string, search: string) => {
  const redirectUrl = new URL(`${window.location.origin}/card/customization/edit/${projectId}${search}`);
  window.location.assign(redirectUrl);
};
