import { fabric } from 'fabric';
import { config } from '../../../regional-config';
import { CustomControlOptions } from '../canvas-custom-config-types';

const defaultIcon = config?.customControls?.icons?.rectangleIcon ?? '';

const drawCorner = (
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  cornerSize: number,
  lineWidth: number,
) => {
  ctx.beginPath();
  ctx.moveTo(left, top);
  ctx.lineTo(left + cornerSize, top);
  ctx.lineTo(left + cornerSize, top + lineWidth);
  ctx.lineTo(left + lineWidth, top + lineWidth);
  ctx.lineTo(left + lineWidth, top + cornerSize);
  ctx.lineTo(left, top + cornerSize);
  ctx.lineTo(left, top);
  ctx.closePath();
  ctx.stroke();
  ctx.fill();
};

export const renderCornerControl = (
  controlName: string,
  ctx: CanvasRenderingContext2D,
  options: CustomControlOptions,
) => {
  const { left, top, size, angle, icon } = options;

  const lineWidth = 3;
  const iconImage = new Image();
  iconImage.src = icon || defaultIcon;

  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(angle));

  if (icon && ['tl', 'tr', 'bl', 'br'].includes(controlName)) {
    ctx.drawImage(iconImage, -size / 2, -size / 2, size, size);
  } else {
    switch (controlName) {
      case 'tl':
        drawCorner(ctx, 0, 0, size, lineWidth);
        break;
      case 'tr':
        ctx.rotate(fabric.util.degreesToRadians(90));
        drawCorner(ctx, 0, 0, size, lineWidth);
        break;
      case 'bl':
        ctx.rotate(fabric.util.degreesToRadians(-90));
        drawCorner(ctx, 0, 0, size, lineWidth);
        break;
      case 'br':
        ctx.rotate(fabric.util.degreesToRadians(180));
        drawCorner(ctx, 0, 0, size, lineWidth);
        break;
    }
  }

  ctx.restore();
};
