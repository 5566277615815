import { useEffect, useState } from 'react';
import { useActiveCanvas } from '../../../../hooks';
import { CanvasDataTypes, overlayImageDefaultSettings } from '../../../../utils';
import { bringBackObject } from '../utils/bring-back-object';
import { bringFrontObject } from '../utils/bring-front-object';

export const usePositionTab = () => {
  const canvas = useActiveCanvas();
  const [lowestIndex, setLowestIndex] = useState(0);

  useEffect(() => {
    if (canvas?.current) {
      const objects = [...canvas.current.getObjects()];

      const overlayImgIndex = objects.findIndex((obj) => obj.name === overlayImageDefaultSettings.name);
      const foldLineIndex = objects.findIndex((obj) => obj?.data?.type === CanvasDataTypes.FoldLine);

      // If there is an overlayImage object, the text cannot go behind that object.
      if (overlayImgIndex !== -1) {
        setLowestIndex(overlayImgIndex + 1);
      } else if (foldLineIndex !== -1) {
        // If there is no overlayImage object but there is an  image with data.type === "fold-line"
        // the text cannot go behind the "fold-line" object.
        setLowestIndex(foldLineIndex + 1);
      }
    }
  }, []);

  const handleBringToFrontClick = () => {
    bringFrontObject(canvas);
  };

  const handleSendToBackClick = () => {
    bringBackObject(canvas, lowestIndex);
  };

  return {
    handleBringToFrontClick,
    handleSendToBackClick,
  };
};
