import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { removeBeforeUnloadEvent } from '../../../utils';
import { ActionButtonProps } from '../system-failure-dialog-types';
import styles from '../system-failure-dialog.module.scss';

export const ActionButton = ({ click }: ActionButtonProps): React.ReactElement => {
  const { t } = useTranslation();

  const onClick = () => {
    removeBeforeUnloadEvent();
    click();
  };

  return (
    <Button click={onClick} mode={ButtonModes.Primary} testId="action-button" addClass={styles['action-button']}>
      {t('systemFailureDialog.action')}
    </Button>
  );
};
