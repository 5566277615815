import { TextEditorDrawerType } from '../../../global-types/regions-list';
import { CanvasDataTypes } from '../../../utils';

interface CanvasObject {
  data?: {
    type: string;
  };
}

const DRAWER_FRONT_ADD_TEXT = 'editor--drawer-front-add-text';
const DRAWER_INSIDE_ADD_TEXT = 'editor--drawer-inside-add-text';
const DRAWER_FRONT_ADD_PHOTO = 'editor--drawer-front-add-photo';
const DRAWER_INSIDE_ADD_PHOTO = 'editor--drawer-inside-add-photo';

/**
 * Get the class name for the editor overlap style
 * @param styles - The styles object
 * @param isTextDrawerOpen - The state of the text drawer
 * @param activeCanvasObject - The active canvas object
 * @param isMobile - The state of the mobile view
 * @param isFrontFace - The state of the front face
 * @param isImageUploadDrawerOpen - The state of the image upload drawer
 * @param isImageUploadToPhotoZoneOpen - The state of the image upload to photo zone
 * @returns The class name
 */

export const getClassEditorOverlapStyle = (
  styles: Record<string, string>,
  isTextDrawerOpen: boolean,
  activeCanvasObject: CanvasObject | null | undefined,
  isMobile: boolean,
  isFrontFace: boolean,
  isImageUploadDrawerOpen: boolean,
  isImageUploadToPhotoZoneOpen: boolean,
  textEditorDrawerType: TextEditorDrawerType | undefined,
  isDynamicTextboxesFeatureFlagOn: boolean,
) => {
  let editorClass = styles['editor'];

  const isAddTextConditionMet =
    isTextDrawerOpen &&
    activeCanvasObject &&
    activeCanvasObject.data?.type !== CanvasDataTypes.EditableText &&
    !isMobile &&
    isDynamicTextboxesFeatureFlagOn &&
    textEditorDrawerType === TextEditorDrawerType.Side;

  const isAddPhotoConditionMet = (isImageUploadDrawerOpen || isImageUploadToPhotoZoneOpen) && !isMobile;

  /** This function opens the Text Drawer */
  if (isAddTextConditionMet) {
    editorClass += ` ${styles[isFrontFace ? DRAWER_FRONT_ADD_TEXT : DRAWER_INSIDE_ADD_TEXT]}`;
  }

  /** This function opens the Photo Drawer */
  if (isAddPhotoConditionMet) {
    editorClass += ` ${styles[isFrontFace ? DRAWER_FRONT_ADD_PHOTO : DRAWER_INSIDE_ADD_PHOTO]}`;
  }

  return editorClass;
};
