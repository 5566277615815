import { useCardContext } from '../context/card-context';
import { CardFaceData } from '../global-types';

export const useCardFaceIsEditable = (): Pick<
  CardFaceData,
  'isEditable' | 'canAddPhoto' | 'canAddText' | 'type' | 'zones' | 'texts'
> => {
  const {
    cardState: { cardFacesList, activeCardIndex },
  } = useCardContext();
  const face = cardFacesList[`${activeCardIndex}`];

  if (!face) {
    return {
      isEditable: false,
      canAddPhoto: false,
      canAddText: false,
      type: '',
      zones: [],
      texts: [],
    };
  }
  const { canAddPhoto, canAddText, isEditable, type, zones, texts } = face;
  return { canAddPhoto, canAddText, isEditable, type, zones, texts };
};
