import API from '@ce-digital/api-middleware';
import { AddToCartBodyParam } from '../context/app-context/app-context-types';
import { MockCartUtility } from '../global-types';

const PODService = new API();
PODService.updateDefaultConfig('withCredentials', true);

export const mockAddToCartBody: AddToCartBodyParam = {
  pid: '2PGM1207',
  quantity: '1',
  projectID: '5bfa7d12-5547-417f-b583-ffd5c152ffaa',
  fulfillmentProductTypeCode: 'S', // Sign and Send --- probably returns an error at the moment
  shippingOptionCode: 'S', // Only S - Standard for now
  firstName: 'Mock',
  lastName: 'User',
  companyName: '',
  addressLine1: '2501 McGee St',
  addressLine2: '',
  city: 'Kansas City',
  stateCode: 'MO',
  zip: '64108',
  countryCode: 'US',
  scanCode: '0006827491',
  thumbnailImageURL: '',
};

const mockBasketResponse = {
  basket_line_items_count: 1,
  basket_subtotal: 4.99,
  line_item_added: {
    id: '2PGM1207',
    title: 'Personalized Full Photo Birthday Photo Card, 5x7 Vertical',
    image_url:
      'https://hmklabs-dotcom-stage-us-west-2-render-output.s3.amazonaws.com/5bfa7d12-5547-417f-b583-ffd5c152ffaa/a053ae75-30fb-4b30-a46d-b4cb45818ee1-1830050276_thumbnail.png',
    quantity: 1,
    price: 4.99,
    discount: 0,
    isSignSend: true,
    isSignSendEligible: true,
    createSignSendURL: '',
    lineItemUUID: '',
  },
};

const addProductUrl = process.env.REACT_APP_ADD_PRODUCT_URL;

export const mockCartUtility: MockCartUtility = {
  addToCart: () => {
    // Formatting request with querystring to prevent CORS errors
    PODService.post(addProductUrl, jsonToQueryString(mockAddToCartBody))
      .then(() => {
        console.log('open dialog with the following response', mockBasketResponse); //eslint-disable-line
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('error message', error.message);
      });
  },
  basketError: false,
  closeModalOverride: null,
  keepShoppingOverride: null,
  loading: false,
  recentBasket: mockBasketResponse,
  basket: '1',
  setBasket: () => console.log('trying to set basket'), // eslint-disable-line
};

const jsonToQueryString = (json) => {
  return (
    '?' +
    Object.keys(json)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]); // eslint-disable-line
      })
      .join('&')
  );
};
