import { GetStartFlowOptions } from '../analytics-context-types';
import { getModalTag, getPODProductTag } from './utils';

export const getStartFlowTag = (options: GetStartFlowOptions) => {
  const startFlowModalEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
    {
      eventInfo: {
        eventName: 'Flow Start',
        eventAction: options.eventAction,
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const { modal, user } = getModalTag(options.productType, 'Preview Mode', startFlowModalEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
