import { fabric } from 'fabric';
import { CardType, CustomFabricObject, ProjectTypeCode } from '../../../global-types';
import { ImageEditDrawerModes } from '../../app-context/app-context-types';

export const createCroppingArea = (
  currentImage: CustomFabricObject | null,
  canvas: React.MutableRefObject<fabric.Canvas>,
  croppingArea: React.MutableRefObject<fabric.Rect | undefined>,
  project_type_code: ProjectTypeCode | undefined,
  cropDeselectionHandler: () => void,
) => {
  if (!currentImage) {
    return;
  }

  if (currentImage && typeof currentImage.fire === 'function') {
    currentImage.fire('custom:hideBorders');
  }

  const { height, width, left, top, angle, scaleX, scaleY } = currentImage;
  if (left !== undefined && top !== undefined) {
    const zoneSize = {
      width: (width as number) * (scaleX as number),
      height: (height as number) * (scaleY as number),
    };

    const croppingRect = new fabric.Rect({
      fill: '',
      originX: 'left',
      originY: 'top',
      width: zoneSize.width - 1,
      height: zoneSize.height - 1,
      visible: true,
      opacity: 1,
      left,
      top,
      angle,
      borderColor: '#ED3E6C',
      borderDashArray: [5, 7],
      borderOpacityWhenMoving: 1,
      hasBorders: true,
      name: 'cropping-rect',
      type: ImageEditDrawerModes.UserImage,
      data: {
        type: ImageEditDrawerModes.UserImage,
      },
    });

    const clipPath = new fabric.Rect({
      width: zoneSize.width,
      height: zoneSize.height,
      left,
      top,
      absolutePositioned: true,
      angle,
    });

    croppingRect.clipPath = clipPath;

    currentImage.opacity = 1;
    // Make other objects unselectable/uneventable until cropping is finished, keep track of original values
    const canvasObjects = canvas.current.getObjects();
    canvasObjects.forEach((obj) => {
      if (obj.selectable === true) {
        obj.selectable = false;
        obj.data.isSelectable = true;
      }
      if (obj.evented === true) {
        obj.evented = false;
        obj.data.isEvented = true;
      }
    });

    canvas.current.add(croppingRect);
    canvas.current.setActiveObject(croppingRect);
    croppingRect.bringToFront();

    canvas.current.on('selection:cleared', cropDeselectionHandler);

    croppingArea.current = croppingRect;
    if (project_type_code === CardType.SAS) {
      croppingArea.current.setControlsVisibility({ mtr: false });
      croppingArea.current.set({ cornerSize: 28 });
    }
  }
};
