import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@hallmark/web.core.feedback.dialog';
import { DialogIconNames, DialogContentType, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { BrandColors } from '@hallmark/web.styles.colors';
import { useInitializationDataContext } from '../../context/data-context';
import { ActionButton } from './fragments/action-button';
import { CancelButton } from './fragments/cancel-button';
import { SystemFailureDialogProps } from './system-failure-dialog-types';

export const SystemFailureDialog = ({ actionButton, cancelButton, onClose, isOpen }: SystemFailureDialogProps) => {
  const { t } = useTranslation();
  const { initializedDataState } = useInitializationDataContext();
  const { isUK } = initializedDataState;

  return (
    <Dialog
      id={'system-failure-dialog'}
      isOpen={isOpen}
      onClose={onClose}
      closeButtonId={'close-button'}
      accentIcon={DialogIconNames.CardFeaturesBold}
      accentIconColor={BrandColors.White}
      hasGrayBackground={true}
      type={DialogContentType.Modal}
      testId="system-failure-dialog"
      title={t('systemFailureDialog.title') as string}
      footerType={DialogFooterType.Cancel}
      disableDialogScroll={true}
      actionButton={<ActionButton click={actionButton} />}
      cancelButton={<CancelButton click={cancelButton} />}
      locale={isUK ? 'uk' : 'us'}
    >
      <p>{t('systemFailureDialog.message')} </p>
    </Dialog>
  );
};
