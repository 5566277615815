import { useInitializationDataContext } from '../context/data-context';
import { useQueryParams } from './useQueryParams';

/**
 *  Hook that returns a boolean to check if the project is 1:Many
 * Conditions for 1:M
 * - Card should be flat (2 panel card)
 * - qty query parameter should be defined
 *
 * @returns true if the project is 1:Many
 */
export const useIsOneToMany = () => {
  const queryParams = useQueryParams();
  const quantity = Number(queryParams.get('qty'));
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const isFlatCard = initializedData?.variables?.template_data.Faces.length === 2;

  return isFlatCard && quantity >= 1;
};
