import { helperSettingsConfig } from '../../../utils';

const { defaultUserZoneInstructionsSize, smallUserZoneInstructionsSize, userZoneScaleBreakpoint } =
  helperSettingsConfig;

/**
 * Gets editable area instructions font size based on zone width and height
 * Returns either default or small font size
 *
 * @param zone - Editable area object
 * @returns number
 */
export const getEditableAreaInstructionsSize = (zone: fabric.Object) => {
  if (zone && zone.width && zone.height) {
    if (zone.width < userZoneScaleBreakpoint || zone.height < userZoneScaleBreakpoint) {
      return smallUserZoneInstructionsSize;
    }
  }
  return defaultUserZoneInstructionsSize;
};
