import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@hallmark/web.core.buttons.button';
import { config } from '../../../../regional-config';
import { FooterContentProps } from '../scale-drawer-types';
import styles from '../scale-drawer.module.scss';

export const FooterContent = ({ onCancel, onApply }: FooterContentProps): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={styles['footer-wrapper']}>
      {config?.scaleDrawer?.hasCancelButton && (
        <Button
          addClass={classNames(styles['footer-buttons'])}
          click={onCancel}
          mode={config?.scaleDrawer?.cancelButtonMode}
        >
          {t('footerContent.cancel')}
        </Button>
      )}
      {config?.scaleDrawer?.hasActionButton && onApply && (
        <Button
          addClass={classNames(styles['footer-buttons'])}
          mode={config?.scaleDrawer?.actionButtonMode}
          click={onApply}
        >
          {t('footerContent.apply')}
        </Button>
      )}
    </div>
  );
};
