import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCardContext } from '../../../context/card-context';
import { CanvasDataTypes } from '../../../utils';

export const useCardZoneValidation = () => {
  const { t } = useTranslation();
  const {
    cardState: { cardFacesList },
  } = useCardContext();

  const checkMandatoryTextFields = (face) => {
    const textObjects = face.canvas.current._objects.filter(
      (obj) => obj.type === 'textbox' && obj.isModified !== undefined,
    );

    return textObjects.every((obj) => obj.isModified);
  };

  const checkEditableTextAreas = (face) => {
    const editableTextObjects = face.canvas.current._objects.filter(
      (obj) => obj.data?.type === 'editable-text' && obj.isModified !== undefined,
    );

    return editableTextObjects.every((obj) => obj.isModified && obj.text.trim().length > 0);
  };

  const checkMandatoryPhotoZones = (face) => {
    const photoZoneObjects = face.canvas.current._objects.filter(
      (obj) => obj.name && obj.name.startsWith('photozone-') && obj.data?.hasContent !== undefined,
    );

    const allZonesFilled = photoZoneObjects.every((zone) => zone.data.hasContent === true);

    return allZonesFilled;
  };

  const checkEditableAreas = (face) => {
    if (!face.editableAreas || face.editableAreas.length === 0) {
      return true;
    }

    const editableAreaObjects = face.canvas.current._objects.filter((obj) => obj.name?.startsWith('area-'));

    return editableAreaObjects.every((obj) => obj.isModified);
  };

  const hasRelevantObjects = (face) => {
    const relevantTypes = [
      CanvasDataTypes.UserText,
      CanvasDataTypes.StickerImage,
      CanvasDataTypes.UserImage,
      CanvasDataTypes.Placeholder,
    ];

    return face.canvas.current._objects.some((obj) => relevantTypes.includes(obj?.data?.type));
  };

  const hasUneditedPlaceholderText = (face) => {
    const placeholderObjects = face.canvas.current._objects.filter(
      (obj) => obj.type === 'textbox' && obj.data?.type === 'placeholder' && obj.text === 'Schrijf je bericht',
    );

    return placeholderObjects.length > 0;
  };

  const isFaceEdited = (face) => {
    if (!face || !face.canvas || !face.canvas.current || !face.canvas.current._objects) {
      return false;
    }

    const textFieldsEdited = checkMandatoryTextFields(face);
    const photoZonesFilled = checkMandatoryPhotoZones(face);
    const editableAreasEdited = checkEditableAreas(face);
    const editableTextEdited = checkEditableTextAreas(face);

    return (
      textFieldsEdited &&
      photoZonesFilled &&
      editableAreasEdited &&
      editableTextEdited &&
      !hasUneditedPlaceholderText(face)
    );
  };

  const validateZones = useCallback(
    (userAttemptedProceed) => {
      const frontFace = cardFacesList.find((face) => face.type === 'front');
      const insideFace = cardFacesList.find((face) => face.type === 'inside');
      const backFace = cardFacesList.find((face) => face.type === 'back');

      const isFrontEdited = frontFace ? isFaceEdited(frontFace) : true;
      const isInsideEdited = insideFace?.canvas.current ? isFaceEdited(insideFace) : false;
      const isBackEdited = backFace?.canvas.current ? isFaceEdited(backFace) : true;

      const hasInsideObjects = insideFace?.canvas.current ? hasRelevantObjects(insideFace) : false;
      const hasBackObjects = backFace?.canvas.current ? hasRelevantObjects(backFace) : true;

      if (!isFrontEdited && !(isInsideEdited || isBackEdited)) {
        return {
          canProceed: false,
          showPopup: false,
          showToast: !userAttemptedProceed,
          toastMessage: {
            title: t('zoneValidation.toast.title'),
            description: t('zoneValidation.toast.description'),
          },
          popupMessage: {
            title: t('zoneValidation.halfWay.title'),
            description: t('zoneValidation.halfWay.description'),
          },
        };
      } else if (!isFrontEdited && (isInsideEdited || isBackEdited)) {
        return {
          canProceed: false,
          showPopup: userAttemptedProceed,
          showToast: !userAttemptedProceed,
          toastMessage: {
            title: t('zoneValidation.toast.title'),
            description: t('zoneValidation.toast.description'),
          },
          popupMessage: {
            title: t('zoneValidation.halfWay.title'),
            description: t('zoneValidation.halfWay.description'),
          },
        };
      } else if (isFrontEdited && (!isInsideEdited || !hasInsideObjects) && (!isBackEdited || !hasBackObjects)) {
        return {
          canProceed: userAttemptedProceed,
          showPopup: !userAttemptedProceed,
          showToast: false,
          toastMessage: { title: '', description: '' },
          popupMessage: {
            title: t('zoneValidation.halfWay.title'),
            description: t('zoneValidation.halfWay.description'),
          },
        };
      } else if (isFrontEdited && (isInsideEdited || isBackEdited)) {
        return {
          canProceed: true,
          showPopup: true,
          showToast: false,
          toastMessage: { title: '', description: '' },
          popupMessage: {
            title: t('zoneValidation.ready.title'),
            description: t('zoneValidation.ready.description'),
          },
        };
      } else {
        return {
          canProceed: false,
          showPopup: false,
          toastMessage: {
            title: t('zoneValidation.error.title'),
            description: t('zoneValidation.error.description'),
          },
          popupMessage: { title: '', description: '' },
        };
      }
    },
    [cardFacesList],
  );

  return { validateZones };
};

export default useCardZoneValidation;
