import { useMemo } from 'react';
import { useInitializationDataContext } from '../../../context/data-context';
import { AddressTypes } from '../../../global-types';
import { useQueryParams } from '../../../hooks';

/**
 * Custom hook that checks for the query parameter "envelope" and based on this value it returns the address steps.
 * Also handles i18n. For example, for UK only recipient address should be fulfilled
 * @returns An array containing the steps (addresses) that need to be fulfilled in address view
 */
export const useEnvelopeAddresses = () => {
  const queryParams = useQueryParams();
  const envelope = queryParams.get('envelope');
  const {
    initializedDataState: { isUK },
  } = useInitializationDataContext();

  const addressSteps = useMemo(() => {
    if (isUK) {
      return [AddressTypes.RECIPIENT];
    }
    if (envelope === 'return') {
      return [AddressTypes.SENDER];
    }
    if (envelope === null || envelope === 'return-recipient') {
      return [AddressTypes.RECIPIENT, AddressTypes.SENDER];
    }

    return [];
  }, [envelope, isUK]);

  return addressSteps;
};
