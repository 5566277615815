import { useCallback } from 'react';
import { useCardContext } from '../context/card-context';
import { useInitializationDataContext } from '../context/data-context';
import { CardFaceData, CardType } from '../global-types';
import { bleedInPixels, getBleed } from '../utils';

/**
 * Some types of cards need some adjustment in their dimensions. This custom hooks gets the card information from the card-context and returns to functions to get
 * the correct dimension 'removing' the bleed.
 * Use the hook to scale canvas and it backgroundImage correctly.
 * @returns An object containing getCanvasDimensionsWithBleed and getImageDimensionsWithBleed
 */
export const useDimensionsWithBleed = () => {
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { cardState } = useCardContext();
  const projectTypeCode = initializedData?.project_type_code;

  /**
   * Removes the bleed from the canvas, depending on the card type
   *
   * @param cardFace Card face data from the card-context. Element of the array cardFacesList.
   * @returns Object containing width and height of canvas with the bleed removed
   */
  const getCanvasDimensionsWithBleed = useCallback(
    (cardFace: CardFaceData) => {
      const bleed =
        projectTypeCode === CardType.SAS ||
        cardState.isChocolate ||
        (cardState.isPostcard && !cardState.isPostcardFromPhoto)
          ? 0
          : bleedInPixels();

      return {
        width: cardFace.dimensions.width - bleed * 2,
        height: cardFace.dimensions.height - bleed * 2,
      };
    },
    [cardState.isChocolate, cardState.isPostcard, cardState.isPostcardFromPhoto, projectTypeCode],
  );

  /**
   * Removes the bleed from the card face image by hiding it (translating the card -bleed)
   * there are 3 exceptions:
   * 1 - chocolate cards: the bleed area is not removed
   * so that the chocolate border will be visible
   * 2 - simple paper photo cards front: this will affect the bleed of the background image
   * the one with the placeholder for the images (the one on the front)
   * the uploader does not add bleed to that image, so we need to not remove it so that the photo zones will match the placeholders
   * 3 - postcards: the postcards come with no bleed (both front and back)
   *
   * @param cardFace Card face data from the card-context. Element of the array cardFacesList.
   * @returns Object containing width, height, left, top, originX and originY to be used as fabric.Image settings. The left and right removes the bleed of the card and translates the card -bleed
   */
  const getImageDimensionsWithBleed = useCallback(
    (cardFace: CardFaceData) => {
      const bleed = getBleed({ projectTypeCode, cardState, faceType: cardFace.type });
      // Round Math.ceil so that background image is always a whole number for the image resizer.
      const roundedWidth = Math.ceil(cardFace.dimensions.width);
      const imageHeight = roundedWidth - cardFace.dimensions.width + cardFace.dimensions.height;
      return {
        width: roundedWidth,
        height: imageHeight,
        left: -bleed,
        top: -bleed,
        originX: 'left',
        originY: 'top',
      };
    },
    [cardState.isChocolate, cardState.isPostcard, cardState.isPostcardFromPhoto, projectTypeCode],
  );

  return { getCanvasDimensionsWithBleed, getImageDimensionsWithBleed };
};
