import { useInitializationDataContext } from '../context/data-context';
import { CardType } from '../global-types';

/**
 *  Hook that returns a boolean to check if the project is S&S
 * Conditions for S&S
 * - initializedData.project_type_code should be equal to 'S'
 *
 * @returns true if the project is S&S
 */

export const useIsSignAndSend = () => {
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();

  return initializedData?.project_type_code === CardType.SAS;
};
