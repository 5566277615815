import { DefaultProductOptions } from '../analytics-context-types';
import { getPODModalTag, getPODProductTag } from './utils';

export const getClickAddressRecipientTag = (options: DefaultProductOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );
  const { modal, user } = getPODModalTag('Address the Envelope - Recipient', [], options.projectID);

  return {
    modal,
    product,
    user,
  };
};
