import { BrandColors, ShadeColors } from '@hallmark/web.styles.colors';

export const basicTextColors: string[] = [
  BrandColors.Black, // Default US text color
  ShadeColors.DarkCharcoal, // Default UK text color
  ShadeColors.Gray,
  ShadeColors.RoyalBlue,
  ShadeColors.DarkTurquoise,
  ShadeColors.BrightPurple,
  ShadeColors.Ivory,
];

export const extendedTextColors: string[] = basicTextColors.concat([
  ShadeColors.RustRed,
  ShadeColors.LightRose,
  ShadeColors.White1,
  ShadeColors.Orange,
  ShadeColors.BrightYellow,
  ShadeColors.DijonYellow,
  ShadeColors.Gold,
  ShadeColors.Tan,
  ShadeColors.ForestGreen,
  ShadeColors.WatercourseGreen,
  ShadeColors.Lime,
  ShadeColors.SageGreen,
  ShadeColors.Mint,
  ShadeColors.Navy,
  ShadeColors.TealBlue,
  ShadeColors.AquamarineBlue,
  ShadeColors.DarkPurple,
  ShadeColors.DustyLilac,
  ShadeColors.BlushPink,
  ShadeColors.Maroon,
  ShadeColors.Red,
  ShadeColors.MagentaPink,
  ShadeColors.BubbleGumPink,
  ShadeColors.CoralPink,
]);
