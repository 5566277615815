import colorsList from '../../../../styles/util.scss';
import { getGroupedTextObject } from '../../../../utils';

/**
 * Changes the fill color of a text object on a canvas.
 *
 * @param {string} color - The fill color to be applied to the text.
 * @param {React.RefObject<Canvas> | undefined} canvas - The reference to the canvas where the text object is located.
 * @param {function} callback - The callback function.
 * @returns {void}
 */
export const onChangeTextColor = (color, canvas, callback) => {
  const textObject = getGroupedTextObject(canvas?.current);

  if (textObject) {
    const colorHexFill = colorsList[`${color}`];

    textObject.setOptions({ fill: colorHexFill });
    canvas?.current?.renderAll();
    textObject.fire('changed');
    callback(color);
  }
};
