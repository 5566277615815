import API from '@ce-digital/api-middleware';
import axios from 'axios';
import { initializeResponse, digitalOrdersMock } from '../data';
import {
  InitBody,
  InitializationData,
  DigitalOrderRequestBody,
  ConfirmProjectDetailsRequestBody,
} from '../global-types';
import { ApiResponse } from '../global-types/services';
import { getCookie } from '../utils';
import { TransformedPersonalizationData } from '../utils/helper-settings';
import { processResponse } from './middleware';

const PODService = new API();
const mockResponse = process.env.REACT_APP_MOCK_INITIALIZATION_SERVICE === 'true';
const accountId = getCookie('accountId');

export const initialize = (initBody: InitBody): Promise<ApiResponse<InitializationData>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<InitializationData> = initializeResponse;
      resolve(response);
    } else {
      const response = PODService.post('/customization', `/${accountId}/initialize`, initBody);
      processResponse(response, resolve, reject);
    }
  });
};

export const loadProject = (projectId: string): Promise<ApiResponse<InitializationData>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const response: ApiResponse<InitializationData> = initializeResponse;
      resolve(response);
    } else {
      const response = PODService.get('/customization', `/${accountId}/${projectId}/load`);
      processResponse(response, resolve, reject);
    }
  });
};

export const migrateProjects = (guestAccountId: string, ...projectIds: string[]) => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const responseMock = {
        meta: initializeResponse.meta,
        data: projectIds.map((id) => ({ project_id: id, status: false })),
      };
      resolve(responseMock);
    } else {
      const requestBody = {
        guest_account_id: guestAccountId,
        project_ids: projectIds,
      };
      const response = PODService.patch('/customization', `/${accountId}/migrate`, requestBody);
      processResponse(response, resolve, reject);
    }
  });
};

export const addProjectToCart = (projectId: string): Promise<ApiResponse<Record<string, never>>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const responseMock = {
        meta: initializeResponse.meta,
        data: {},
      };
      resolve(responseMock);
    }
    const response = PODService.patch('/customization', `/${accountId}/${projectId}/add-to-cart`);
    processResponse(response, resolve, reject);
  });
};

export const savePersonalization = (
  formData: TransformedPersonalizationData[],
  projectId: string,
): Promise<ApiResponse<Record<string, never>>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const responseMock = {
        meta: initializeResponse.meta,
        data: {},
      };
      resolve(responseMock);
    }
    const response = PODService.patch('/customization', `/${accountId}/${projectId}/save-personalization`, formData);
    processResponse(response, resolve, reject);
  });
};

export const updateDraftName = (
  draftName: string,
  projectId: string,
  productQuantity: string,
  isDraft: boolean,
): Promise<ApiResponse<Record<string, never>>> => {
  const formData = {
    name: draftName,
    is_draft: isDraft,
    purchase_quantity: Number(productQuantity),
  };

  return new Promise((resolve, reject) => {
    if (mockResponse) {
      const responseMock = {
        meta: initializeResponse.meta,
        data: {},
      };
      resolve(responseMock);
    }
    const response = PODService.patch('/customization', `/${accountId}/${projectId}/save-draft`, formData);
    processResponse(response, resolve, reject);
  });
};

/**
 *
 * @param requestBody
 * @returns JSON containing 'order_reference_id' field
 * which is used as the value for 'order_number' field
 * in PATCH call to confirm endpoint.
 */
export const sendDigitalOrder = (
  requestBody: DigitalOrderRequestBody,
): Promise<ApiResponse<Record<string, string>>> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve(digitalOrdersMock);
      return;
    }

    const response = PODService.post('/order-management', `/${accountId}/digital-orders`, requestBody);
    processResponse(response, resolve, reject);
  });
};
/**
 * Call to shop FE API to retrieve profile data
 * @returns JSON response object containing profile fields
 */
export const fetchAccountProfile = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const baseUrl = process.env.REACT_APP_MY_HALLMARK_URL;
    const callUrl = `${baseUrl}/api/accounts/getProfile`;
    const options = {
      withCredentials: true,
    };

    axios.get(callUrl, options).then(
      (response) => {
        const responseBody = response.data.body || undefined;
        resolve(responseBody);
      },
      (err) => {
        reject(err);
      },
    );
  });
};

/**
 * Patch call to confirm order has been sent
 * @param requestBody
 * @returns nothing
 * Request returns no response. Fire & forget.
 */
export const confirmProjectData = (requestBody: ConfirmProjectDetailsRequestBody): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      resolve();
      return;
    }

    const response = PODService.patch('/customization', `/${accountId}/confirm`, requestBody);
    processResponse(response, resolve, reject);
  });
};

/**
 * Final processing of DG orders
 * @param orderRequestBody
 * @param confirmRequestBody
 * @returns nothing
 */
export const finalizeDigitalOrder = async (
  orderRequestBody: DigitalOrderRequestBody,
  confirmRequestBody: ConfirmProjectDetailsRequestBody,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (mockResponse) {
      return;
    }

    PODService.validateTokens().then(
      async () => {
        const profileData = await fetchAccountProfile();
        // customer_crown_card_number: '', // Keep blank for now
        // enterprise_consumer_id: '', // Keep blank for now

        if (profileData !== undefined) {
          const finalOrderRequestBody = {
            ...orderRequestBody,
            customer_crown_card_number: profileData.CRNumber.toString(),
            enterprise_consumer_id: profileData?.CID?.toString() || '',
          };

          // Send digital order
          const orderData = await sendDigitalOrder(finalOrderRequestBody);
          const finalConfirmBody = {
            ...confirmRequestBody,
            order_number: orderData.data?.order_reference_id || '',
          };

          // Confirm project data
          await confirmProjectData(finalConfirmBody);
          resolve();
        } else {
          reject();
        }
      },
      () => {
        // Either JWT refresh token is also expired, or tokens are guest tokens
        reject();
      },
    );
  });
};
