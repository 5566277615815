import { RegionalCodesList } from '../../../global-types';
import { helperSettingsConfig } from '../../../utils';
import { isAnyOfRegions } from '../../../utils/utility';

const { userZoneScaleBreakpoint, largeUserZoneButtonSize } = helperSettingsConfig;

/**
 * Scales editableArea buttons so that they don't exceed the bounds of the user zone
 * Note: We've told DME that user zones will support a min width/height of 500
 *
 * @param canvas - using width, scale buttons to ensure they can all fit within the zone
 * @returns fabric.Object (button)
 */
export const scaleEditableAreaButton = (button: fabric.Object, editableArea: fabric.Object): fabric.Object => {
  if (isAnyOfRegions[(RegionalCodesList.us, RegionalCodesList.ce)]) {
    // As of now, only UK is scaling button size
    return button;
  }
  if (editableArea.width) {
    if (editableArea.width > userZoneScaleBreakpoint) {
      // If width > breakpoint of 500, scale buttons up (UK wants large buttons)
      button.scaleToHeight(largeUserZoneButtonSize);
    } else {
      // if width is less than 500, scale buttons down to ensure they can all fit within the zone
      // 6 is a "magic number" to get buttons small enough
      const scaleToHeight = editableArea.width / 6;
      button.scaleToHeight(scaleToHeight);
    }
  }
  return button;
};
