import { DataLayerObject } from '@hallmark/web.page-components.datalayer';

export const clearDataLayerEvents = () => {
  const event = [];
  DataLayerObject({ dataObj: JSON.stringify({ event }), keysToDelete: ['event'] });
};

export const clearDataLayerModalEvents = () => {
  const modal = {
    event: [],
  };
  DataLayerObject({ depth: 'modal', dataObj: JSON.stringify(modal), keysToDelete: ['modal.event'] });
};

export const clearDataLayerModalLevels = () => {
  const modal = {
    level1: '',
    level2: '',
  };
  DataLayerObject({ depth: 'modal', dataObj: JSON.stringify(modal), keysToDelete: ['modal.level1', 'modal.level2'] });
};
