import { GetUploadPhotoOptions, ModalEventType } from '../analytics-context-types';
import { getModalTag, getPODProductTag } from './utils';

export const getUploadPhotoTag = (options: GetUploadPhotoOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );
  const photos: ModalEventType[] = [];
  options.uploadedPhotos.forEach((imageSize) =>
    photos.push({
      eventInfo: {
        eventName: 'photo upload',
        eventAction: `${imageSize}`,
      },
    }),
  );

  const uploadPhotoModalEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
    ...photos,
  ];

  const { modal, user } = getModalTag(options.productType, 'Edit Mode', uploadPhotoModalEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
