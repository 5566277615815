import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { useCardContext } from '../../../context/card-context';
import { useCropContext } from '../../../context/crop-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { useFeatureFlags } from '../../../hooks';
import { useEmailOrderProcess } from '../../../hooks/useEmailOrderProcess';
import { useHandleEditingDone } from '../../../hooks/useHandleEditingDone';
import { useHandleIndexChange } from '../../../hooks/useHandleIndexChange';
import { getCardFaceLabel } from '../../../utils';
import { useUserZonesValidation } from './useUserZonesValidation';

export const useEditorNavigation = () => {
  const { t } = useTranslation();
  const { SKIP_PREVIEW_STEP } = useFeatureFlags();
  const { isSavingCroppedImage } = useCropContext();

  const { cardState } = useCardContext();

  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();

  const { isSendingEmail } = useEmailOrderProcess();
  const { pageNavigationButtonClicked } = useAnalyticsContext();
  const { validateUserZones } = useUserZonesValidation();

  const slideIndex = cardState.slideCardIndex;

  const editableFaces = useMemo(
    () => cardState.cardFacesList.filter((face) => face.editorDisplayIndicator),
    [cardState.cardFacesList],
  );
  const handleEditingDone = useHandleEditingDone();
  const handleIndexChange = useHandleIndexChange({ savingCard: true });

  const isOnLastFace = slideIndex === editableFaces.length - 1;

  const handleIncrementSlideIndex = () => {
    pageNavigationButtonClicked.current = 'Edit Button';
    const newActiveCardIndex = editableFaces[slideIndex + 1].faceNumber - 1;
    handleIndexChange(slideIndex + 1, newActiveCardIndex);
  };

  const getNextButtonText = () => {
    const nextFace = editableFaces[slideIndex + 1];

    if (nextFace) {
      return t('editorView.nextFace', { face: getCardFaceLabel(nextFace.type) });
    }

    return t('editorView.lastFace');
  };

  const previewProps = {
    click: isOnLastFace ? handleEditingDone : handleIncrementSlideIndex,
    children: getNextButtonText(),
    disabled:
      isOnLastFace && initializedData?.project_type_code === 'S' ? !validateUserZones() || isSavingCroppedImage : false,
  };

  const withoutPreviewProps = {
    click: handleEditingDone,
    children: getNextButtonText(),
    disabled: isSendingEmail,
  };

  const buttonProps = SKIP_PREVIEW_STEP ? withoutPreviewProps : previewProps;

  return { buttonProps, getNextButtonText, handleIncrementSlideIndex };
};
