import { DefaultProductOptions } from '../analytics-context-types';
import { getPODModalTag, getPODProductTag } from './utils';

export function getInitializeEditTag(options: DefaultProductOptions) {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const initializeEditModal = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: 'outside-front', // "outside-back","inside-left", "inside-right", inside-top", OR "inside-bottom"
      },
    },
    {
      eventInfo: {
        eventName: 'text', // "photo", "sticker", OR "message",
        eventAction: 'start',
      },
    },
  ];

  const { modal, user } = getPODModalTag('Edit Mode', initializeEditModal, options.projectID);

  return { modal, product, user };
}
