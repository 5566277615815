import { CardFaceData } from '../../../global-types';
import { getObjectsByType, CanvasDataTypes } from '../../../utils';
import { removeEditableAreaButtons } from './remove-editable-area-buttons';

/**
 * On POD cards, remove editable areas (user zones) from canvas before saving the project
 *
 * @param {CardFaceData[]} cardFaces array containing all faces of a card
 */
export const removeEditableAreas = (cardFaces: CardFaceData[]) => {
  cardFaces.forEach((face) => {
    if (face.editableAreas && face.canvas.current) {
      getObjectsByType(face.canvas.current, CanvasDataTypes.EditableArea).forEach((obj) =>
        face?.canvas?.current?.remove(obj),
      );
      // If removing editableArea, also ensure its buttons are removed
      removeEditableAreaButtons(face.canvas.current);
    }
  });
};
