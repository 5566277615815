import { AddressForm } from '../../global-types';

export const defaultFormValues: Partial<AddressForm> = {
  first_name: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  company_name: '',
  isQuickAddress: false,
  last_name: '',
  state_code: '',
  zip: '',
  send_to: 'them',
};
