import { ImageHeader, ImageDetails } from '../../global-types/images';

/**
 * Checks if the provided file is a valid image by reading its header.
 *
 * This function reads the first 4 bytes of the file to determine the image type.
 * It supports PNG, and various JPEG formats identified by their unique headers.
 *
 * @param {File} file - The file to be checked if it's a valid image.
 * @returns {Promise<ImageDetails>} A promise that resolves to `true` if the file is a valid image, otherwise `false`.
 */
export const isValidImage = (file): Promise<ImageDetails> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = function (e) {
      if (e.target?.result instanceof ArrayBuffer) {
        const arr = new Uint8Array(e.target.result).subarray(0, 4);
        let header = '';
        arr.forEach((byte) => {
          header += byte.toString(16).padStart(2, '0');
        });
        if (Object.values(ImageHeader).includes(header as ImageHeader)) {
          return resolve({
            isValid: true,
            imageHeader: header as ImageHeader,
          });
        } else {
          return resolve({
            isValid: false,
            imageHeader: null,
          });
        }
      }
    };

    reader.readAsArrayBuffer(file.slice(0, 4));
  });
};
