import React from 'react';
import classNames from 'classnames';
import { config } from '../../regional-config';
import styles from './screen-loader.module.scss';
import { ScreenLoaderProps, ScreenLoaderType } from './screen-loader.types';

export const ScreenLoader = ({
  isVisible,
  children,
  type = ScreenLoaderType.Default,
}: ScreenLoaderProps): JSX.Element | null => {
  if (!isVisible) {
    return null;
  }

  const loaderClass = classNames(styles['screen-loader'], {
    [styles.drawer]: type === ScreenLoaderType.Drawer,
  });

  return (
    <div className={loaderClass}>
      <img src={config.screen?.imageLoader} alt="Animated Crown" className={styles['animated-crown']} />
      {children && <div className={styles['screen-loader-content']}>{children}</div>}
    </div>
  );
};
