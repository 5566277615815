/**
 * Reads a file as a a Data URI
 * @param file Takes a file and reads it as a data URI
 * @returns dataURI string
 */
export const readFileAsDataURI = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (ev) => {
      const url = ev.target?.result;
      if (url) {
        resolve(url as string);
      }
      reject(null);
    };
    reader.readAsDataURL(file);
  });
};
