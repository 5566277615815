import { getGroupedTextObject } from '../../../../utils';

/**
 * Restores the original styles of a text object and triggers the 'changed' event.
 * @param {object} initialTextSelected - The initial text object.
 * @param {object} canvas - The canvas object.
 * @param {function} callback - The callback function.
 */
export const onRevertStyles = (initialTextSelected, canvas, callback) => {
  const textObject = getGroupedTextObject(canvas?.current);
  const textAlign = initialTextSelected?.textAlign || 'left';
  const textBoxObjects = canvas?.current?.getObjects();
  const topestPositionIndex = textBoxObjects.length - 1;
  const order = initialTextSelected?.order || topestPositionIndex;

  if (textObject) {
    textObject.moveTo(order);
    textObject.setOptions({ ...initialTextSelected, textAlign });

    canvas?.current?.renderAll();

    textObject.fire('changed');

    callback();
  }
};
