import { fabric } from 'fabric';
import { CustomFabricObject } from '../../../global-types';
import { SetCropAreaAngleProps } from '../crop-context-types';

/**
 * Sets the angle and coordiantes of the cropping area, and returns the totalRotation and currentAngle
 * @returns
 */
export const getCroppingAreaCoords = ({ croppingArea, currentImage }: SetCropAreaAngleProps) => {
  if (!croppingArea.current) {
    throw Error('Unable to crop image: Unable to retrieve cropping area');
  }
  const currentAngle = croppingArea.current?.angle ?? 0;
  const angle = fabric.util.degreesToRadians(Math.abs((croppingArea.current.angle as number) - 360));
  const currentRotation = Math.abs(currentAngle - 360);
  const totalRotation = currentRotation + (currentImage.angle ?? 0);
  (croppingArea.current as CustomFabricObject)._setOriginToCenter();
  const newCoords = fabric.util.rotatePoint(
    croppingArea.current.getCenterPoint(),
    currentImage.getCenterPoint(),
    angle,
  );

  return { currentAngle, totalRotation, newCoords };
};
