import { ApiResponse, EnvelopePreviewResponse, FulfillmentResponse, PreviewResponseData } from '../global-types';

export const envelopPreviewResponse: EnvelopePreviewResponse = {
  meta: {
    code: 201,
    version: '2.2.0',
  },
  data: {
    encoded_image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYcAAAI9CAYAAADRp7TwAAAACXBIWXMAAAsSAAALEgHS3X7.....',
  },
};

export const previewResponse: ApiResponse<PreviewResponseData> = {
  meta: {
    code: 201,
    version: '2.2.0',
  },
  data: {
    encoded_image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYcAAAI9CAYAAADRp7TwAAAACXBIWXMAAAsSAAALEgHS3X7.....',
    image_url:
      'https://s3.amazonaws.com/hmklabs-dotcom-dev-us-west-2-render-output/bd771707-f3fd-4004-9bfa-4496c6b07f33/901b73f2-965b-4064-8136-3cfc8e696c895577006791947779410.png',
  },
};

export const fulfillmentResponse: FulfillmentResponse = {
  meta: {
    code: 200,
    version: '2.2.0',
  },
  data: {
    fulfillment_date: '2021-01-06T00:00:00Z',
    fulfillment_expiration_date: '2021-01-04T16:35:00Z',
    fulfillment_node_id: 83,
    allocation_date: '2021-01-04T00:00:00Z',
    fulfillment_method: 'automated_line1_inkjet',
    delivery_dates: [
      {
        shipping_option_code: 'E',
        shipping_option_description: 'Expedited Shipping',
        carrier_code: 'FEDEX',
        shipping_method_code: '2D',
        earliest_delivery_date: '2021-01-08',
        latest_delivery_date: '2021-01-08',
        arrival_dates: 'Tue. 01/08 - Tue. 01/08',
      },
      {
        shipping_option_code: 'R',
        shipping_option_description: 'Rush Shipping',
        carrier_code: 'FEDEX',
        shipping_method_code: '1D',
        earliest_delivery_date: '2021-01-07',
        latest_delivery_date: '2021-01-07',
        arrival_dates: 'Mon. 01/07 - Mon. 01/07',
      },
      {
        shipping_option_code: 'S',
        shipping_option_description: 'Standard Ground',
        carrier_code: 'USPS',
        shipping_method_code: 'FC',
        earliest_delivery_date: '2021-01-08',
        latest_delivery_date: '2021-01-10',
        arrival_dates: 'Tue. 01/08 - Thu. 01/10',
      },
    ],
  },
};
