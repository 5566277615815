import { GetEnvelopePrintChoiceOptions } from '../analytics-context-types';
import { getModalLevel2, getPODModalTag, getPODProductTag } from './utils';

/**
 * Gets the modal and product tag to track the user's envelope print choice
 *
 * @param options attributes to be included in product tag and modal event tag
 * @returns
 */
export const getEnvelopePrintChoiceTag = (options: GetEnvelopePrintChoiceOptions) => {
  const envelopePrintChoiceEvent = [
    {
      eventInfo: {
        eventName: 'address envelope',
        eventAction: options.eventAction,
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const level2 = getModalLevel2();

  const { modal, user } = getPODModalTag(level2, envelopePrintChoiceEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
