import { RefObject } from 'react';
import { fabric } from 'fabric';
import { hasNoBleed } from '../components/card-editor/utils/utils';
import { CardContextState } from '../context/card-context';
import { CardFace, TemplateData, CardFaceData, CardEditableArea } from '../global-types';
import { helperSettingsConfig } from './configs/helper-settings.configs';

const { scalingFactor, mmInPixels, bleedInMM } = helperSettingsConfig;

type CardStateParams = {
  prevState: CardContextState;
  cardData: TemplateData;
  projectId?: string;
};
/**
 *
 * @param prevState: current card-context state,
 * @returns
 */
export const parseCardState = ({ prevState, cardData, projectId }: CardStateParams): CardContextState => {
  const newCardState: CardContextState = { ...prevState };

  newCardState.cardFacesList = [];
  newCardState.cardFormat = cardData.CardFormat;
  newCardState.cardType = cardData.CardType;
  newCardState.isPostcardFromPhoto = cardData.IsVederePhoto;
  newCardState.lockedFront = cardData.IsFrontOfCardNotEditable;
  newCardState.lockedBack = cardData.IsInsideOfCardNotEditable;
  newCardState.projectId = projectId ?? '';
  newCardState.cardTemplateName = cardData.Name;

  if (newCardState.cardType === 'wooden' || newCardState.cardType === 'woodenphoto') {
    newCardState.isWooden = true;
  } else if (newCardState.cardType === 'chocolate' || newCardState.cardType === 'chocophoto') {
    newCardState.isChocolate = true;
  } else if (newCardState.cardType === 'vedere' || newCardState.cardType === 'vederephoto') {
    newCardState.isPostcard = true;
  }
  return newCardState;
};

type ParseCardFaceParams = {
  face: CardFace;
  index: number;
  currentState: CardContextState;
  canvasRefs: RefObject<fabric.Canvas>[];
};

const createClipPaths = (editableAreas: CardEditableArea[], bleed: number) => {
  const clipPaths: fabric.Rect[] = [];
  editableAreas.forEach((area: CardEditableArea, index: number) => {
    const clipPath = new fabric.Rect({
      name: `clip-path-${index}`,
      width: area.Width / scalingFactor,
      height: area.Height / scalingFactor,
      left: (area.LeftPosition - bleed * mmInPixels) / scalingFactor,
      top: (area.TopPosition - bleed * mmInPixels) / scalingFactor,
      angle: area.Rotation,
      hasRotatingPoint: false,
      absolutePositioned: true,
    });
    clipPaths.push(clipPath);
  });
  return clipPaths;
};
/**
 * Given an ParseCardFaceParams object, returns a CardFace object.
 */
export const parseCardFace = ({ face, currentState, index, canvasRefs }: ParseCardFaceParams): CardFaceData => {
  const bleed = hasNoBleed(currentState) ? 0 : bleedInMM;
  return {
    texts: face.Texts,
    type: face.Type,
    faceId: face.FaceId,
    faceNumber: index + 1,
    photoZoneTemplate: face.FrameUrl,
    zones: face.PhotoZones,
    canvas: canvasRefs[`${index}`],
    userZones: face.UserTextZones && face.UserTextZones.length ? face.UserTextZones : null,
    editableAreas: face.EditableAreas && face.EditableAreas.length ? face.EditableAreas : null,
    clipPaths: createClipPaths(face.EditableAreas, bleed),
    dimensions: {
      width: Math.round(face.Dimensions.Width) / scalingFactor,
      height: Math.round(face.Dimensions.Height) / scalingFactor,
    },
    displayDefaultTextArea: face.DisplayDefaultTextArea,
    backgroundImage: face.BackgroundUrl,
    woodenBackground: currentState.isWooden ? face.ReplaceBackgroundUrl : null,
    chocolateBackground: currentState.isChocolate ? face.ReplaceBackgroundUrl : null,
    postcardBackground: currentState.isPostcard ? face.ReplaceBackgroundUrl : null,
    postcardOverlay: face.OverlayBackgroundUrl ? face.OverlayBackgroundUrl : null,
    active: face.Type === 'front',
    originalCanvasJson: face.CanvasJson,
    photosAddedOnFace: face.UserImages,
    isEditable: face.IsEditable,
    canAddPhoto: face.IsEditable && face.CanAddPhoto,
    canAddText: face.IsEditable && face.CanAddText,
    editorDisplayIndicator: face.DisplayIndicator,
    previewDisplayIndicator: face.PreviewDisplayIndicator,
  };
};

/**
 * Returns total number of user zones
 *
 * @param totalNumber - total number of user zones counted so far
 * @param cardFace - card face data
 * @returns
 */
export const getSumCardFaceZones = (totalNumber: number, cardFace: CardFaceData): number => {
  const { userZones, zones, texts } = cardFace;
  return totalNumber + Number(userZones?.length) + Number(zones.length) + Number(texts.length);
};

/**
 *  This functions gets the first word of the card face type before the dash (-)
 * For example:
 *   - getCardFaceLabel('inside-left') // should return 'inside'
 * @param type type of the card face (e. g. 'front', 'inside-right', 'back')
 * @returns the label of the card face removing the '-' symbol
 */
export const getCardFaceLabel = (type: CardFaceData['type']): string => {
  return type.split('-')[0] ?? '';
};
