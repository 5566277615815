import { RefObject } from 'react';
import { useCardContext } from '../context/card-context';

export const useActiveCanvas = (): RefObject<fabric.Canvas> | undefined => {
  const { cardState } = useCardContext();
  if (cardState.cardFacesList.length <= 0) {
    return;
  }

  const i = cardState.activeCardIndex;
  return cardState.cardFacesList[`${i}`]?.canvas;
};
