import { useEffect, useState } from 'react';
import { DataLayerObject } from '@hallmark/web.page-components.datalayer';
import {
  clearDataLayerEvents,
  clearDataLayerModalEvents,
  clearDataLayerModalLevels,
} from './analytics-context-helpers';
import { getTag } from './analytics-context-tags';
import { TagOptions } from './analytics-context-types';

export const useAnalytics = () => {
  const [dataLayerAvailable, setDataLayerAvailable] = useState(false);

  useEffect(() => {
    const dataLayerCheckTimer = setInterval(() => {
      if (typeof window !== 'undefined' && window.dataLayer?.['siteID'] !== 'undefined') {
        setDataLayerAvailable(true);
      }
    }, 500);
    return () => {
      clearInterval(dataLayerCheckTimer);
    };
  }, []);

  const buildTag = (tagType: string, options?: TagOptions, keysToDelete?: string[]) => {
    const tag = getTag(tagType, options || ({} as TagOptions));
    clearDataLayerEvents();
    clearDataLayerModalEvents();
    clearDataLayerModalLevels();
    DataLayerObject({ dataObj: JSON.stringify(tag), keysToDelete: keysToDelete || [] });
    return tag;
  };

  const track = (trackType = 'page') => {
    if (window && window._satellite && typeof window._satellite.track === 'function') {
      window._satellite.track(trackType);
    }
  };

  return {
    dataLayerAvailable,
    buildTag,
    track,
  };
};
