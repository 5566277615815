import { fabric } from 'fabric';
import { EditableTextSettings } from '../../global-types';

export function createEditableInput(
  settings: EditableTextSettings,
  face: number,
  index: number,
  DYNAMIC_TEXBOXES: boolean,
) {
  const editableInput = new fabric.Textbox(settings.text);
  const zoneId = `userTextbox-${face}-${index}`;

  const extendedSettings: EditableTextSettings & { originalText: string } = {
    ...settings,
    originalText: settings.text,
  };

  editableInput.set(extendedSettings);

  editableInput.set({
    name: zoneId,
    hasControls: DYNAMIC_TEXBOXES,
    data: {
      ...settings.data,
      isEdited: false,
      maxFontSize: settings.fontSize,
      fixedWidth: editableInput.getScaledWidth(),
    },
  });

  return editableInput;
}
