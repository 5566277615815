import { CardFaceData } from '../../../global-types';
import { CanvasDataTypes } from '../../../utils';
import { Zone } from '../editor-types';

/**
 * Function to get text areas that must be mandatorily edited.
 *
 * @param uneditedZones - A list of unedited zones.
 * @param cardFacesList - A list of card faces.
 *
 * @returns An array of zones that must be edited mandatorily.
 */

export const getMandatoryEditTextAreas = (uneditedZones: Zone[], cardFacesList: CardFaceData[]) => {
  return uneditedZones.filter(({ zone }) => {
    // Flattens and maps cardState.cardFacesList to get all texts
    const allTexts = cardFacesList
      .flat()
      .map((face) => face.texts)
      .flat();

    const matchingText = allTexts.find((text) => text.ID === zone.data?.ID);

    // Checks if the type is "editable-text" and if "mustEditTextArea" is true
    return zone.data?.type === CanvasDataTypes.EditableText && matchingText?.MustEditTextArea === true;
  });
};
