import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@hallmark/web.core.buttons.button';
import { config } from '../../../../regional-config';
import { FooterContentProps } from '../rotate-drawer-types';
import styles from '../rotate-drawer.module.scss';

export const FooterContent = ({ onDrawerCancel, onDrawerApply }: FooterContentProps): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={styles['footer-wrapper']}>
      {config?.rotateDrawer?.hasCancelButton && (
        <Button
          testId="rotate-drawer--cancel"
          addClass={styles['footer-cancel-button']}
          click={onDrawerCancel}
          mode={config?.rotateDrawer?.cancelButtonMode}
        >
          {t('footerContent.cancel')}
        </Button>
      )}
      {config?.rotateDrawer?.hasActionButton && onDrawerApply && (
        <Button
          testId="rotate-drawer--apply"
          addClass={styles['footer-primary-button']}
          click={onDrawerApply}
          mode={config?.rotateDrawer?.actionButtonMode}
        >
          {t('footerContent.apply')}
        </Button>
      )}
    </div>
  );
};
