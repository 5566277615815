type ImageResponse = {
  account_id: string;
  created_at: string;
  height?: number;
  image_url: string;
  is_handwriting_image: boolean;
  last_updated_at?: string;
  photo_tray_image_id: string;
  image_reference_id?: string;
  image_id?: string;
  width?: number;
  thumbnail_url?: string;
};

type LinkPhotoResponse = {
  created_at: string;
  image_id: string;
  image_reference_id: string;
  last_updated_at: string;
  image_url: string;
};

type linkPhotoFormData = {
  image_reference_id: string;
};

type cleanUpFormData = {
  source_version_id?: string;
};

type cropAndRotateFormData = {
  x_pos: number;
  y_pos: number;
  width: number;
  height: number;
  rotation: number;
  source_version_id: string;
  image_url: string;
};

type CleanUpImageResponse = {
  version_id: string;
  source_version_id: string;
  version_type_code: string;
  image_url: string;
  attributes: {
    height: number;
    rotation: number;
    width: number;
    x_pos: number;
    y_pos: number;
  } | null;
  created_at: string;
  last_updated_at: string;
};

type CropAndRotateResponse = {
  created_at: string;
  image_url: string;
  last_updated_at: string;
  source_version_id: string;
  version_id: string;
  version_type_code: string;
  attributes: {
    height: number;
    rotation: number;
    width: number;
    x_pos: number;
    y_pos: number;
  };
};

type ImageDetails = {
  isValid: boolean;
  imageHeader: ImageHeader | null;
};

enum ImageType {
  JPEG = 'JPEG',
  PNG = 'PNG',
  GIF = 'GIF',
  HEIC = 'HEIC',
  WEBP = 'WEBP',
}

enum ImageHeader {
  PNG = '89504e47',
  GIF = '47494638',
  JPEG_JFIF = 'ffd8ffe0',
  JPEG_JFIF_ALT = 'ffd8ffdb',
  JPEG_EXIF = 'ffd8ffe1',
  JPEG_CANON = 'ffd8ffe2',
  JPEG_SAMSUNG = 'ffd8ffe3',
  JPEG_SPIFF = 'ffd8ffe8',
}

type AssetStorageResponse = string[];

type MappedFileWithAsset = {
  file: File;
  format: string;
  assetUrl: string;
};

type PhotoTrayImage = {
  image_url: string;
  display_indicator: boolean;
  is_handwriting_image: boolean;
};

type PreviewImage = {
  dataURL: string;
  type: string;
};

type LinkedAsset = {
  asset_type_code: string;
  url: string;
  resize_urls?: {
    M: string;
    S: string;
  };
};

type LinkedAssets = {
  data: LinkedAsset[];
  meta: {
    code: string;
    version: string;
  };
};

enum PreviewAssetType {
  I = 'I',
  F = 'F',
  B = 'B',
}

enum PreviewLabel {
  I = 'Inside',
  F = 'Front',
  B = 'Back',
  IR = 'Inside Right',
  BLANK = '',
}

export type {
  AssetStorageResponse,
  ImageResponse,
  LinkPhotoResponse,
  CleanUpImageResponse,
  CropAndRotateResponse,
  linkPhotoFormData,
  cleanUpFormData,
  cropAndRotateFormData,
  ImageDetails,
  MappedFileWithAsset,
  PhotoTrayImage,
  PreviewImage,
  LinkedAsset,
  LinkedAssets,
};

export { ImageType, ImageHeader, PreviewAssetType, PreviewLabel };
