import { GetSaveLinkOptions } from '../analytics-context-types';
import { getModalLevel2, getModalTag, getPODProductTag } from './utils';

export const getSaveLinkTag = (options: GetSaveLinkOptions) => {
  const optionsLevel2 = ['Preview Mode', 'Edit Mode', 'Preview'];
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const level2 = options.onSplashScreen ? 'Hooray' : getModalLevel2(options.productType);

  const saveLinkEvent = [
    {
      eventInfo: {
        eventName: 'save project',
        eventAction: 'save',
      },
    },
  ];

  if (optionsLevel2.includes(level2)) {
    saveLinkEvent.unshift({
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    });
  }

  const { modal, user } = getModalTag(options.productType, level2, saveLinkEvent, options.projectID);

  return {
    product,
    modal,
    user,
  };
};
