import { fabric } from 'fabric';
import { config } from '../../../regional-config';
import { CustomControlOptions } from '../canvas-custom-config-types';

const middleControlIcon = config?.customControls?.icons?.rectangleIcon ?? '';

const drawMiddleControl = (ctx: CanvasRenderingContext2D, width: number, length: number) => {
  ctx.rect(-width / 2, -length / 2, width, length);
  ctx.stroke();
  ctx.fill();
};

export const renderMiddleControl = (
  controlName: string,
  ctx: CanvasRenderingContext2D,
  options: CustomControlOptions,
) => {
  const { left, top, size, angle, icon } = options;
  const rectWidth = 1;
  const iconImage = new Image();
  iconImage.src = icon || middleControlIcon;

  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(angle));

  if (icon) {
    const iconSize = size;
    const offset = 2;

    switch (controlName) {
      case 'ml':
        ctx.drawImage(iconImage, -(iconSize / 2 + offset), -(iconSize / 2), iconSize, iconSize);
        break;
      case 'mr':
        ctx.drawImage(iconImage, -(iconSize / 2 - offset), -(iconSize / 2), iconSize, iconSize);
        break;
      case 'mt':
        ctx.drawImage(iconImage, -(iconSize / 2), -(iconSize / 2 + offset), iconSize, iconSize);
        break;
      case 'mb':
        ctx.drawImage(iconImage, -(iconSize / 2), -(iconSize / 2 - offset), iconSize, iconSize);
        break;
      default:
        break;
    }
  } else {
    switch (controlName) {
      case 'ml':
      case 'mr':
        drawMiddleControl(ctx, rectWidth, size);
        break;
      case 'mt':
      case 'mb':
        ctx.rotate(fabric.util.degreesToRadians(90));
        drawMiddleControl(ctx, rectWidth, size);
        break;
      default:
        break;
    }
  }

  ctx.restore();
};
