import { getEditProjectNameOptions } from '../analytics-context-types';
import { getModalLevel2, getModalTag, getPODProductTag } from './utils';

/**
 * Gets the modal and product tag to track when the user updates the project's name via the save-project modal
 *
 * @param options attributes to be included in product tag and modal event tag
 * @returns
 */
export const getEditProjectNameTag = (options: getEditProjectNameOptions) => {
  const editProjectNameEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
    {
      eventInfo: {
        eventName: 'save project',
        eventAction: 'save project - edit project name',
      },
    },
    {
      eventInfo: {
        eventName: 'project summary',
        eventAction: 'project name updated',
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const level2 = options.onSplashScreen ? 'Hooray' : getModalLevel2(options.productType);

  const { modal, user } = getModalTag(options.productType, level2, editProjectNameEvent, options.projectID);

  return {
    product,
    modal,
    user,
  };
};
