import { CardFaceData, CardText } from '../../global-types';

/**
 * Finds a text by its ID in a list of card faces.
 * @param {CardFaceData[]} cardFacesList - The list of card faces to search in.
 * @param {string} id - The ID of the text to find.
 * @returns {CardText | null} - The found text or null if no text was found with the given ID.
 */
export const findTextById = (cardFacesList: CardFaceData[], id: string): CardText | null => {
  for (const face of cardFacesList) {
    const foundText = face.texts.find((text) => text.ID === id);
    if (foundText) {
      return foundText;
    }
  }
  return null;
};
