import { GetChangeQuantityFromProjectSummaryOptions } from '../analytics-context-types';
import { getModalLevel2, getPODModalTag, getPODProductTag } from './utils';

/**
 * Gets the modal and product tag to track when the quantity change on the project summary
 *
 * @param options attributes to be included in product tag and modal event tag
 * @returns
 */
export const getChangeQuantityFromProjectSummaryTag = (options: GetChangeQuantityFromProjectSummaryOptions) => {
  const changeQuantityFromProjectSummaryEvent = [
    {
      eventInfo: {
        eventName: 'project summary',
        eventAction: 'update quantity',
      },
    },
    {
      eventInfo: {
        eventName: 'product quantity',
        eventAction: options.productQuantity,
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const level2 = getModalLevel2();

  const { modal, user } = getPODModalTag(level2, changeQuantityFromProjectSummaryEvent, options.projectID);

  return {
    product,
    modal,
    user,
  };
};
