import { ErrorResponse, SuccessResponse } from '../global-types/services';

export const processResponse = <T = unknown>(request: Promise<SuccessResponse<T> | ErrorResponse>, resolve, reject) => {
  request.then((response) => {
    if (response === undefined || (response && 'status' in response)) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};
