import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorOption } from '@hallmark/web.page-components.print-on-demand.widgets.color-option';
import { extendedTextColors } from '../../../../constants';
import { useActiveCanvas } from '../../../../hooks';
import { getGroupedTextObject } from '../../../../utils';
import styles from '../font-drawer.module.scss';
import { hexToColorNameMap } from '../utils/hex-to-color-name-map';
import { onChangeTextColor } from '../utils/on-change-text-color';

export const ColorTab = (): React.ReactElement => {
  const canvas = useActiveCanvas();
  const initialHexColor = getGroupedTextObject(canvas?.current)?.fill;
  const initialColor = hexToColorNameMap[initialHexColor as string];
  const [selectedColor, setSelectedColor] = useState(initialColor || '');
  const { t } = useTranslation();

  return (
    <ColorOption
      testId="color-tab"
      label={t('fontDrawer.textColor')}
      addClass={styles['color-option']}
      colors={extendedTextColors}
      selectedColor={selectedColor}
      buttonGroupClick={(color) => onChangeTextColor(color, canvas, setSelectedColor)}
    />
  );
};
