import { GetImageFormatsOptions, GetTextFormatsOptions, ModalEventType } from '../analytics-context-types';
import { getModalTag, getPODProductTag } from './utils';

export const getTextFormatsTag = (options: GetTextFormatsOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const textFormatsModalEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
    {
      eventInfo: {
        eventName: options.elementType,
        eventAction: options.eventAction || 'format',
      },
    },
    {
      eventInfo: {
        eventName: 'alignment',
        eventAction: options.textAlignment,
      },
    },
    {
      eventInfo: {
        eventName: 'add new color',
        eventAction: options.addColor,
      },
    },
    {
      eventInfo: {
        eventName: 'color',
        eventAction: options.color,
      },
    },
    {
      eventInfo: {
        eventName: 'font',
        eventAction: options.fontName,
      },
    },
    {
      eventInfo: {
        eventName: 'size',
        eventAction: options.fontSize,
      },
    },
  ];

  if (options.isTextModified) {
    textFormatsModalEvent.push({
      eventInfo: {
        eventName: 'text',
        eventAction: 'type',
      },
    });
  }

  if (options.move) {
    textFormatsModalEvent.push({
      eventInfo: {
        eventName: 'move',
        eventAction: options.elementPosition,
      },
    });
  }

  const { modal, user } = getModalTag(
    options.productType,
    'Edit Mode',
    textFormatsModalEvent.filter((prop) => prop.eventInfo.eventAction !== ''),
    options.projectID,
  );

  return {
    modal,
    product,
    user,
  };
};

export const getImageFormats = (options: GetImageFormatsOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const imageFormatsModalEvent: ModalEventType[] = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: options.elementPosition,
      },
    },
    {
      eventInfo: {
        eventName: options.elementType,
        eventAction: options.eventAction || 'format',
      },
    },
    {
      eventInfo: {
        eventName: 'crop',
        eventAction: options.crop, //'<coords of crop or size of crop>',
      },
    },
    {
      eventInfo: {
        eventName: 'size',
        eventAction: options.size, //'<<number of final size>>',
      },
    },
    {
      eventInfo: {
        eventName: 'rotate',
        eventAction: options.rotate, //"<<degree of final rotation or degree of change>>"
      },
    },
    {
      eventInfo: {
        eventName: 'order',
        eventAction: options.order, // "<<final order or number of change in positive or negative>>"
      },
    },
  ];

  if (options.move) {
    imageFormatsModalEvent.push({
      eventInfo: {
        eventName: 'move',
        eventAction: options.elementPosition,
      },
    });
  }

  if (options.replace) {
    imageFormatsModalEvent.push({
      eventInfo: {
        eventName: 'replace',
        eventAction: options.replace,
      },
    });
  }

  const { modal, user } = getModalTag(
    options.productType,
    'Edit Mode',
    imageFormatsModalEvent.filter((prop) => prop.eventInfo.eventAction !== ''),
    options.projectID,
  );

  return {
    modal,
    product,
    user,
  };
};
