import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@hallmark/web.core.buttons.button';
import { IconButton, IconButtonBrandColors, IconNames } from '@hallmark/web.core.buttons.icon-button';
import { Drawer, DrawerPositions } from '@hallmark/web.core.feedback.drawer';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { RadioCard } from '@hallmark/web.core.forms.radio-card';
import { HtmlTagOverrides, Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { setIsToasterOpen, useAppContext } from '../../context/app-context';
import { useInitializationDataContext, updateContacts } from '../../context/data-context';
import { deleteQuickAddress } from '../../services/address';
import { AddressBookProps, FormData, HandleDeleteParams } from './address-book-types';
import styles from './address-book.module.scss';

const AddressBook = ({ isOpen, onClose, handleAddressBookSubmit }: AddressBookProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<FormData>({ mode: 'onChange' });

  const { appDispatch } = useAppContext();
  const {
    initializedDataState: { savedContacts, isUK },
    initializationDataDispatch,
  } = useInitializationDataContext();

  const { t } = useTranslation();

  const onSubmit = (formData: FormData) => {
    const contact = savedContacts[Number(formData.index)];
    if (contact) {
      handleAddressBookSubmit(contact);
      onClose();
      return;
    }
    // in case contact is not valid reset the form
    reset();
  };

  const handleDelete = ({ contactId, firstName, lastName }: HandleDeleteParams) => {
    const successMessage = `${t('addressBook.successDelete', { name: `${firstName} ${lastName}` })}`;
    const errorMessage = `${firstName} ${lastName} ${t('addressBook.failedDelete')}`;

    deleteQuickAddress(contactId).then((response) => {
      if (response && response.meta.code >= 400) {
        setIsToasterOpen(appDispatch, {
          title: `${t('addressBook.failedDeleteTitle')}`,
          children: errorMessage,
          variant: ToastVariants.Error,
        });
      } else {
        // if delete is successful we reset the form in order to avoid issues with a non existing id.
        reset();
        const newContacts = savedContacts.filter((contact) => contact.contact_id !== contactId);
        updateContacts(initializationDataDispatch, newContacts);
        setIsToasterOpen(appDispatch, { title: '', children: successMessage });
      }
    });
  };

  const HeaderContent = (): React.ReactElement => (
    <Typography variant={TypographyVariants.Headline1} addClass={styles.headline}>
      {t('addressBook.addressBook')}
    </Typography>
  );

  const FooterContent = (): React.ReactElement => (
    <Button
      disabled={!isValid}
      addClass={classNames(styles['footer-content-button'], savedContacts?.length === 0 && styles.hidden)}
      testId={'submit-address-button'}
      click={handleSubmit(onSubmit)}
    >
      {t('addressBook.useAddress')}
    </Button>
  );

  return (
    <Drawer
      drawerPosition={DrawerPositions.Left}
      closeButtonText={`${t('addressBook.closeDrawer')}`}
      isOpen={isOpen}
      onClose={onClose}
      headerContent={<HeaderContent />}
      footerContent={<FooterContent />}
      addClass={styles.drawer}
      drawerTestId="address-book-drawer"
    >
      {savedContacts?.length > 0 ? (
        savedContacts.map((contact, index) => (
          <div role="radiogroup" aria-labelledby="radio_button_group" id="radio_button_group" key={contact.contact_id}>
            <RadioCard
              domId={`basic-radio-card-${index}`}
              register={register('index', { required: true })}
              testId={`address-radio-card-${index}`}
              addClass={styles['radio-card']}
              value={index.toString()}
            >
              <div className={styles['contact-row']}>
                <div className={styles['name']}>
                  <Typography
                    htmlTagOverride={HtmlTagOverrides.Div}
                    variant={TypographyVariants.Caption}
                    color={IconButtonBrandColors.DarkGray}
                  >
                    {`${contact.first_name} ${contact.last_name}`}
                  </Typography>
                </div>
                <IconButton
                  icon={IconNames.TrashBold}
                  click={() =>
                    handleDelete({
                      contactId: contact.contact_id,
                      firstName: contact.first_name,
                      lastName: contact.last_name,
                    })
                  }
                  size={16}
                  iconColor={IconButtonBrandColors.DarkGray}
                  addClass={styles['icon']}
                  testId="trash-icon"
                  altText="delete contact"
                />
              </div>
              <Typography
                htmlTagOverride={HtmlTagOverrides.Div}
                variant={TypographyVariants.Caption}
                color={IconButtonBrandColors.MediumGray}
              >
                {contact.address_line_1}
              </Typography>
              <Typography
                htmlTagOverride={HtmlTagOverrides.Div}
                variant={TypographyVariants.Caption}
                color={IconButtonBrandColors.MediumGray}
              >
                {isUK ? `${contact.city}, ${contact.zip}` : `${contact.city}, ${contact.state_code} ${contact.zip}`}
              </Typography>
            </RadioCard>
          </div>
        ))
      ) : (
        <Typography
          addClass={styles['empty-message']}
          variant={TypographyVariants.Body}
          color={BrandColors.Black}
        >{`${t('addressBook.emptyMessage')}`}</Typography>
      )}
    </Drawer>
  );
};

export default AddressBook;
