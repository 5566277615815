import { fabric } from 'fabric';
import { config } from '../../../regional-config';
import { CustomControlOptions } from '../canvas-custom-config-types';

export const drawRotateControl = (
  ctx: CanvasRenderingContext2D,
  options: CustomControlOptions,
  scalingFactor: number,
  useIcon: boolean,
) => {
  const customRotateIcon = new Image();
  customRotateIcon.src = config?.customControls?.icons?.rotateIcon || '';
  const { left, top, size, angle } = options;
  const sideSize = size / scalingFactor;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(angle));
  if (useIcon) {
    ctx.drawImage(customRotateIcon, -size / 2, -size / 2, size, size);
  } else {
    ctx.rect(-sideSize / scalingFactor, -sideSize / scalingFactor, sideSize, sideSize);
  }
  ctx.stroke();
  ctx.fill();
  ctx.restore();
};
