import { CustomFabricImage } from '../../../global-types';

/**
 * Takes image object json and replaces the src with the croppedUrl
 * @param imageObjectJson | JSON of image object
 */
export const setCroppedSrc = (fabricObject: CustomFabricImage) => {
  if (!fabricObject?.data?.croppedUrl) {
    return fabricObject;
  }

  fabricObject.src = fabricObject.data.croppedUrl;
};
