import { GetPageNavigation } from '../analytics-context-types';
import { getModalLevel2, getPODModalTag, getPODProductTag } from './utils';

/**
 * Gets the modal and product tag to track when the page is changed in card-select-bar
 *
 * @param options attributes to be included in product tag and modal event tag
 * @returns
 */
export const getPageNavigationTag = (options: GetPageNavigation) => {
  const pageNavigation = [
    {
      eventInfo: {
        eventName: `view ${options.page}`,
        eventAction: options.button,
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const level2 = getModalLevel2();

  const { modal, user } = getPODModalTag(level2, pageNavigation, options.projectID);

  return {
    product,
    modal,
    user,
  };
};
