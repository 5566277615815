import { CardType } from '../../../global-types';

/**
 * Determines whether or not the preview image should be saved on the backend based on various conditions.
 * This function is designed to be generic and adaptable for different scenarios, including various card types and regions.
 *
 * @param {string} cardType - The type of the card being previewed.
 * @param {string | null | undefined} projectTypeCode - A code representing the project type, used to identify specific card types like S&S. Can be null or undefined.
 * @param {boolean | undefined} isDigitalGreetings - A flag indicating whether the card is a Digital Greetings card. Can be undefined.
 * @returns {boolean} - Returns true if the preview image should be saved, false otherwise.
 */
export const getShouldSavePreviewImage = (
  cardType: string,
  projectTypeCode: string | null | undefined,
  isDigitalGreetings?: boolean,
): boolean => {
  // If is a S&S card, or a card is not Digital Greetings AND this is not a front panel, don't save the preview.
  if (projectTypeCode === CardType.SAS || (!isDigitalGreetings && cardType !== 'front')) {
    return false;
  }
  // If this is a DG card, or a POD front panel, do save the preview image
  return true;
};
