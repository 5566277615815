/**
 * Filter out EXIF data from an image by running it through an HTML canvas
 * @param dataEncodedImage Data encoded image to be ran through canvas to filter out EXIF data
 * @returns a promise that resolves with a string of the filtered image data
 */
export const stripExifData = async (dataEncodedImage: string): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = async () => {
      const canvas = document.createElement('canvas');
      const canvasContext = canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;

      if (canvasContext) {
        // Draw the image onto the canvas
        canvasContext.drawImage(image, 0, 0, image.width, image.height);
        resolve(canvas.toDataURL('image/jpeg'));
      } else {
        reject(null);
      }
    };
    image.src = dataEncodedImage;
  });
};
