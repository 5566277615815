import { GetLeaveProjectSummaryOptions } from '../analytics-context-types';
import { getModalLevel2, getPODModalTag, getPODProductTag } from './utils';

/**
 * Gets the modal and product tag to track when user is leaving (closing) the project summary drawer
 * @param options attributes to be included in product tag and modal event tag
 * @returns
 */
export const getLeaveProjectSummaryTag = (options: GetLeaveProjectSummaryOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const leaveProjectModalEvent = [
    {
      eventInfo: {
        eventName: 'project summary',
        eventAction: options.eventAction,
      },
    },
  ];

  // as this event may occur in any of the app views we get the level2 attribute based on the url
  const level2 = getModalLevel2();
  const { modal, user } = getPODModalTag(level2, leaveProjectModalEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
