import { ImageType, ImageHeader } from '../../global-types/images';

/**
 * Maps an image byte code to an image type enum for purposes of identifying image types to apply specific logic to.
 * @param imageHeader  takes an image header string
 * @returns an 'image type' enum if a match is found , otherwise null
 */
export const mapImageType = (imageHeader: ImageHeader): ImageType | null => {
  switch (imageHeader) {
    case ImageHeader.PNG:
      return ImageType.PNG;
    case ImageHeader.GIF:
      return ImageType.GIF;
    case ImageHeader.JPEG_JFIF:
    case ImageHeader.JPEG_JFIF_ALT:
    case ImageHeader.JPEG_EXIF:
    case ImageHeader.JPEG_CANON:
    case ImageHeader.JPEG_SAMSUNG:
    case ImageHeader.JPEG_SPIFF:
      return ImageType.JPEG;
    default:
      return null;
  }
};
