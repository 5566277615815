import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { setAllDrawersClosed, useAppContext } from '../../../../context/app-context';
import { useActiveCanvas } from '../../../../hooks';
import { resetZoom } from '../../../card-face/utils/zoom-mobile-text';
import { FontDrawerFooterProps } from '../font-drawer-types';
import styles from '../font-drawer.module.scss';

export const FontDrawerFooter = ({ onCancel }: FontDrawerFooterProps) => {
  const { t } = useTranslation();
  const { appDispatch } = useAppContext();
  const canvas = useActiveCanvas();

  const onSubmit = useCallback(() => {
    setAllDrawersClosed(appDispatch);
    if (canvas?.current) resetZoom(canvas?.current);
  }, [appDispatch]);
  return (
    <>
      <Button
        testId="font-drawer-footer-cancel"
        mode={ButtonModes.TextLink}
        click={onCancel}
        addClass={styles['secondary-button']}
      >
        {t('fontDrawer.cancel')}
      </Button>
      <Button
        testId="font-drawer-footer-apply"
        mode={ButtonModes.Primary}
        click={onSubmit}
        addClass={styles['primary-button']}
      >
        {t('fontDrawer.apply')}
      </Button>
    </>
  );
};
