import React from 'react';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Icon } from '@hallmark/web.core.display.icon';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { DrawerButtonProps, OptionsContainerProps } from '../image-edit-drawer-types';
import styles from '../image-edit-drawer.module.scss';

const DrawerButton = ({ onClick, icon, label }: DrawerButtonProps): React.ReactElement => {
  return (
    <Button testId={`drawer-button-${label}`} addClass={styles.button} click={onClick} mode={ButtonModes.Icon}>
      <Icon name={icon} size={24} addClass={styles.icon} color={BrandColors.DarkGray} />
      <Typography variant={TypographyVariants.Helper} color={BrandColors.DarkGray}>
        {label}
      </Typography>
    </Button>
  );
};

export const USUKOptionsContainer = ({
  onCrop,
  onSizeChange,
  onRotateChange,
  onDelete,
  onOrderChange,
  onReplace,
  onReset,
  isPodProductCode,
  isPhotoZoneImage,
  isHandwriting,
  isUS,
  isSignAndSend,
}: OptionsContainerProps) => (
  <>
    <DrawerButton icon={IconNames.CropBold} onClick={onCrop} label="Crop" />
    <DrawerButton icon={IconNames.ScaleRegular} onClick={onSizeChange} label="Size" />
    <DrawerButton icon={IconNames.RotateBold} onClick={onRotateChange} label="Rotate" />
    {isPodProductCode && <DrawerButton icon={IconNames.Trash} onClick={onDelete} label="Delete" />}
    {isPodProductCode && !isPhotoZoneImage && (
      <DrawerButton icon={IconNames.LayersRegular} onClick={onOrderChange} label="Order" />
    )}
    {!isHandwriting && !isUS && <DrawerButton icon={IconNames.Camera} onClick={onReplace} label="Replace" />}
    {isSignAndSend && <DrawerButton icon={IconNames.Trash} onClick={onReset} label="Reset" />}
  </>
);
