import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { fabric } from 'fabric';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { setIsToasterOpen, useAppContext } from '../../../context/app-context';
import { useCardContext } from '../../../context/card-context';
import { RegionalCodesList } from '../../../global-types';
import { useSaveProject, useIsDigitalGreeting, useActiveCanvas, useFeatureFlags } from '../../../hooks';
import { useHandleIndexChange } from '../../../hooks/useHandleIndexChange';
import { CanvasDataTypes } from '../../../utils';
import { PodCardPreview } from '../../../utils/analytics/analytics-types';
import { pushPodCardPreview } from '../../../utils/analytics/analytics-utils';
import { CustomFabricType } from '../../../utils/canvas';
import { isAnyOfRegions } from '../../../utils/utility';
import { Zone } from '../editor-types';
import { getMandatoryEditTextAreas } from '../utils/get-mandatory-edit-text-areas';
import { setEnvelopeSearchParam } from '../utils/search-param-utils';

/**
 * useHandleProjectDone is a hook that handles the completion of a card project.
 * It checks if there are fields that need to be completed before moving to the next view.
 *
 * @param skipValidation - A ref object that indicates whether to skip validation.
 * @param validateUserZones - A function that checks if the user has edited the necessary zones.
 * @param uneditedZones - An array of zones that haven't been edited.
 * @param isOneToMany - A boolean indicating whether the card is 1:Many type.
 * @returns A function that can be invoked when the card project is done.
 */

export const useHandleProjectDone = (
  skipValidation: React.MutableRefObject<boolean>,
  validateUserZones: () => boolean,
  uneditedZones: Zone[],
  isOneToMany: boolean,
) => {
  // Initialize hooks and variables
  const { t } = useTranslation();
  const { saveProject } = useSaveProject();
  const { appDispatch } = useAppContext();
  const canvas = useActiveCanvas();
  const handleIndexChange = useHandleIndexChange({ savingCard: false });
  const { trackClickAddressRecipient, trackUneditedZoneError, trackEmailDeliveryRecipient } = useAnalyticsContext();
  const isDigitalGreeting = useIsDigitalGreeting();
  const { search } = useLocation();
  const history = useHistory();
  const { SKIP_PREVIEW_STEP } = useFeatureFlags();

  const { cardState } = useCardContext();

  return useCallback(() => {
    const showErrorToastAndSetSkipValidation = (titleKey: string, descriptionKey: string, shouldSkip: boolean) => {
      handleIndexChange(0, 0);
      setIsToasterOpen(appDispatch, {
        variant: ToastVariants.Error,
        title: t(titleKey),
        children: t(descriptionKey),
      });
      skipValidation.current = shouldSkip;
    };

    // Check if there are any unedited zones and send warning to analytics if there are
    if (uneditedZones.length > 0) {
      const statusMessage = `${t('editableFieldsError.toastErrorTitle')} | ${t(
        'editableFieldsError.toastErrorDescription',
      )}`;

      const uneditedPhotoZone = uneditedZones.find(({ zone }) => zone.data?.type === CanvasDataTypes.PhotoZone);
      const uneditedTextZone = uneditedZones.find(({ zone }) => zone.data?.type === CanvasDataTypes.EditableText);
      // Send warning to analytics
      if (uneditedPhotoZone) {
        trackUneditedZoneError(statusMessage, uneditedPhotoZone.zone as fabric.Object, uneditedPhotoZone.face);
      }
      if (uneditedTextZone) {
        trackUneditedZoneError(statusMessage, uneditedTextZone.zone as fabric.Object, uneditedTextZone.face);
      }
    }

    // Check if there are any fields that must be edited (MustEditTextArea: true)
    const fieldsToBeEdited = getMandatoryEditTextAreas(uneditedZones, cardState.cardFacesList);
    // If there are fields to be edited, show an error toast and set skipValidation to false
    if (fieldsToBeEdited.length > 0) {
      showErrorToastAndSetSkipValidation(
        'fieldsToBeEditedError.toastErrorTitle',
        'fieldsToBeEditedError.toastErrorDescription',
        false,
      );
    }
    // If user zones do not validate and skipValidation is false, show an error toast and set skipValidation to true
    else if (!validateUserZones() && !skipValidation.current) {
      showErrorToastAndSetSkipValidation(
        'editableFieldsError.toastErrorTitle',
        'editableFieldsError.toastErrorDescription',
        true,
      );
    }
    // Otherwise, save the project and go to the next view
    else {
      saveProject({
        shouldRestoreCanvas: false,
        isSaveButtonClicked: false,
        generatePreviews: true,
      })?.then(() => {
        if (isDigitalGreeting) {
          trackEmailDeliveryRecipient();
        } else {
          trackClickAddressRecipient();
        }

        const textAdded = cardState.cardFacesList.some(() =>
          canvas?.current?.getObjects().some((obj) => obj.type === 'textbox'),
        );
        const photoAdded = cardState.cardFacesList.some(() =>
          canvas?.current
            ?.getObjects()
            .some((obj) => obj.type === 'image' && (obj as fabric.Image).data.customType === CustomFabricType.Image),
        );
        const stickerAdded = cardState.cardFacesList.some(() =>
          canvas?.current
            ?.getObjects()
            .some((obj) => obj.type === 'image' && (obj as fabric.Image).data.customType === CustomFabricType.Sticker),
        );
        const handwritingAdded = cardState.cardFacesList.some(() =>
          canvas?.current
            ?.getObjects()
            .some(
              (obj) => obj.type === 'image' && (obj as fabric.Image).data.customType === CustomFabricType.Handwriting,
            ),
        );

        const podCardPreviewData: Omit<PodCardPreview, 'event_id'> = {
          event: 'pod_card_preview',
          text_added: textAdded,
          photo_added: photoAdded,
          sticker_added: stickerAdded,
          handwriting_added: handwritingAdded,
        };
        pushPodCardPreview(podCardPreviewData);

        // sets default value to the envelope if the card is 1:Many
        const searchParams = new URLSearchParams(search);
        setEnvelopeSearchParam(searchParams, search, isOneToMany);
        if (!SKIP_PREVIEW_STEP) {
          // Push to next view
          history.push({
            pathname: `/card/customization/${isAnyOfRegions([RegionalCodesList.ce]) ? 'preview' : 'address'}`,
            search: searchParams.toString(),
          });
        }
      });
    }
  }, [validateUserZones, skipValidation.current, isOneToMany]);
};
