import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Image } from '@hallmark/web.core.display.image';
import blankEnvelope from '../../../assets/BlankEnvelop.png';
import returnEnvelope from '../../../assets/ReturnAddress.png';
import { AddressForm, SelectedEnvelope } from '../../../global-types';
import styles from '../address.module.scss';

export const envelopes: SelectedEnvelope[] = [
  {
    id: 'blank',
    image: blankEnvelope,
  },
  {
    id: 'return',
    image: returnEnvelope,
  },
];

type EnvelopeSelectionProps = {
  formHandlers: UseFormReturn<AddressForm>;
};
export const EnvelopeSelection = ({ formHandlers: { watch } }: EnvelopeSelectionProps) => {
  const { t } = useTranslation();
  const currentEnvelopeSelection = watch('envelope');
  const currentEnvelope = envelopes.find((envelope) => envelope.id === currentEnvelopeSelection);

  if (!currentEnvelope) {
    return <></>;
  }

  return (
    <div className={styles['envelope-selection']} data-testid="envelope-image">
      <Image
        key={currentEnvelope.id}
        src={currentEnvelope.image}
        alt={t(`envelopeView.${currentEnvelope.id}EnvelopeLabel`)}
      />
    </div>
  );
};
