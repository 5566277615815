import { useMemo } from 'react';
import { getTextAndImageObjects } from '../utils/canvas';
import { useActiveCanvas } from './useActiveCanvas';

/**
 * Custom hook to determine if the active canvas is empty of text and image objects.
 *
 * @returns {boolean} Returns true if the canvas is empty, false otherwise.
 */

function useIsCanvasEmpty(): boolean {
  const activeCanvas = useActiveCanvas();

  const isCanvasEmpty = useMemo(() => {
    if (!activeCanvas?.current) {
      return true;
    }

    const filteredObjects = getTextAndImageObjects(activeCanvas.current);
    return filteredObjects.length === 0;
  }, [activeCanvas]);

  return isCanvasEmpty;
}

export default useIsCanvasEmpty;
