import { RefObject } from 'react';
import { Canvas } from 'fabric/fabric-impl';

export const bringBackObject = (canvas: RefObject<Canvas> | undefined, lowestIndex: number) => {
  const activeObject = canvas?.current?.getActiveObject();
  // Send the active text object to the lowest index
  if (activeObject) {
    activeObject.moveTo(lowestIndex);
    activeObject.setOptions({ order: lowestIndex });
  }
};
