import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerPositions } from '@hallmark/web.core.feedback.drawer';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useActiveCanvas } from '../../../hooks';
import { config } from '../../../regional-config';
import { useImageUploadHandlers } from '../drawer-container/hooks/useImageUploadHandlers';
import { HeaderContent, FooterContent } from './fragments';
import { ScaleDrawerProps } from './scale-drawer-types';
import styles from './scale-drawer.module.scss';

export const ScaleDrawer = ({ isOpen }: ScaleDrawerProps): React.ReactElement => {
  const [originalScaleValue, setOriginalScaleValue] = useState<number | undefined>();
  const canvas = useActiveCanvas();
  const { openImageEditDrawerByCanvasDataType } = useImageUploadHandlers();

  const openEditImageDrawer = () => {
    const activeObject = canvas?.current?.getActiveObject();
    openImageEditDrawerByCanvasDataType(activeObject?.data?.type);
  };

  const { t } = useTranslation();

  useEffect(() => {
    const activeObject = canvas?.current?.getActiveObject();
    if (activeObject?.type === 'image') {
      setOriginalScaleValue(activeObject.scaleX);
    }
  }, []);

  const handleClose = () => {
    const activeObject = canvas?.current?.getActiveObject();
    if (activeObject?.type === 'image') {
      activeObject.set({
        scaleX: originalScaleValue,
        scaleY: originalScaleValue,
      });
      canvas?.current?.renderAll();
    }
    openEditImageDrawer();
  };

  return (
    <Drawer
      showVeil={false}
      hideCloseButton={true}
      addClass={styles['scale-drawer']}
      drawerPosition={DrawerPositions.Bottom}
      onClose={handleClose}
      isOpen={isOpen}
      headerContent={<HeaderContent />}
      layoutVariant={config?.scaleDrawer?.layoutVariant || undefined}
    >
      <FooterContent onApply={openEditImageDrawer} onCancel={handleClose} />
      <Typography variant={TypographyVariants.SRT}>{t('scaleDrawer.scaleDrawer')}</Typography>
    </Drawer>
  );
};
