import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { SystemFailureDialog } from '../../components/system-failure-dialog';
import { useAnalyticsContext } from '../../context/analytics-context';
import { useAppContext } from '../../context/app-context';
import { setIsToasterClosed, setTriggeringElementRef } from '../../context/app-context/app-context-actions';
import { useInitializationDataContext } from '../../context/data-context';
import { RegionalCodesList } from '../../global-types';
import { useFeatureFlags, useQueryParams, useConfirmationDialog } from '../../hooks';
import { config } from '../../regional-config';
import { redirectToEdit } from '../../utils';
import { getMonolithUrl, isAnyOfRegions } from '../../utils/utility';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog';
import { Loader } from '../loader';
import { PrintOnDemandHeader } from '../print-on-demand-header';
import { RedirectDialog } from '../redirect-dialog';
import { ScreenLoader } from '../screen-loader';
import { Toolbar } from '../toolbar';
import { Toast } from './fragments/toast';
import { LayoutProps } from './layout-types';
import styles from './layout.module.scss';

/**
 * Lazy loading EditorMenu since it's
 * only needed for CE region
 */
export const EditorMenuComponent = lazy(() =>
  import('../editor-menu/editor-menu').then((module) => ({
    default: module.EditorMenu,
  })),
);

/**
 * Suspense fallback component for CE region
 * EditorMenu
 */
export const EditorMenuLoader = () => <div>Loading</div>;

export const Layout = ({
  children,
  hideToolbar,
  addToolbarClass,
  addToastClass,
  addLayoutClass,
  onToolbarItemClick,
}: LayoutProps) => {
  const { pathname, search } = useLocation();
  const isPreviewPage = pathname.includes('/preview');
  const showEditorMenu = isAnyOfRegions([RegionalCodesList.ce]) && !isPreviewPage;
  const shouldHideToolbar = showEditorMenu || hideToolbar;
  const [redirectUrl, setRedirectUrl] = useState('');
  const { appState, appDispatch } = useAppContext();
  const history = useHistory();
  const { toaster, loader, isSystemErrorOpen, isRedirectDialogOpen, errorMessages, exitUrl } = appState;
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const classes = classNames(
    styles.layout,
    { [styles['no-toolbar']]: hideToolbar, [styles['editor-menu']]: showEditorMenu },
    addLayoutClass,
  );
  const queryParams = useQueryParams();
  const { trackRetryHandlerTriggered } = useAnalyticsContext();
  const [retryTrackingTrigger, setRetryTrackingTrigger] = useState<string | null>(null);

  const { SAVED_PROJECTS, SAVED_PROJECTS_LINK } = useFeatureFlags();
  const homePageUrl = getMonolithUrl() as string;

  const { isDialogOpen, setIsDialogOpen, dialogGoToHomePage, handleCloseDialog, discardChanges } =
    useConfirmationDialog({ homePageUrl });

  useEffect(() => {
    if (retryTrackingTrigger) {
      if (errorMessages) {
        trackRetryHandlerTriggered(retryTrackingTrigger, errorMessages);
      }
      setRetryTrackingTrigger(null);
    }
  }, [retryTrackingTrigger]);

  useEffect(() => {
    const storedRedirectUrl = localStorage.getItem('redirectUrl');

    const currentRedirectUrl = exitUrl && exitUrl !== window.location.href ? exitUrl : homePageUrl;

    if (currentRedirectUrl) {
      setRedirectUrl(currentRedirectUrl);
      localStorage.setItem('redirectUrl', currentRedirectUrl);
    } else if (storedRedirectUrl) {
      setRedirectUrl(storedRedirectUrl);
    }
  }, [exitUrl]);

  const closeToaster = () => {
    setIsToasterClosed(appDispatch);
    document.body.removeAttribute('data-dialog');
  };

  const handleRedirect = useCallback(() => {
    const decodedUrl = decodeURIComponent(redirectUrl);
    window.location.href = decodedUrl;
    localStorage.removeItem('redirectUrl');
  }, [redirectUrl]);

  const handleConfirmDialog = useCallback(() => {
    if (config?.navigation?.useAlternativeExitLogic) {
      handleRedirect();
    } else {
      discardChanges();
    }
    handleCloseDialog();
  }, [handleRedirect, config]);

  const handleSystemFailureAction = () => {
    if (errorMessages) {
      trackRetryHandlerTriggered('retry', errorMessages);
    }
    const { pathname } = history.location;
    const isEditorView = pathname.includes('edit');
    setTimeout(() => {
      if (isEditorView) {
        history.go(0);
      } else {
        if (initializedData?.project_id) {
          redirectToEdit(initializedData?.project_id, search);
        }
      }
    }, 1000);
  };

  const handleSystemFailureCancel = () => {
    setRetryTrackingTrigger('exit');
    setTimeout(() => {
      window.location.assign(document.referrer);
    }, 1000);
  };

  const handleSystemFailureClose = () => {
    setRetryTrackingTrigger('exit-x');
    setTimeout(() => {
      window.location.assign(document.referrer);
    }, 1000);
  };

  const navigateAway = useCallback(() => {
    localStorage.setItem('redirectUrl', redirectUrl);
    setIsDialogOpen(true);
  }, [redirectUrl]);

  const onCloseApp = useCallback(
    (button: HTMLButtonElement | null) => {
      if (!SAVED_PROJECTS_LINK) {
        discardChanges();
        return;
      }

      if (button) {
        if (config?.navigation?.useAlternativeExitLogic) {
          navigateAway();
        } else {
          queryParams.append('exitUrl', homePageUrl);
          history.replace({ pathname, search: queryParams.toString() });
        }
      }
    },
    [queryParams, SAVED_PROJECTS_LINK],
  );

  const goToHomePage = () => {
    if (!SAVED_PROJECTS_LINK) {
      discardChanges();
      return;
    }

    if (config?.navigation?.useAlternativeExitLogic) {
      navigateAway();
    } else {
      queryParams.append('exitUrl', homePageUrl);
      history.replace({ pathname, search: queryParams.toString() });
    }
  };

  const isEditor = useMemo(() => {
    return pathname.includes('/edit/');
  }, [pathname]);

  // All this section will be removed once saved projects goes to Prod
  const { t } = useTranslation();

  const dialogOnCloseApp = useCallback((button: HTMLButtonElement | null) => {
    if (button) {
      setTriggeringElementRef(appDispatch, button);
    }
    setIsDialogOpen(true);
  }, []);

  // End of section

  return (
    <div className={classes}>
      <PrintOnDemandHeader
        onClose={SAVED_PROJECTS ? onCloseApp : dialogOnCloseApp}
        isEditor={isEditor}
        onReturnToHomePage={SAVED_PROJECTS ? goToHomePage : dialogGoToHomePage}
      />
      <ConfirmationDialog
        domId="close-confirmation"
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        title={`${t('header.leavePersonalizationTitle')}`}
        contentText={`${t('header.leavePersonalizationContent')}`}
        actionButtonText={`${t('header.leavePersonalizationActionButtonText')}`}
        cancelButtonText={`${t('header.leavePersonalizationCancelButtonText')}`}
        useAlternativeDialog={config?.dialog?.useAlternativeDialog || false}
        invertedButtons={true}
      />
      {isSystemErrorOpen && (
        <SystemFailureDialog
          isOpen={isSystemErrorOpen}
          actionButton={handleSystemFailureAction}
          cancelButton={handleSystemFailureCancel}
          onClose={handleSystemFailureClose}
        />
      )}
      {isRedirectDialogOpen && <RedirectDialog />}
      {config?.loader?.useCrownLoader ? (
        <ScreenLoader isVisible={loader.isLoading} />
      ) : (
        <Loader isLoading={loader.isLoading} title={loader.title} />
      )}
      {toaster.isOpen && (
        <Toast
          variant={toaster.variant as undefined}
          isOpen={toaster.isOpen}
          title={toaster.title}
          onClose={closeToaster}
          addClass={addToastClass}
        >
          {toaster.children}
        </Toast>
      )}

      {!shouldHideToolbar && <Toolbar addClass={addToolbarClass} onToolbarItemClick={onToolbarItemClick} />}

      <div className={styles.content} data-testid="app-view">
        {children}
      </div>
      {showEditorMenu && (
        <Suspense fallback={EditorMenuLoader()}>
          <EditorMenuComponent />
        </Suspense>
      )}
    </div>
  );
};
