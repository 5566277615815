import API from '@ce-digital/api-middleware';
import { ApiResponse } from '../global-types';
import { StickerData } from '../global-types';
import { processResponse } from './middleware';

const PODService = new API();

/**
 * Get call to get stickers data
 * @param page - pagination parameter (25 stickers per request)
 * @param keyword - search parameter (search by word)
 * @returns array of stickers data
 */

export const getStickers = (page: number, keyword: string): Promise<ApiResponse<StickerData[] | null>> => {
  return new Promise((resolve, reject) => {
    const response = PODService.get('/customization', `/stickers?page=${page}&keyword=${keyword}&partial_search=true`);
    processResponse(response, resolve, reject);
  });
};
