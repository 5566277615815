/**
 * Method to detect if the image is a HEIC image on ios or windows
 * @param file
 * @returns true if the image type is image/heic, or the extension is heic but the type is blank
 */
export const detectHeicImage = (file: File): boolean => {
  if (file.type === 'image/heic') {
    return true;
  }

  // Get .heic file extension from file.name to see if file extension is heic
  const fileExtension = file?.name?.split('.')?.pop();
  if (fileExtension === 'heic') {
    // On windows, HEIC images return as a blank file type.
    return file.type === '';
  }

  // If the file type is not heic, return false
  return false;
};
