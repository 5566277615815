import { GetViewProjectSummaryOptions } from '../analytics-context-types';
import { getModalLevel2, getPODModalTag, getPODProductTag } from './utils';

/**
 * Gets the modal and product tag to track when user opens the project summary drawer
 *
 * @param options attributes to be included in product tag and modal event tag
 * @returns
 */
export const getViewProjectSummaryTag = (options: GetViewProjectSummaryOptions) => {
  const viewProjectSummaryEvent = [
    {
      eventInfo: {
        eventName: 'project summary',
        eventAction: options.eventAction,
      },
    },
  ];

  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const level2 = getModalLevel2();

  const { modal, user } = getPODModalTag(level2, viewProjectSummaryEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
