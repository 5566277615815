import { CardFaceTypes } from '../../../global-types/card-face';
import { GetDGPlayAnimationOptions } from '../analytics-context-types';
import { getDGModalTag, getPODProductTag } from './utils';

export const getDGPlayAnimationTag = (options: GetDGPlayAnimationOptions) => {
  const { product } = getPODProductTag(
    options.productName,
    options.productID,
    options.productType,
    options.productQuantity,
  );

  const isFrontFace = options.elementPosition === CardFaceTypes.AREAFRONT;
  const currentFace = isFrontFace ? CardFaceTypes.FACEFRONT : options.elementPosition;
  const isHoorayView = options.isOnEmailSplashScreen;
  const playAnimationTagEvent = [
    {
      eventInfo: {
        eventName: 'area',
        eventAction: currentFace,
      },
    },
    {
      eventInfo: {
        eventName: 'view animation',
        eventAction: 'view eCard',
      },
    },
  ];

  if (isHoorayView) playAnimationTagEvent.shift();

  const { modal, user } = getDGModalTag(isHoorayView ? 'Hooray' : 'Preview', playAnimationTagEvent, options.projectID);

  return {
    modal,
    product,
    user,
  };
};
